import _ from "lodash";

export function cleanEmptyStringInValue(object: any) {
  Object.keys(object).forEach((key) => {
    if (object[key] === "") {
      delete object[key];
      return;
    }
    if (typeof object[key] === "object") {
      cleanEmptyStringInValue(object[key]);
    }
  });
  return object;
}

/**
 * Flat an object to dont have nested objects.
 * While flatting the object it will add . separator to the object and the keys will be separated by .
 * @param obj The object to be flatted
 */
export function flatObject(obj: any): any {
  obj = _.cloneDeep(obj);
  let needToFlat = false;
  do {
    // At each iteration, we say that the data dont need any flatting
    needToFlat = false;

    // Check if the object has any nested objects
    for (const key in obj) {
      // If there's an object, we need to flat it
      if (typeof obj[key] === "object") {
        needToFlat = true;
        break;
      }
    }

    // If there's a nested object, flat it separating fields with '.' for easy access from the project data
    if (needToFlat) {
      // Check if the object has any nested objects
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          // If it is an nested object, flat it and add the . separator to the keys
          const newObj = obj[key];
          for (const newKey in newObj) {
            obj[key + "." + newKey] = newObj[newKey];
          }
          delete obj[key];
        }
      }
    }
  } while (needToFlat === true)
  return obj;
}

/**
* Unflat an object to have nested objects.
* While unflatting the object it will remove . separator to the object and the keys will be separated by .
* @param obj The object to be unflatted
*/
export function unflatObject(obj: any): any {
  let result = {}
  _.keys(obj).forEach(function (key, index) {
    if (obj[key] === undefined) return;
    _.set(result, key, obj[key])
  })
  return result
}