import { Space, Typography } from "antd";
import { EazyLoader } from "./EazyLoader"
type Props = {
  hasProjects: boolean;
  projectExists?: boolean;
};

export function NoProjectSelectedScreen({ hasProjects, projectExists }: Props) {
  return (
    <Space
      style={{
        padding: "1rem",
        justifyContent: "center",
      }}
      align="center"
      className={"NoProjectSelectedScreen"}
      direction="vertical"
    >
      {projectExists ? (
        <Typography.Title level={2}>
          {hasProjects
            ? "Connect to a project to begin"
            : "Start by creating a project in the platform"}
        </Typography.Title>
      ) : (
        <>
          <Typography.Title level={2}>
            Oops, we can't find what you are looking for
          </Typography.Title>
          <Typography.Title>
            <Typography.Link href="mailto:support@blings.io" style={{ color: "white", borderBottom: "2px solid #fff", paddingBottom: "2px", fontSize: "30px" }}>
              Contact us
            </Typography.Link>
          </Typography.Title>
        </>
      )
      }
      <EazyLoader size={600} isLoop={false} />
    </Space>
  );
}
