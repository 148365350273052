import * as React from "react";
import { MSTContext } from "../../stores/main";
import { Select, Space, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

type Props = {};
type IOption = { value: string; label: string };

export const ConnectToProject = observer((props: Props) => {
  const {
    platformStore: { allProjects, selectedProjectId },
  } = useContext(MSTContext);
  const history = useHistory();
  const pjOptions = allProjects.map((p) => ({ label: p.title, value: p.id }));
  return (
    <>
      {" "}
      <Space className="ConnectToProject">
        <Typography.Title level={4}>Project</Typography.Title>

        <Select<string>
          id="project-list-selector"
          placeholder="Select a project"
          showArrow
          value={selectedProjectId || undefined}
          options={pjOptions.sort(function (a, b) {
            // sort projects alphabeticlly
            const aName = a.label ? a.label : a.value;
            const bName = b.label ? b.label : b.value;
            if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
              return -1;
            } else {
              return 1;
            }
          })}
          onChange={(v) => history.push(`/${v}`)}
          showSearch
          style={{ minWidth: "8rem" }}
          filterOption={(input, option) => {
            return (
              (option as IOption)?.label
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1 ||
              (option as IOption)?.value
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1
            );
          }}
        />

        <br />
      </Space>
    </>
  );
});
