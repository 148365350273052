import { Select } from "antd";
import { toJS } from "mobx";
import { mainAssetKey } from "../stores/PlayerStore";
import { tagRender } from "./LayerTagRender";
import { useRef } from "react";
import { RefSelectProps } from "antd/es/select";
const { Option } = Select;

type mapFilterLayerFuncReturn = { layerName: string; renderText: string };
type mapFilterLayerFuncReturnOrString = string | mapFilterLayerFuncReturn;

interface LayerSelectorProps {
  isMulty: boolean;
  placeholder?: string;
  assetLayerPairs;
  selectedAssetId?: string;
  selectedLayerName: string;
  selectedAdditionals?: Array<{
    assetId?: string;
    layerName: string;
  }>;
  onChange;
  index: number;
  mapFilterLayerFunc?: (
    p: Array<mapFilterLayerFuncReturnOrString>,
    layerName: string,
    assetId?: string,
    additionals?: Array<{
      assetId?: string;
      layerName: string;
    }>
  ) => Array<mapFilterLayerFuncReturnOrString>;
}

export const LayerSelector = ({
  isMulty,
  placeholder,
  assetLayerPairs,
  selectedAssetId,
  selectedLayerName,
  selectedAdditionals,
  onChange,
  index,
  mapFilterLayerFunc, //= ()=> true
}: LayerSelectorProps) => {
  const handleSingleChange = (value) => {
    const { assetId, layerName } = JSON.parse(value);
    onChange(
      index,
      assetId === mainAssetKey ? undefined : assetId,
      layerName,
      undefined
    );
  };
  const singleCurrentVal = !!selectedLayerName
    ? [`${selectedLayerName} - ${selectedAssetId || mainAssetKey}`]
    : undefined;
  const handleMultiChange = (values: string[]) => {
    if (values.length) {
      // on first selection - should close the dropdown
      if (values.length === 1) {
        try {
          selectElementRef.current?.blur();
        } catch (e) {}
      }

      const { assetId, layerName, additionals } = JSON.parse(values[0]);
      let l_additionals = additionals;
      if (!l_additionals) {
        l_additionals = [];
      }
      if (values.length > 1) {
        values.slice(1).forEach((value) => {
          let { assetId, layerName } = JSON.parse(value);
          if (assetId === mainAssetKey) {
            assetId = undefined;
          }
          l_additionals.push({ assetId, layerName });
        });
      }
      onChange(
        index,
        assetId === mainAssetKey ? undefined : assetId,
        layerName,
        l_additionals
      );
    } else {
      onChange(index, undefined, undefined, undefined);
    }
  };

  const assetId = selectedAssetId ? selectedAssetId : mainAssetKey;
  let value: string | undefined;
  if (selectedLayerName) {
    value = JSON.stringify({ assetId: assetId, layerName: selectedLayerName });
  }

  let multiCurrentValues: string[] | undefined;
  if (value) {
    multiCurrentValues = [value];
    if (selectedAdditionals) {
      selectedAdditionals.forEach((additional) => {
        const assetId = additional.assetId ? additional.assetId : mainAssetKey;
        const newValue = JSON.stringify({
          assetId: assetId,
          layerName: additional.layerName,
        });
        if (multiCurrentValues && newValue) {
          multiCurrentValues.push(newValue);
        }
      });
    }
  }
  const selectElementRef = useRef<RefSelectProps>(null);
  const arrayFromFunction = () => {
    return (
      <>
        {Array.from(toJS<[string, any]>(assetLayerPairs)).flatMap(
          (
            [assetId, layers]: [
              string,
              string[],
              Array<{
                assetId?: string;
                layerName?: string;
              }>
            ],
            assetIndex
          ) =>
            (mapFilterLayerFunc
              ? layers.reduce<mapFilterLayerFuncReturnOrString[]>(
                  (p, c) => mapFilterLayerFunc(p, c, assetId, []),
                  []
                )
              : layers
            ).map((layerNameOrTextRender, layerIndex) => {
              const isObj = typeof layerNameOrTextRender === "string";
              const layerName = isObj
                ? layerNameOrTextRender
                : (layerNameOrTextRender as mapFilterLayerFuncReturn).layerName;

              const text = isObj
                ? `${layerName} - ${assetId}`
                : (layerNameOrTextRender as mapFilterLayerFuncReturn)
                    .renderText;
              const stringified = JSON.stringify({ assetId, layerName });
              return (
                <Option
                  title={text}
                  key={text + assetIndex + "_" + layerIndex}
                  value={stringified}
                >
                  {text}
                </Option>
              );
            })
        )}
      </>
    );
  };
  return (
    <Select<string[]>
      id="layer-selector"
      ref={selectElementRef}
      mode={isMulty ? "multiple" : undefined}
      className={"LayerSelector"}
      allowClear
      style={{ width: "100%" }}
      onChange={isMulty ? handleMultiChange : handleSingleChange}
      value={isMulty ? multiCurrentValues : singleCurrentVal}
      tagRender={tagRender}
      placeholder={placeholder || isMulty ? "Choose layers" : "Choose layer"}
    >
      {arrayFromFunction()}
    </Select>
  );
};
