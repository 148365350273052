import React, { useContext } from "react";
import { MSTContext } from "./stores/main";
import { observer } from "mobx-react-lite";
import { AsyncStateBtn } from "./BaseComps/AsyncStateBtn";

export const SideFormActionButtons = observer(() => {
  const {
    platformStore: { selectedProjectId, updateVideoPartMods, saveModsStatus },
    playerStore,
  } = useContext(MSTContext);

  const disableProjectBtns = !playerStore.jsonFile || !selectedProjectId;

  return (
    <div className={"SideFormActionButtons"}>
      <AsyncStateBtn
        type="primary"
        onClick={async () => {
          await updateVideoPartMods();
        }}
        disabled={disableProjectBtns}
        asyncState={saveModsStatus}
        btnText={"Save Connectors"}
      />
    </div>
  );
});
