import { BlingsPlayer, Player } from "@blings/blings-player";
import _ from "lodash";
import { cleanEmptyStringInValue } from "../helpers/objects";

const resizePlayer = (p: Player) => {
  let maxHeight = window.innerHeight;
  let widthPrecent = 100;
  if (p.mainContainer.offsetHeight > maxHeight) {
    widthPrecent = (maxHeight / p.mainContainer.offsetHeight) * 100;
    document
      .getElementsByClassName("VidContainerWrapper-inner-container")[0]
      .setAttribute(
        "style",
        "max-width:" + widthPrecent + "%; max-height:" + maxHeight
      );
  }
};

/** This objects will contain all the player configuration and the running instance for this player. */
let oldConfig: any = {};
let newConfig: any = {};
let player: Player | null = null;
let beingCreated = false;

/**
 * Create the editor player, the one we see while being able to add mods, on a vid-container div.
 * This function will use the global object to check if there's a player being created.
 * and to check if the old config is the same as the new config.
 * If the old config is the same as the new config, do nothing.
 * If the old config is different from the new config, create a new player with the new config.
 * @param {object} config All the configuration for the player to be built
 */
export const runPlayer = async ({
  JsonConfig,
  jsonFile,
  autoplay,
  posterFrame,
  scenes,
  rendererType,
  videoData,
  liveControlData,
  videoParts,
  cb,
  assetChanges,
}) => {
  // Save the received parameters as the new configuration for the player
  // This will be used to check if the player needs to be recreated and recreated if needed
  newConfig = {
    JsonConfig,
    jsonFile,
    autoplay,
    posterFrame,
    scenes,
    rendererType,
    videoData,
    liveControlData,
    videoParts,
    cb,
    assetChanges,
  };

  //#region --------------->>>  START THE PLAYER CREATION PROCESS  <<<------------------------------
  // Check if the player is being created
  if (beingCreated) return;

  // Check if the old config is the same as the new config
  if (_.isEqual(oldConfig, newConfig)) return;

  // Set the player is being created to true and save the new config as the old config as a copy
  beingCreated = true;
  let oldScenes = JSON.stringify(oldConfig.scenes);
  oldConfig = _.cloneDeep(newConfig);

  // Get the vid-container div and check if it exists. If it exists, already clean it
  const vidContainer = document.getElementById("vid-container");
  const spinner = document.getElementById("loader-inner-container")
  if (!vidContainer) return;
  spinner?.setAttribute("style", "")
  vidContainer.innerHTML = "";

  // Destroy the old player (if there is one)
  let startFrame;
  if (player) {
    try {
      if (oldScenes === JSON.stringify(oldConfig.scenes)) {
        startFrame = player.animation.currentFrame;
      }
      await player.destroy();
      player = null;
    } catch (e) {
      console.error("err destroying player", e);
    }
  }

  // Get the configuration for the player
  const ratio = oldConfig.jsonFile?.w / oldConfig.jsonFile?.h;
  const settings = {
    skin: {
      timeline: {
        show: true,
      },
    },
    jsonConfig: oldConfig.JsonConfig,

    maxLoadingTime: 10000,
    autoplay: startFrame ? false : oldConfig.autoplay,
    posterFrame: oldConfig.posterFrame,
    ratio,
    rendererType: oldConfig.rendererType,
  };

  // Create the new player
  try {
    player = await BlingsPlayer.create({
      project: {
        liveControlData: cleanEmptyStringInValue(
          JSON.parse(JSON.stringify(oldConfig.liveControlData))
        ),
        videoParts: oldConfig.videoParts,
      },
      data: cleanEmptyStringInValue(
        JSON.parse(JSON.stringify(oldConfig.videoData))
      ),
      settings: {
        container: vidContainer,
        ...settings,
      },
      scenes: oldConfig.scenes,
    });

    // After the player is created, resize it to the correct size and add a listener to the window resize event
    spinner?.setAttribute("style", "display: none")
    resizePlayer(player);
    window.onresize = () => {
      if (player) resizePlayer(player);
    };
    if (startFrame) {
      player.animation.goToAndStop(startFrame, true);
      player.hideCover();
      await player.slideTo(startFrame); // replace later to seekTo - need to change videos hidden.
      player.pausedByUser = true;
    } else {
      player.EE.on("onCoverHide", () => {
        player?.unmute();
      });
    }
    if(cb){
      cb(player);
    }
    (window as any).p = player;
  } catch (e) {
    console.error("err", e, { settings });
  }

  // Finally, set the player is being created to false
  beingCreated = false;
  //#endregion

  // Check the new config and the old config and if they are different, call this function again to recreate the player
  if (!_.isEqual(oldConfig, newConfig)) runPlayer(newConfig);
};

/**
 * Run a debaunced runPlayer function.
 */
export const runPlayerDebounce = _.debounce(runPlayer, 1000, {
  trailing: true,
  leading: false,
});
