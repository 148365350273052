import * as React from "react";
import { Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { MSTContext } from "../../stores/main";
import { useHistory } from "react-router-dom";
type Props = {};

export const VideoPartsSelection = observer((props: Props) => {
  const history = useHistory();
  const {
    platformStore: {
      projectWorkspaceVersion,
      selectedProjectId,
      selectedVideoPartName,
      deleteVideoPart,
    },
  } = useContext(MSTContext);
  return (
    <div className={"video-parts"}>
      <Typography.Title level={5}>Scenes</Typography.Title>
      <div className="vid-part-tags-container">
        {projectWorkspaceVersion?.videoParts &&
          projectWorkspaceVersion.videoParts.map((vp, index) => (
            <Tag
              id={"video-part-" + index}
              className={
                "video-part-tag" +
                (selectedVideoPartName === vp.name ? " selected" : "")
              }
              // closable
              key={vp.name}
              // value={vp.name}
              onClick={(e) => {
                history.push(`/${selectedProjectId}/${vp.name}`);
              }}
              // color={theme.blings_teal
              // selectedVideoPartName === vp.name ? theme.primary : undefined
              // }
              // style={{backgroundColor: selectedVideoPartName === vp.name ? '#ff00ff' : '#00ff00'}}
            >
              <Space>
                {vp.name}
                <Popconfirm
                  id="delete-video-part"
                  title="Are you sure to delete this part?"
                  onConfirm={() => {
                    deleteVideoPart(vp.name);
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <button
                    type="button"
                    className={"delete-video-part-btn"}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <DeleteOutlined style={{ fontSize: 10 }} />
                  </button>
                </Popconfirm>
              </Space>
            </Tag>
          ))}
      </div>
    </div>
  );
});
