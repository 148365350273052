import { rgbaArrToHexStr } from "../../../helpers/colors";
import { Space } from "antd";
import { IThemeColorChangeMod } from "@blings/blings-player";

function ColorBox({ clr }: { clr: string | ColorArr }) {
  const bg = typeof clr === "string" ? clr : rgbaArrToHexStr(clr);
  return (
    <div
      title={bg}
      style={{
        // border: '1px dashed #555',
        // boxSizing:'content-box',
        width: "0.5rem",
        height: "0.5rem",
        backgroundColor: bg,
      }}
    />
  );
}

export function getColorTitles(c: IThemeColorChangeMod, t: string) {
  const maxDisplayedColors = 3;
  return (
    <Space>
      {c.froms.slice(0, maxDisplayedColors).map((f, i) => (
        <ColorBox key={i} clr={f} />
      ))}
      {c.froms.length > maxDisplayedColors
        ? `(+${c.froms.length - maxDisplayedColors})`
        : undefined}
      {"=>"}
      {c.value ? <ColorBox clr={t} /> : t}
    </Space>
  );
}
