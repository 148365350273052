export const hexToRgba = (hex: string): ColorArr => {
  const arr = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g);

  if (arr) {
    const a = arr.map((x) => parseInt(x, 16) / 255);

    if (a.length < 4) {
      a.push(1);
    }

    return a as [number, number, number, number];
  }

  return [1, 1, 1, 1];
};

export const rgbaArrToHexStr = (rgba: ColorArr): string => {
  const rgb = rgba[3] && rgba[3] === 1 ? rgba.slice(0, 3) : rgba;
  return (
    "#" +
    rgb
      //.slice(0, 3)
      .map((v) => {
        const c = Math.round(v * 255).toString(16);
        return c.length === 1 ? "0" + c : c;
      })
      .join("")
  );
};
