import { IInteractiveModJSExpression } from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";
import { JsEditor } from "../../../../components/Editor/JsEditor";

export const defaultFunctionForInteractionExpression = `(data, liveData, event, element, player) => {
  console.log("clicked") 
}`;
function renderActionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  return (
    <span className={"JsEditorAndToolTipContainer"}>
      <JsEditor
        defaultValue={
          props.change.functionString || defaultFunctionForInteractionExpression
        }
        onChange={(e: any) => props.onChange(props.index, "functionString", e)}
      />
      <InfoTooltip
        info={
          "This function will run every time the user interacts with the layer\\s"
        }
      />
    </span>
  );
}

export function AddInteractionJsExpressionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  return (
    <AddInteractiveHOC<IInteractiveModJSExpression>
      {...props}
      renderSpecificInteractive={renderActionForm}
    />
  );
}
