import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { rootStore } from "../../stores/main";
import { Button, Space } from "antd";
import { FrameIndicator } from "../Vid-Bottom-Ctrls/FrameIndicator";
import "./VidContainer.scss";
import { DemoWindow } from "../DemoWindow/DemoWindow";
import { SimpleLoader } from "../SimpleLoader";

const MSTContext = React.createContext(rootStore);
export const VidContainer = observer(() => {
  const {
    platformStore: { selectedVideoPart },
    dynamicDataStore: { liveControlCurrentData },
    playerStore: { videoBgColor, jsonFile },
    layoutStore: { schemaDrawerOpen },
  } = useContext(MSTContext);
  const [demoWinOpen, setDemoWinOpen] = useState(false);
  const { platformStore, playerStore } = useContext(MSTContext);
  return (
    <div
      className={`VidContainerWrapper ${schemaDrawerOpen ? "schemaDrawerOpen" : ""
        }`}
      hidden={!jsonFile}
    >
      <div className="VidContainerWrapper-inner-container">
        <div className="VidContainerWrapper-inner-container2">
          <div
            id="vid-container"
            className="vid-container"
            style={{
              backgroundImage: videoBgColor,
              backgroundSize: "60px 60px",
              backgroundPosition: "0 0, 30px 30px",
            }}
          />
        </div>
      </div>
      <div
        id="loader-container"
      >
        <div id="loader-inner-container">
          <SimpleLoader />
        </div>
      </div>
      <Space className="Vid-Bottom-Ctrls">
        <FrameIndicator />
        {demoWinOpen && platformStore.projectWorkspaceVersion && (
          <DemoWindow
            openWin={setDemoWinOpen}
            selectedVideoPart={selectedVideoPart}
            project={platformStore.projectWorkspaceVersion}
            posterFrame={playerStore.player?.posterFrame || 0}
            autoplay={false}
            height={jsonFile.h}
            liveControlData={liveControlCurrentData}
          ></DemoWindow>
        )}
        <Button
          type="primary"
          onClick={() => {
            const p = (window as any).p;
            if (!p.animation.isPaused) {
              p.pause();
              p.pausedByUser = true;
            }
            setDemoWinOpen(true);
          }}
        >
          View Draft
        </Button>
      </Space>
    </div>
  );
});
