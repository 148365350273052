import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { shortenTextAEStyle } from "../../helpers/text";

export function PanelHeader({
  handleRemove,
  formTitleText,
  formTitleText2,
  // Icon,
  color,
  handleCopy,
  modTitle,
}: {
  // Icon: React.ElementType;
  color: string;
  handleRemove;
  handleCopy;
  formTitleText: string;
  formTitleText2?: [string, (a: any) => JSX.Element];
  modTitle: string;
}) {
  const TitleTexts = () => {
    if (formTitleText2) {
      return (
        <>
          <div
            className={"modPanelText"}
            style={{ flex: 0.8 }}
            title={formTitleText}
          >
            {shortenTextAEStyle(formTitleText, 20)}
          </div>
          <div
            className={"modPanelText2"}
            title={
              typeof formTitleText2[0] === "string"
                ? formTitleText2[0]
                : undefined
            }
          >
            {formTitleText2[1]} {formTitleText2[0]}
          </div>
        </>
      );
    } else
      return (
        <div className={"modPanelText"} title={formTitleText}>
          {formTitleText}
        </div>
      );
  };

  return (
    <div
      className={"ModsListPanelHeader"}
      style={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div className="modIconTitleWrapper">
        {/*<span className={"modTitle"}>{modTitle}</span>*/}
        <Tooltip
          overlay={modTitle + " CONNECTOR"}
          placement={"leftTop"}
          overlayStyle={{ fontSize: "70%" }}
        >
          <div
            className="modIcon"
            title={modTitle}
            style={{ backgroundColor: color }}
          >
            {/*<Icon style={{ color: "#fff" }} />*/}
          </div>
        </Tooltip>
      </div>
      <TitleTexts />

      <div className={"modPanelBtns"}>
        <Popconfirm
          title='Delete Connector? (nothing is saved till you press "Save Connectors")'
          onConfirm={(event) => {
            event?.stopPropagation();
            event?.preventDefault();
            handleRemove();
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className={"modPanelBtn modPanelBtn_remove"}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            type="dashed"
            title={"Delete Connector"}
          >
            <DeleteOutlined />
          </Button>
        </Popconfirm>

        <Button
          className={"modPanelBtn modPanelBtn_copy"}
          type="dashed"
          onClick={handleCopy}
          title={"Copy Connector"}
        >
          <CopyOutlined />
        </Button>
      </div>
    </div>
  );
}
