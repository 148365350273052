import { getRoot, Instance, types } from "mobx-state-tree";
import { RootInstance } from "./main";
import { JSONSchema7 } from "json-schema";
import { jsonSchemaGetExamples } from "../helpers/jsonShema.helpers";
export const DynamicDataModel = types
  .model({
    liveControlCurrentSchema: types.maybeNull(types.string),
    perVideoCurrentSchema: types.maybeNull(types.string),
    perVideoCurrentData: types.frozen(),
    liveControlCurrentData: types.frozen(),
    liveControlInitialSchema: types.maybeNull(types.string),
    perVideoInitialSchema: types.maybeNull(types.string),
    initialPerVideoData: types.frozen(),
    initialLiveControlData: types.frozen(),
  })
  .views((self) => ({
    get hasUnsavedChanges() {
      return (
        self.liveControlInitialSchema !== self.liveControlCurrentSchema ||
        JSON.stringify(self.initialLiveControlData) !==
          JSON.stringify(self.liveControlCurrentData) ||
        self.perVideoInitialSchema !== self.perVideoCurrentSchema
      );
    },
  }))
  .actions((self) => ({
    setInitialPerVideoSchema(schema: string) {
      self.perVideoInitialSchema = schema;
      const data = jsonSchemaGetExamples(
        JSON.parse(self.perVideoInitialSchema)
      );
      self.perVideoCurrentSchema = schema;
      this.setInitialPerVideoData(data);
    },
    setInitialLiveControSchemaAndData(schema: string, liveControlData: string) {
      self.liveControlInitialSchema = schema;
      self.liveControlCurrentSchema = schema;
      this.setInitialLiveControlData(JSON.parse(liveControlData));
    },

    setInitialPerVideoData(data) {
      self.initialPerVideoData = data;
      this.setCurrentPerVideoData(data);
    },
    setInitialLiveControlData(data) {
      self.initialLiveControlData = data;
      this.setCurrentLiveControlData(data);
    },

    setCurrentPerVideoSchema(schema: JSONSchema7) {
      self.perVideoCurrentSchema = JSON.stringify(schema);
    },
    setCurrentLiveControlSchema(schema: JSONSchema7) {
      self.liveControlCurrentSchema = JSON.stringify(schema);
    },

    setCurrentPerVideoData(data) {
      self.perVideoCurrentData = data;
      getRoot<RootInstance>(self).playerStore.runPlayer();
    },
    setCurrentLiveControlData(data) {
      self.liveControlCurrentData = data;
      getRoot<RootInstance>(self).playerStore.runPlayer();
    },
  }));

export type IDynamicDataModel = Instance<typeof DynamicDataModel>;
