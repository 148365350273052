import * as React from "react";
import { NoProjectSelectedScreen } from "./NoProjectSelectedScreen";
import { Layout } from "antd";
import { SideBar } from "./playground-left-sidebar/SideBar";
import { VidContainer } from "./MainVidPlayer/VidContainer";
import { SchemaEditors } from "./RightPanel/SchemaEditors";
import { useContext } from "react";
import { MSTContext } from "../stores/main";
import { observer } from "mobx-react-lite";

type Props = {};
const { Content } = Layout;

export const MainLayout = observer((props: Props) => {
  const {
    platformStore: {
      selectedProjectId,
      allProjects,
      initialProjectLoadSuccess,
    },
  } = useContext(MSTContext);
  const selectedProjectExists = allProjects.find(
    (project) => project.id === selectedProjectId
  );
  return !selectedProjectId ? (
    <NoProjectSelectedScreen
      hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
      projectExists={true}
    />
  ) : (
    selectedProjectExists !== undefined ?
      (
        <Layout className={"main"}>
          <SideBar />
          <Content>
            <VidContainer />
            <SchemaEditors />
          </Content>
        </Layout>
      )
      :
      (
        <NoProjectSelectedScreen
          hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
          projectExists={selectedProjectExists !== undefined}
        />
      )
  );
});