import React from "react";
import { useMst } from "../../stores/main";
import { observer } from "mobx-react-lite";
export const PublishStatus = observer(() => {
  const {
    platformStore: { selectedProjectBasicInfo, projectWorkspaceVersion, },
    dynamicDataStore: {
      hasUnsavedChanges: hasUnsavedDynamicDataChanges
    },
    modsStore: { hasUnsavedChanges: hasUnsavedModsChanges },
  } = useMst();
  const isLiveVersion = (selectedProjectBasicInfo?.updatedAt && ((projectWorkspaceVersion?.updatedAt && projectWorkspaceVersion.updatedAt < selectedProjectBasicInfo.updatedAt) || selectedProjectBasicInfo?.updatedAt === selectedProjectBasicInfo?.createdAt));
  const hasUnsavedChanges = hasUnsavedModsChanges || hasUnsavedDynamicDataChanges;
  if (isLiveVersion && !hasUnsavedChanges) {
    const editText = savedDate2Relative(selectedProjectBasicInfo.updatedAt);
    const savedDate = new Date(selectedProjectBasicInfo?.updatedAt);
    return <div className="PublishStatus" title={savedDate.toString()}>
      <p>{`Published version. Latest version was published ${editText}`}</p>
      </div>;
  } else {
    if (projectWorkspaceVersion?.updatedAt) {
      const editText = savedDate2Relative(projectWorkspaceVersion.updatedAt);
      const savedDate = new Date(projectWorkspaceVersion?.updatedAt);
      return <div className="PublishStatus" title={savedDate.toString()}>
        <p>{`Unpublished version. Last edit was ${editText}`}</p>
      </div>;
    }
  }
  return null;
});

function savedDate2Relative(date: string) {
  const TO_SECONDS = 1000;
  const TO_MINUTES = TO_SECONDS * 60;
  const TO_HOURS = TO_MINUTES * 60;
  const TO_DAYS = TO_HOURS * 24;
  const TO_WEEKS = TO_DAYS * 7;

  const now = Date.now();
  const savedAt = Date.parse(date);
  let editText = "";
  const diff = now - savedAt;
  const savedDate = new Date(date);
  if (diff / TO_WEEKS > 1) {
    const currentYear = new Date().getFullYear();
    if (currentYear === savedDate.getFullYear()) {
      editText = `on ${savedDate.toLocaleDateString("default", { month: "long", day: "numeric", year: "numeric" })}`;
    } else {
      editText = `on ${savedDate.toLocaleDateString("default", { month: "long", day: "numeric" })}`;
    }
  } else if (diff / TO_DAYS > 1) {
    const days = Math.floor(diff / TO_DAYS);
    editText = `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (diff / TO_HOURS > 1) {
    const hours = Math.floor(diff / TO_HOURS);
    editText = `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (diff / TO_MINUTES > 1) {
    const minutes = Math.floor(diff / TO_MINUTES);
    editText = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    editText = "seconds ago";
  }
  return editText;
}
