/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateProjectInput = {
  id: string,
  projectAccountId?: string | null,
  title?: string | null,
  videoParts?: Array< VidPartInput > | null,
  allowCreateDataPoint?: boolean | null,
  allowDataConnect?: boolean | null,
  analyticsReportUrl?: string | null,
  createdAt?: string | null,
  dataFiles?: Array< DataFileInput | null > | null,
  description?: string | null,
  fileName?: string | null,
  jsonChanges?: string | null,
  jsonVidUrl?: string | null,
  playerSettings?: PlayerSettingsInput | null,
  settings?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  thumbS3Url?: string | null,
  owner?: string | null,
  playerVersionToUse?: string | null,
  customHtml?: string | null,
};

export type VidPartInput = {
  name: string,
  jsonUrl: string,
  modsArr?: Array< ModInput > | null,
  mods?: string | null,
  fonts?: Array< FontInput > | null,
  updatedAt?: string | null,
  hasVideo?: boolean | null,
};

export type ModInput = {
  id: number,
  dataStr: string,
  origin?: string | null,
};

export type FontInput = {
  family: string,
  url: string,
  weight: string,
  style: string,
};

export type DataFileInput = {
  fileName?: string | null,
  tag?: string | null,
  createdAt?: string | null,
};

export type PlayerSettingsInput = {
  posterFrame?: number | null,
  showTimeline?: boolean | null,
  storiesMode?: boolean | null,
  color_loader?: string | null,
  color_ctrlBtns?: string | null,
  color_rail?: string | null,
  color_progress?: string | null,
  color_thumb?: string | null,
  color_bg?: string | null,
  muted?: boolean | null,
  autoplay?: boolean | null,
  autoReplay?: boolean | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  projectAccountId: string,
  title: string,
  account: Account,
  videoParts?:  Array<VidPart > | null,
  allowCreateDataPoint?: boolean | null,
  allowDataConnect?: boolean | null,
  analyticsReportUrl?: string | null,
  createdAt?: string | null,
  dataFiles?:  Array<DataFile | null > | null,
  description?: string | null,
  fileName?: string | null,
  jsonChanges?: string | null,
  jsonVidUrl?: string | null,
  playerSettings?: PlayerSettings | null,
  projectVersions?: ModelProjectVersionConnection | null,
  settings?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  thumbS3Url?: string | null,
  analyticsEnabled?: boolean | null,
  owner?: string | null,
  renders?: ModelServerRenderConnection | null,
  notifications?: ModelNotificationConnection | null,
  playerVersionToUse?: string | null,
  customHtml?: string | null,
  updatedAt: string,
};

export type Account = {
  __typename: "Account",
  id: string,
  gid: string,
  name: string,
  projects?: ModelProjectConnection | null,
  fileuploads?:  Array<FileUpload | null > | null,
  integrations?: Integrations | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type FileUpload = {
  __typename: "FileUpload",
  originalName?: string | null,
  createdAt: string,
  fileName: string,
  fileStatus?: string | null,
  fileError?: string | null,
  numRecords?: number | null,
  numErrors?: number | null,
  totalRecords?: number | null,
  writtenRecords?: number | null,
};

export type Integrations = {
  __typename: "Integrations",
  hubspot?: string | null,
};

export type VidPart = {
  __typename: "VidPart",
  name: string,
  jsonUrl: string,
  modsArr?:  Array<Mod > | null,
  mods?: string | null,
  fonts?:  Array<Font > | null,
  updatedAt?: string | null,
  hasVideo?: boolean | null,
};

export type Mod = {
  __typename: "Mod",
  id: number,
  dataStr: string,
  origin?: string | null,
};

export type Font = {
  __typename: "Font",
  family: string,
  url: string,
  weight: string,
  style: string,
};

export type DataFile = {
  __typename: "DataFile",
  fileName?: string | null,
  tag?: string | null,
  createdAt?: string | null,
};

export type PlayerSettings = {
  __typename: "PlayerSettings",
  posterFrame?: number | null,
  showTimeline?: boolean | null,
  storiesMode?: boolean | null,
  color_loader?: string | null,
  color_ctrlBtns?: string | null,
  color_rail?: string | null,
  color_progress?: string | null,
  color_thumb?: string | null,
  color_bg?: string | null,
  muted?: boolean | null,
  autoplay?: boolean | null,
  autoReplay?: boolean | null,
};

export type ModelProjectVersionConnection = {
  __typename: "ModelProjectVersionConnection",
  items:  Array<ProjectVersion | null >,
  nextToken?: string | null,
};

export type ProjectVersion = {
  __typename: "ProjectVersion",
  id: string,
  accountOwner: string,
  owner?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  settings?: string | null,
  videoParts?:  Array<VidPart > | null,
  publishedProject?: Project | null,
  updatedAt?: string | null,
  playerVersionToUse?: string | null,
  createdAt: string,
  projectProjectVersionsId?: string | null,
};

export type ModelServerRenderConnection = {
  __typename: "ModelServerRenderConnection",
  items:  Array<ServerRender | null >,
  nextToken?: string | null,
};

export type ServerRender = {
  __typename: "ServerRender",
  id: string,
  projectId: string,
  project: Project,
  scenes: Array< string >,
  data?: string | null,
  env?: string | null,
  outputFileName?: string | null,
  quality?: number | null,
  frameToStart?: number | null,
  frameToEnd?: number | null,
  format?: string | null,
  status?: ProjectStatus | null,
  cognitoUserIdentifier?: string | null,
  cognitoGroupOwner?: string | null,
  createdAt?: string | null,
  playerVersion?: string | null,
  updatedAt: string,
};

export type ProjectStatus = {
  __typename: "ProjectStatus",
  ready?: string | null,
  status?: string | null,
  downloadLink?: string | null,
  code?: string | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  type: string,
  subType?: string | null,
  status: NotificationStatus,
  title: string,
  message: string,
  read: boolean,
  owner: string,
  projectId?: string | null,
  project?: Project | null,
  createdAt: string,
  extra?: string | null,
  updatedAt: string,
  projectNotificationsId?: string | null,
};

export enum NotificationStatus {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}


export type CreateAccountInput = {
  id?: string | null,
  gid: string,
  name: string,
  fileuploads?: Array< FileUploadInput | null > | null,
  integrations?: IntegrationsInput | null,
};

export type FileUploadInput = {
  originalName?: string | null,
  createdAt?: string | null,
  fileName: string,
  fileStatus?: string | null,
  fileError?: string | null,
  numRecords?: number | null,
  numErrors?: number | null,
  totalRecords?: number | null,
  writtenRecords?: number | null,
};

export type IntegrationsInput = {
  hubspot?: string | null,
};

export type ModelAccountConditionInput = {
  gid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UpdateAccountInput = {
  id: string,
  gid?: string | null,
  name?: string | null,
  fileuploads?: Array< FileUploadInput | null > | null,
  integrations?: IntegrationsInput | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  type: string,
  subType?: string | null,
  status: NotificationStatus,
  title: string,
  message: string,
  read: boolean,
  owner: string,
  projectId?: string | null,
  createdAt?: string | null,
  extra?: string | null,
  projectNotificationsId?: string | null,
};

export type ModelNotificationConditionInput = {
  type?: ModelStringInput | null,
  subType?: ModelStringInput | null,
  status?: ModelNotificationStatusInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  projectNotificationsId?: ModelIDInput | null,
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null,
  ne?: NotificationStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateNotificationInput = {
  id: string,
  type?: string | null,
  subType?: string | null,
  status?: NotificationStatus | null,
  title?: string | null,
  message?: string | null,
  read?: boolean | null,
  owner?: string | null,
  projectId?: string | null,
  createdAt?: string | null,
  extra?: string | null,
  projectNotificationsId?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateProjectInput = {
  id?: string | null,
  projectAccountId: string,
  title: string,
  videoParts?: Array< VidPartInput > | null,
  allowCreateDataPoint?: boolean | null,
  allowDataConnect?: boolean | null,
  analyticsReportUrl?: string | null,
  createdAt?: string | null,
  dataFiles?: Array< DataFileInput | null > | null,
  description?: string | null,
  fileName?: string | null,
  jsonChanges?: string | null,
  jsonVidUrl?: string | null,
  playerSettings?: PlayerSettingsInput | null,
  settings?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  thumbS3Url?: string | null,
  analyticsEnabled?: boolean | null,
  owner?: string | null,
  playerVersionToUse?: string | null,
  customHtml?: string | null,
};

export type ModelProjectConditionInput = {
  projectAccountId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  allowCreateDataPoint?: ModelBooleanInput | null,
  allowDataConnect?: ModelBooleanInput | null,
  analyticsReportUrl?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  jsonChanges?: ModelStringInput | null,
  jsonVidUrl?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  stateJsonSchemaStr?: ModelStringInput | null,
  settingsJsonSchemaStr?: ModelStringInput | null,
  thumbS3Url?: ModelStringInput | null,
  analyticsEnabled?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  playerVersionToUse?: ModelStringInput | null,
  customHtml?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateProjectVersionInput = {
  id?: string | null,
  accountOwner: string,
  owner?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  settings?: string | null,
  videoParts?: Array< VidPartInput > | null,
  updatedAt?: string | null,
  playerVersionToUse?: string | null,
  projectProjectVersionsId?: string | null,
};

export type ModelProjectVersionConditionInput = {
  accountOwner?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  stateJsonSchemaStr?: ModelStringInput | null,
  settingsJsonSchemaStr?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  playerVersionToUse?: ModelStringInput | null,
  and?: Array< ModelProjectVersionConditionInput | null > | null,
  or?: Array< ModelProjectVersionConditionInput | null > | null,
  not?: ModelProjectVersionConditionInput | null,
  projectProjectVersionsId?: ModelIDInput | null,
};

export type UpdateProjectVersionInput = {
  id: string,
  accountOwner?: string | null,
  owner?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  settings?: string | null,
  videoParts?: Array< VidPartInput > | null,
  updatedAt?: string | null,
  playerVersionToUse?: string | null,
  projectProjectVersionsId?: string | null,
};

export type DeleteProjectVersionInput = {
  id: string,
};

export type CreateServerRenderInput = {
  id?: string | null,
  projectId: string,
  scenes: Array< string >,
  data?: string | null,
  env?: string | null,
  outputFileName?: string | null,
  quality?: number | null,
  frameToStart?: number | null,
  frameToEnd?: number | null,
  format?: string | null,
  status?: ProjectStatusInput | null,
  cognitoUserIdentifier?: string | null,
  cognitoGroupOwner?: string | null,
  createdAt?: string | null,
  playerVersion?: string | null,
};

export type ProjectStatusInput = {
  ready?: string | null,
  status?: string | null,
  downloadLink?: string | null,
  code?: string | null,
};

export type ModelServerRenderConditionInput = {
  projectId?: ModelIDInput | null,
  scenes?: ModelStringInput | null,
  data?: ModelStringInput | null,
  env?: ModelStringInput | null,
  outputFileName?: ModelStringInput | null,
  quality?: ModelIntInput | null,
  frameToStart?: ModelIntInput | null,
  frameToEnd?: ModelIntInput | null,
  format?: ModelStringInput | null,
  cognitoUserIdentifier?: ModelStringInput | null,
  cognitoGroupOwner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  playerVersion?: ModelStringInput | null,
  and?: Array< ModelServerRenderConditionInput | null > | null,
  or?: Array< ModelServerRenderConditionInput | null > | null,
  not?: ModelServerRenderConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateServerRenderInput = {
  id: string,
  projectId?: string | null,
  scenes?: Array< string > | null,
  data?: string | null,
  env?: string | null,
  outputFileName?: string | null,
  quality?: number | null,
  frameToStart?: number | null,
  frameToEnd?: number | null,
  format?: string | null,
  status?: ProjectStatusInput | null,
  cognitoUserIdentifier?: string | null,
  cognitoGroupOwner?: string | null,
  createdAt?: string | null,
  playerVersion?: string | null,
};

export type DeleteServerRenderInput = {
  id: string,
};

export type SendInstantNotificationInput = {
  type: string,
  subType?: string | null,
  status: NotificationStatus,
  title: string,
  message: string,
  owner: string,
  projectId?: string | null,
  extra?: string | null,
};

export type InstantNotification = {
  __typename: "InstantNotification",
  type: string,
  subType?: string | null,
  status: NotificationStatus,
  title: string,
  message: string,
  owner: string,
  projectId?: string | null,
  extra?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  gid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  subType?: ModelStringInput | null,
  status?: ModelNotificationStatusInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
  projectNotificationsId?: ModelIDInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  projectAccountId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  allowCreateDataPoint?: ModelBooleanInput | null,
  allowDataConnect?: ModelBooleanInput | null,
  analyticsReportUrl?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  jsonChanges?: ModelStringInput | null,
  jsonVidUrl?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  stateJsonSchemaStr?: ModelStringInput | null,
  settingsJsonSchemaStr?: ModelStringInput | null,
  thumbS3Url?: ModelStringInput | null,
  analyticsEnabled?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  playerVersionToUse?: ModelStringInput | null,
  customHtml?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ProjectChangeActivity = {
  __typename: "ProjectChangeActivity",
  projectId: string,
  before?: ProjectDataChangeActivity | null,
  after?: ProjectDataChangeActivity | null,
  owner?: string | null,
  origin?: string | null,
  accountOwner: string,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type ProjectDataChangeActivity = {
  __typename: "ProjectDataChangeActivity",
  projectAccountId?: string | null,
  title?: string | null,
  videoParts?:  Array<VidPart | null > | null,
  allowCreateDataPoint?: boolean | null,
  allowDataConnect?: boolean | null,
  analyticsReportUrl?: string | null,
  createdAt?: string | null,
  dataFiles?:  Array<DataFile | null > | null,
  description?: string | null,
  fileName?: string | null,
  jsonChanges?: string | null,
  jsonVidUrl?: string | null,
  playerSettings?: PlayerSettings | null,
  settings?: string | null,
  stateJsonSchemaStr?: string | null,
  settingsJsonSchemaStr?: string | null,
  thumbS3Url?: string | null,
  type: string,
  owner?: string | null,
};

export type ModelProjectChangeActivityFilterInput = {
  projectId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  accountOwner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProjectChangeActivityFilterInput | null > | null,
  or?: Array< ModelProjectChangeActivityFilterInput | null > | null,
  not?: ModelProjectChangeActivityFilterInput | null,
};

export type ModelProjectChangeActivityConnection = {
  __typename: "ModelProjectChangeActivityConnection",
  items:  Array<ProjectChangeActivity | null >,
  nextToken?: string | null,
};

export type ModelProjectVersionFilterInput = {
  id?: ModelIDInput | null,
  accountOwner?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  stateJsonSchemaStr?: ModelStringInput | null,
  settingsJsonSchemaStr?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  playerVersionToUse?: ModelStringInput | null,
  and?: Array< ModelProjectVersionFilterInput | null > | null,
  or?: Array< ModelProjectVersionFilterInput | null > | null,
  not?: ModelProjectVersionFilterInput | null,
  projectProjectVersionsId?: ModelIDInput | null,
};

export type ModelServerRenderFilterInput = {
  id?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  scenes?: ModelStringInput | null,
  data?: ModelStringInput | null,
  env?: ModelStringInput | null,
  outputFileName?: ModelStringInput | null,
  quality?: ModelIntInput | null,
  frameToStart?: ModelIntInput | null,
  frameToEnd?: ModelIntInput | null,
  format?: ModelStringInput | null,
  cognitoUserIdentifier?: ModelStringInput | null,
  cognitoGroupOwner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  playerVersion?: ModelStringInput | null,
  and?: Array< ModelServerRenderFilterInput | null > | null,
  or?: Array< ModelServerRenderFilterInput | null > | null,
  not?: ModelServerRenderFilterInput | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  subType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  read?: ModelSubscriptionBooleanInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  extra?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  allowCreateDataPoint?: ModelSubscriptionBooleanInput | null,
  allowDataConnect?: ModelSubscriptionBooleanInput | null,
  analyticsReportUrl?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  jsonChanges?: ModelSubscriptionStringInput | null,
  jsonVidUrl?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  stateJsonSchemaStr?: ModelSubscriptionStringInput | null,
  settingsJsonSchemaStr?: ModelSubscriptionStringInput | null,
  thumbS3Url?: ModelSubscriptionStringInput | null,
  analyticsEnabled?: ModelSubscriptionBooleanInput | null,
  playerVersionToUse?: ModelSubscriptionStringInput | null,
  customHtml?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionProjectVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stateJsonSchemaStr?: ModelSubscriptionStringInput | null,
  settingsJsonSchemaStr?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  playerVersionToUse?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectVersionFilterInput | null > | null,
};

export type ModelSubscriptionServerRenderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  scenes?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  env?: ModelSubscriptionStringInput | null,
  outputFileName?: ModelSubscriptionStringInput | null,
  quality?: ModelSubscriptionIntInput | null,
  frameToStart?: ModelSubscriptionIntInput | null,
  frameToEnd?: ModelSubscriptionIntInput | null,
  format?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  playerVersion?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServerRenderFilterInput | null > | null,
  or?: Array< ModelSubscriptionServerRenderFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateProjectVersionMutationVariables = {
  input: CreateProjectVersionInput,
  condition?: ModelProjectVersionConditionInput | null,
};

export type CreateProjectVersionMutation = {
  createProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type UpdateProjectVersionMutationVariables = {
  input: UpdateProjectVersionInput,
  condition?: ModelProjectVersionConditionInput | null,
};

export type UpdateProjectVersionMutation = {
  updateProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type DeleteProjectVersionMutationVariables = {
  input: DeleteProjectVersionInput,
  condition?: ModelProjectVersionConditionInput | null,
};

export type DeleteProjectVersionMutation = {
  deleteProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type CreateServerRenderMutationVariables = {
  input: CreateServerRenderInput,
  condition?: ModelServerRenderConditionInput | null,
};

export type CreateServerRenderMutation = {
  createServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateServerRenderMutationVariables = {
  input: UpdateServerRenderInput,
  condition?: ModelServerRenderConditionInput | null,
};

export type UpdateServerRenderMutation = {
  updateServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteServerRenderMutationVariables = {
  input: DeleteServerRenderInput,
  condition?: ModelServerRenderConditionInput | null,
};

export type DeleteServerRenderMutation = {
  deleteServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type SendInstantNotificationMutationVariables = {
  input?: SendInstantNotificationInput | null,
};

export type SendInstantNotificationMutation = {
  sendInstantNotification?:  {
    __typename: "InstantNotification",
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    owner: string,
    projectId?: string | null,
    extra?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      subType?: string | null,
      status: NotificationStatus,
      title: string,
      message: string,
      read: boolean,
      owner: string,
      projectId?: string | null,
      createdAt: string,
      extra?: string | null,
      updatedAt: string,
      projectNotificationsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByTypeAndCreatedAtQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByTypeAndCreatedAtQuery = {
  notificationsByTypeAndCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      subType?: string | null,
      status: NotificationStatus,
      title: string,
      message: string,
      read: boolean,
      owner: string,
      projectId?: string | null,
      createdAt: string,
      extra?: string | null,
      updatedAt: string,
      projectNotificationsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByOwnerAndCreatedAtQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByOwnerAndCreatedAtQuery = {
  notificationsByOwnerAndCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      subType?: string | null,
      status: NotificationStatus,
      title: string,
      message: string,
      read: boolean,
      owner: string,
      projectId?: string | null,
      createdAt: string,
      extra?: string | null,
      updatedAt: string,
      projectNotificationsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByProjectIdAndCreatedAtQueryVariables = {
  projectId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByProjectIdAndCreatedAtQuery = {
  notificationsByProjectIdAndCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: string,
      subType?: string | null,
      status: NotificationStatus,
      title: string,
      message: string,
      read: boolean,
      owner: string,
      projectId?: string | null,
      createdAt: string,
      extra?: string | null,
      updatedAt: string,
      projectNotificationsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByProjectAccountIdAndCreatedAtQueryVariables = {
  projectAccountId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByProjectAccountIdAndCreatedAtQuery = {
  projectsByProjectAccountIdAndCreatedAt?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectChangeActivityQueryVariables = {
  id: string,
};

export type GetProjectChangeActivityQuery = {
  getProjectChangeActivity?:  {
    __typename: "ProjectChangeActivity",
    projectId: string,
    before?:  {
      __typename: "ProjectDataChangeActivity",
      projectAccountId?: string | null,
      title?: string | null,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      type: string,
      owner?: string | null,
    } | null,
    after?:  {
      __typename: "ProjectDataChangeActivity",
      projectAccountId?: string | null,
      title?: string | null,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      type: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    origin?: string | null,
    accountOwner: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListProjectChangeActivitiesQueryVariables = {
  filter?: ModelProjectChangeActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectChangeActivitiesQuery = {
  listProjectChangeActivities?:  {
    __typename: "ModelProjectChangeActivityConnection",
    items:  Array< {
      __typename: "ProjectChangeActivity",
      projectId: string,
      owner?: string | null,
      origin?: string | null,
      accountOwner: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectChangeActivitiesByProjectIdAndCreatedAtQueryVariables = {
  projectId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectChangeActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectChangeActivitiesByProjectIdAndCreatedAtQuery = {
  projectChangeActivitiesByProjectIdAndCreatedAt?:  {
    __typename: "ModelProjectChangeActivityConnection",
    items:  Array< {
      __typename: "ProjectChangeActivity",
      projectId: string,
      owner?: string | null,
      origin?: string | null,
      accountOwner: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectVersionQueryVariables = {
  id: string,
};

export type GetProjectVersionQuery = {
  getProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type ListProjectVersionsQueryVariables = {
  filter?: ModelProjectVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectVersionsQuery = {
  listProjectVersions?:  {
    __typename: "ModelProjectVersionConnection",
    items:  Array< {
      __typename: "ProjectVersion",
      id: string,
      accountOwner: string,
      owner?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      settings?: string | null,
      updatedAt?: string | null,
      playerVersionToUse?: string | null,
      createdAt: string,
      projectProjectVersionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServerRenderQueryVariables = {
  id: string,
};

export type GetServerRenderQuery = {
  getServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type ListServerRendersQueryVariables = {
  filter?: ModelServerRenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServerRendersQuery = {
  listServerRenders?:  {
    __typename: "ModelServerRenderConnection",
    items:  Array< {
      __typename: "ServerRender",
      id: string,
      projectId: string,
      scenes: Array< string >,
      data?: string | null,
      env?: string | null,
      outputFileName?: string | null,
      quality?: number | null,
      frameToStart?: number | null,
      frameToEnd?: number | null,
      format?: string | null,
      cognitoUserIdentifier?: string | null,
      cognitoGroupOwner?: string | null,
      createdAt?: string | null,
      playerVersion?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ServerRendersByProjectIdAndCreatedAtQueryVariables = {
  projectId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServerRenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ServerRendersByProjectIdAndCreatedAtQuery = {
  serverRendersByProjectIdAndCreatedAt?:  {
    __typename: "ModelServerRenderConnection",
    items:  Array< {
      __typename: "ServerRender",
      id: string,
      projectId: string,
      scenes: Array< string >,
      data?: string | null,
      env?: string | null,
      outputFileName?: string | null,
      quality?: number | null,
      frameToStart?: number | null,
      frameToEnd?: number | null,
      format?: string | null,
      cognitoUserIdentifier?: string | null,
      cognitoGroupOwner?: string | null,
      createdAt?: string | null,
      playerVersion?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateProjectSubSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateProjectSubSubscription = {
  onUpdateProjectSub?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateProjectVersionSubSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateProjectVersionSubSubscription = {
  onUpdateProjectVersionSub?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type OnSendInstantNotificationSubscriptionVariables = {
  owner: string,
};

export type OnSendInstantNotificationSubscription = {
  onSendInstantNotification?:  {
    __typename: "InstantNotification",
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    owner: string,
    projectId?: string | null,
    extra?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    gid: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    fileuploads?:  Array< {
      __typename: "FileUpload",
      originalName?: string | null,
      createdAt: string,
      fileName: string,
      fileStatus?: string | null,
      fileError?: string | null,
      numRecords?: number | null,
      numErrors?: number | null,
      totalRecords?: number | null,
      writtenRecords?: number | null,
    } | null > | null,
    integrations?:  {
      __typename: "Integrations",
      hubspot?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: string,
    subType?: string | null,
    status: NotificationStatus,
    title: string,
    message: string,
    read: boolean,
    owner: string,
    projectId?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    createdAt: string,
    extra?: string | null,
    updatedAt: string,
    projectNotificationsId?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    projectAccountId: string,
    title: string,
    account:  {
      __typename: "Account",
      id: string,
      gid: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    },
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    allowCreateDataPoint?: boolean | null,
    allowDataConnect?: boolean | null,
    analyticsReportUrl?: string | null,
    createdAt?: string | null,
    dataFiles?:  Array< {
      __typename: "DataFile",
      fileName?: string | null,
      tag?: string | null,
      createdAt?: string | null,
    } | null > | null,
    description?: string | null,
    fileName?: string | null,
    jsonChanges?: string | null,
    jsonVidUrl?: string | null,
    playerSettings?:  {
      __typename: "PlayerSettings",
      posterFrame?: number | null,
      showTimeline?: boolean | null,
      storiesMode?: boolean | null,
      color_loader?: string | null,
      color_ctrlBtns?: string | null,
      color_rail?: string | null,
      color_progress?: string | null,
      color_thumb?: string | null,
      color_bg?: string | null,
      muted?: boolean | null,
      autoplay?: boolean | null,
      autoReplay?: boolean | null,
    } | null,
    projectVersions?:  {
      __typename: "ModelProjectVersionConnection",
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    thumbS3Url?: string | null,
    analyticsEnabled?: boolean | null,
    owner?: string | null,
    renders?:  {
      __typename: "ModelServerRenderConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "ModelNotificationConnection",
      nextToken?: string | null,
    } | null,
    playerVersionToUse?: string | null,
    customHtml?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateProjectVersionSubscriptionVariables = {
  filter?: ModelSubscriptionProjectVersionFilterInput | null,
  owner?: string | null,
};

export type OnCreateProjectVersionSubscription = {
  onCreateProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type OnUpdateProjectVersionSubscriptionVariables = {
  filter?: ModelSubscriptionProjectVersionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProjectVersionSubscription = {
  onUpdateProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type OnDeleteProjectVersionSubscriptionVariables = {
  filter?: ModelSubscriptionProjectVersionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProjectVersionSubscription = {
  onDeleteProjectVersion?:  {
    __typename: "ProjectVersion",
    id: string,
    accountOwner: string,
    owner?: string | null,
    stateJsonSchemaStr?: string | null,
    settingsJsonSchemaStr?: string | null,
    settings?: string | null,
    videoParts?:  Array< {
      __typename: "VidPart",
      name: string,
      jsonUrl: string,
      mods?: string | null,
      updatedAt?: string | null,
      hasVideo?: boolean | null,
    } > | null,
    publishedProject?:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    } | null,
    updatedAt?: string | null,
    playerVersionToUse?: string | null,
    createdAt: string,
    projectProjectVersionsId?: string | null,
  } | null,
};

export type OnCreateServerRenderSubscriptionVariables = {
  filter?: ModelSubscriptionServerRenderFilterInput | null,
  cognitoUserIdentifier?: string | null,
};

export type OnCreateServerRenderSubscription = {
  onCreateServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateServerRenderSubscriptionVariables = {
  filter?: ModelSubscriptionServerRenderFilterInput | null,
  cognitoUserIdentifier?: string | null,
};

export type OnUpdateServerRenderSubscription = {
  onUpdateServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteServerRenderSubscriptionVariables = {
  filter?: ModelSubscriptionServerRenderFilterInput | null,
  cognitoUserIdentifier?: string | null,
};

export type OnDeleteServerRenderSubscription = {
  onDeleteServerRender?:  {
    __typename: "ServerRender",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      projectAccountId: string,
      title: string,
      allowCreateDataPoint?: boolean | null,
      allowDataConnect?: boolean | null,
      analyticsReportUrl?: string | null,
      createdAt?: string | null,
      description?: string | null,
      fileName?: string | null,
      jsonChanges?: string | null,
      jsonVidUrl?: string | null,
      settings?: string | null,
      stateJsonSchemaStr?: string | null,
      settingsJsonSchemaStr?: string | null,
      thumbS3Url?: string | null,
      analyticsEnabled?: boolean | null,
      owner?: string | null,
      playerVersionToUse?: string | null,
      customHtml?: string | null,
      updatedAt: string,
    },
    scenes: Array< string >,
    data?: string | null,
    env?: string | null,
    outputFileName?: string | null,
    quality?: number | null,
    frameToStart?: number | null,
    frameToEnd?: number | null,
    format?: string | null,
    status?:  {
      __typename: "ProjectStatus",
      ready?: string | null,
      status?: string | null,
      downloadLink?: string | null,
      code?: string | null,
    } | null,
    cognitoUserIdentifier?: string | null,
    cognitoGroupOwner?: string | null,
    createdAt?: string | null,
    playerVersion?: string | null,
    updatedAt: string,
  } | null,
};
