import { getRoot, Instance, types } from "mobx-state-tree";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { Mod, ProjectVersion } from "../API";
import { updateProjectVersion } from "../graphql/mutations";
import { RootInstance } from "./main";
import { CDN, getENV } from "./consts";
import {
  AsyncOpState,
  asyncOpStateType,
  updateAsyncStatus,
} from "./async-op-state";
import {
  emptySchema,
  jsonSchemaGetExamples,
} from "../helpers/jsonShema.helpers";
import { flatObject } from "../helpers/objects";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import {
  onUpdateProjectSub,
  onUpdateProjectVersionSub,
} from "../graphql/subscriptions";
import Observable from "zen-observable";
import { cleanEmptyStringInValue } from "../helpers/objects";
import { UploadFile } from "antd/lib/upload/interface";
import {
  createVersion,
  getLatestProjectVersion,
  getPublishedProject,
  getPublishedProjectBasicInfo,
  publishVersion,
} from "../utils/projectTools";
import { Slide, toast } from "react-toastify";

export type ProjectBasicInfo = {
  title: string;
  id: string;
  updatedAt: string;
  createdAt: string;
};

export const PlatformModel = types
  .model({
    initialProjectLoadSuccess: types.optional(types.boolean, false),
    selectedProjectId: types.maybeNull(types.string),
    selectedProjectBasicInfo: types.maybeNull(types.frozen<ProjectBasicInfo>()),
    selectedVideoPartName: types.maybeNull(types.string),
    allProjects: types.optional(
      types.array(types.frozen<ProjectBasicInfo>()),
      []
    ),
    projectWorkspaceVersion: types.maybeNull(types.frozen<ProjectVersion>()),
    uploadJsonAndImagesStatus: asyncOpStateType,
    saveModsStatus: asyncOpStateType,
    saveProjectSchemaStatus: asyncOpStateType,
    saveProjectLiveControlSchemaStatus: asyncOpStateType,
    uploadAssetStatus: asyncOpStateType,
    dynamicFormUploadAssetStatus: types.map(
      types.model({ id: types.identifier, status: asyncOpStateType })
    ),
  })
  .views((self) => ({
    get selectedVideoPart() {
      return self.projectWorkspaceVersion?.videoParts?.find(
        (vp) => vp.name === self.selectedVideoPartName
      );
    },
    get platformStorageProjectAssetsRoot() {
      return `projects/${self.selectedProjectId}/assets`;
    },
    get selectedProjectDataSchema() {
      if (self.projectWorkspaceVersion?.stateJsonSchemaStr)
        return JSON.parse(self.projectWorkspaceVersion?.stateJsonSchemaStr);
      return emptySchema;
    },
    get selectedProjectLiveControlSchema() {
      if (self.projectWorkspaceVersion?.settingsJsonSchemaStr)
        return JSON.parse(self.projectWorkspaceVersion?.settingsJsonSchemaStr);
      return emptySchema;
    },
    get selectedProjectLiveControl() {
      if (self.projectWorkspaceVersion?.settings)
        return JSON.parse(self.projectWorkspaceVersion?.settings);
      return {};
    },
  }))
  .views((self) => ({
    get inputNames() {
      const inputNames: string[] = [];
      if (
        self.projectWorkspaceVersion &&
        self.projectWorkspaceVersion.videoParts
      ) {
        self.projectWorkspaceVersion.videoParts
          .map((vp) => vp.modsArr)
          .forEach((ma) => {
            ma?.map((mod) => (mod?.dataStr ? JSON.parse(mod.dataStr) : []))
              .filter((mod) => mod.type === "interactiveInput")
              .forEach((mod) => {
                inputNames.push(mod.value);
              });
          });
      }
      return inputNames;
    },
  }))
  .actions((self) => ({
    // ...updateAsyncStatus(self, 'uploadJsonAndImagesStatus'),
    updateUploadJsonAndImagesStatus: updateAsyncStatus(
      self,
      "uploadJsonAndImagesStatus"
    ),
    updateUploadAssetStatus: updateAsyncStatus(self, "uploadAssetStatus"),
    updateSaveModsStatus: updateAsyncStatus(self, "saveModsStatus"),
    updateSaveProjectSchemaStatus: updateAsyncStatus(
      self,
      "saveProjectSchemaStatus"
    ),
    updateSaveProjectLiveControlSchemaStatus: updateAsyncStatus(
      self,
      "saveProjectLiveControlSchemaStatus"
    ),
    getDynamicFormAssetsUploadStatus(id: string) {
      return self.dynamicFormUploadAssetStatus.get(id)?.status;
    },
  }))
  .actions((self) => {
    let selectedProjectObservable: ZenObservable.Subscription;
    let projectVersionObservable: ZenObservable.Subscription;
    return {
      async logout() {
        await Auth.signOut();
        window.location.reload();
      },
      setProjects(projects: ProjectBasicInfo[]) {
        self.initialProjectLoadSuccess = true;
        self.allProjects.replace(projects);
      },
      setWorkspaceProject(project: ProjectVersion | null) {
        self.projectWorkspaceVersion = project;
      },
      async loadProjects() {
        const listProjectBasicInfo = `query ListProjects(
          $filter: ModelProjectFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              title
              updatedAt
              createdAt
            }
            nextToken
          }
        }`;

        let nextToken = null;
        const projects: ProjectBasicInfo[] = [];
        do {
          const projectsData = (await API.graphql(
            graphqlOperation(listProjectBasicInfo, { nextToken })
          )) as { data: any };
          nextToken = projectsData?.data?.listProjects?.nextToken;
          if (projectsData?.data?.listProjects?.items) {
            projects.push(...projectsData.data.listProjects.items);
          }
        } while (nextToken);

        this.setProjects(projects.filter((p) => !!p.id));
      },
      async publishCurrentVersion() {
        if (self.projectWorkspaceVersion) {
          await publishVersion(self.projectWorkspaceVersion);
          await this.loadProjects();
        } else throw new Error("Workspace version is not defined");
      },
      updateSelectedVideoPart(name: string | null) {
        self.selectedVideoPartName = name;
      },
      setSelectedProjectBasicInfo(projectBasicInfo: ProjectBasicInfo) {
        self.selectedProjectBasicInfo = projectBasicInfo;
      },
      async selectProject(pid: string) {
        self.selectedProjectBasicInfo = null;
        this.setWorkspaceProject(null);
        self.selectedProjectId = pid;

        let jsonVidUrl;
        let mods: Array<Mod> | null | undefined;

        let latestProjectVersion = await getLatestProjectVersion(pid);
        if (!latestProjectVersion) {
          // if there is no workspace version, create one out of the published project
          const publishedProject = await getPublishedProject(pid);
          latestProjectVersion = await createVersion(publishedProject);
        }
        // TODO subscribe to version and published
        this.setSelectedProjectBasicInfo(
          await getPublishedProjectBasicInfo(pid)
        );
        this.setWorkspaceProject(latestProjectVersion);

        if (
          self.projectWorkspaceVersion?.videoParts &&
          self.projectWorkspaceVersion.videoParts.length
        ) {
          const selectedVideoPart = self.projectWorkspaceVersion.videoParts[0];
          this.updateSelectedVideoPart(selectedVideoPart.name);

          jsonVidUrl = selectedVideoPart.jsonUrl;
          mods = selectedVideoPart.modsArr;
        }

        this.updateJsonAndMods(jsonVidUrl, mods);
        const { dynamicDataStore } = getRoot<RootInstance>(self);
        dynamicDataStore.setInitialPerVideoSchema(
          latestProjectVersion.stateJsonSchemaStr || "{}"
        );
        dynamicDataStore.setInitialLiveControSchemaAndData(
          latestProjectVersion.settingsJsonSchemaStr || "{}",
          latestProjectVersion.settings || "{}"
        );
        projectVersionObservable?.unsubscribe();
        selectedProjectObservable?.unsubscribe();

        const modifiedSub = insertIntoGraphQlString(onUpdateProjectVersionSub, {
          path: ["videoParts"],
          key: "modsArr",
          value: {
            id: true,
            dataStr: true,
            origin: true,
          },
        });
        const responseWorkspaceProject = API.graphql(
          graphqlOperation(modifiedSub, {
            id: pid,
          })
        );
        const responseBasicInformation = API.graphql(
          graphqlOperation(onUpdateProjectSub, {
            id: pid,
          })
        );
        if (responseWorkspaceProject instanceof Observable) {
          projectVersionObservable = responseWorkspaceProject.subscribe({
            next: (value) => {
              if (value.errors) return console.error(value.errors);
              const workspaceProjectInDb = value.value.data
                .onUpdateProjectVersionSub as ProjectVersion;
              this.setWorkspaceProject(workspaceProjectInDb);
              const selectedVideoPart = workspaceProjectInDb.videoParts?.find(
                (videoPart) => videoPart.name === self.selectedVideoPartName
              );
              if (selectedVideoPart) {
                getRoot<RootInstance>(
                  self
                ).modsStore.mergeWithModsFromSubscription(
                  selectedVideoPart.modsArr
                );
              }
              const { dynamicDataStore } = getRoot<RootInstance>(self);
              dynamicDataStore.setInitialLiveControSchemaAndData(
                workspaceProjectInDb.settingsJsonSchemaStr || "{}",
                workspaceProjectInDb.settings || "{}"
              );
              // dont cahnge the initials values if you already have.
              if (!dynamicDataStore.initialPerVideoData) {
                dynamicDataStore.setInitialPerVideoData(
                  jsonSchemaGetExamples(self.selectedProjectDataSchema)
                );
              }
            },
            error: (errorValue) => {
              console.error(errorValue);
              selectedProjectObservable.unsubscribe();
            },
          });
        }
        if (responseBasicInformation instanceof Observable) {
          selectedProjectObservable = responseBasicInformation.subscribe({
            next: (value) => {
              if (value.errors) return console.error(value.errors);
              const basicInfo = value.value.data.onUpdateProjectSub as {
                id: string;
                title: string;
                updatedAt: string;
                createdAt: string;
              };
              this.setSelectedProjectBasicInfo(basicInfo);
            },
            error: (errorValue) => {
              console.error(errorValue);
              selectedProjectObservable.unsubscribe();
            },
          });
        }
      },
      updateJsonAndMods(jsonVidUrl: string, mods?: Array<Mod> | null): void {
        this.updateJson(jsonVidUrl);
        getRoot<RootInstance>(self).modsStore.replaceMods(mods);
      },
      updateJson(jsonVidUrl: string): void {
        const playerStore = getRoot<RootInstance>(self).playerStore;
        if (jsonVidUrl) {
          playerStore.downloadJsonFile(jsonVidUrl);
        } else {
          playerStore.removePlayer();
        }
      },
      changeVideoPart(selectedVideoPartName) {
        if (
          self.projectWorkspaceVersion &&
          self.projectWorkspaceVersion.videoParts
        ) {
          getRoot<RootInstance>(self).modsStore.setActiveMod(); // Reset active mod
          self.selectedVideoPartName = selectedVideoPartName;
          const selectedVideoPart = self.projectWorkspaceVersion.videoParts.find(
            (vp) => vp.name === selectedVideoPartName
          );
          if (selectedVideoPart) {
            this.updateJsonAndMods(
              selectedVideoPart.jsonUrl,
              selectedVideoPart.modsArr || null
            );
          }
        }
      },

      async deleteVideoPart(videoPartName) {
        const vps = self.projectWorkspaceVersion?.videoParts?.filter(
          (vp) => vp.name !== videoPartName
        );
        if (vps) {
          await API.graphql(
            graphqlOperation(updateProjectVersion, {
              input: { id: self.selectedProjectId, videoParts: vps },
            })
          );
          const projectWorkspaceVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );
          projectWorkspaceVersion.videoParts = vps;
          this.setWorkspaceProject(projectWorkspaceVersion);
        }
      },
      async savePerVideoSchema(schema: any) {
        if (!self.projectWorkspaceVersion) {
          return;
        }
        /* const schema = createSchemaFromData(data); */
        self.updateSaveProjectSchemaStatus(AsyncOpState.Saving);
        try {
          const modifiedUpdate = insertIntoGraphQlString(updateProjectVersion, {
            path: ["videoParts"],
            key: "modsArr",
            value: {
              id: true,
              dataStr: true, //TODO Add origin
            },
          });
          await API.graphql(
            graphqlOperation(modifiedUpdate, {
              input: {
                id: self.selectedProjectId,
                stateJsonSchemaStr: JSON.stringify(schema),
              },
            })
          );
          const projectWorkspaceVersion = JSON.parse(
            JSON.stringify(self.projectWorkspaceVersion)
          );

          projectWorkspaceVersion.stateJsonSchemaStr = JSON.stringify(schema);
          this.setWorkspaceProject(projectWorkspaceVersion);
          const { dynamicDataStore } = getRoot<RootInstance>(self);
          dynamicDataStore.setInitialPerVideoSchema(
            projectWorkspaceVersion.stateJsonSchemaStr
          );

          self.updateSaveProjectSchemaStatus(AsyncOpState.Success);
        } catch (e) {
          self.updateSaveProjectSchemaStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateSaveProjectSchemaStatus(AsyncOpState.Changed);
        }, 2000);
      },
      async saveLiveControlSchema(schema: any, data: any) {
        if (!self.projectWorkspaceVersion) {
          return;
        }
        /* const schema = createSchemaFromData(data); */
        self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Saving);
        try {
          const modifiedUpdate = insertIntoGraphQlString(updateProjectVersion, {
            path: ["videoParts"],
            key: "modsArr",
            value: {
              id: true,
              dataStr: true, // TODO Add origin
              origin: true,
            },
          });
          const updatedProjectVersion = ((await API.graphql(
            graphqlOperation(modifiedUpdate, {
              input: {
                id: self.selectedProjectId,
                settingsJsonSchemaStr: JSON.stringify(schema),
                settings: JSON.stringify(data),
              },
            })
          )) as { data: { updateProjectVersion: ProjectVersion } }).data
            .updateProjectVersion;
          this.setWorkspaceProject(updatedProjectVersion);

          const { dynamicDataStore } = getRoot<RootInstance>(self);
          dynamicDataStore.setInitialLiveControSchemaAndData(
            self.projectWorkspaceVersion.settingsJsonSchemaStr || "{}",
            self.projectWorkspaceVersion.settings || "{}"
          );
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Success);
        } catch (e) {
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateSaveProjectLiveControlSchemaStatus(AsyncOpState.Changed);
        }, 2000);
      },
      async updateVideoPartMods() {
        if (
          !self.selectedVideoPartName ||
          !self.projectWorkspaceVersion?.videoParts
        ) {
          return;
        }

        const vp = self.projectWorkspaceVersion.videoParts.find(
          (vp) => vp.name === self.selectedVideoPartName
        );
        if (vp) {
          self.updateSaveModsStatus(AsyncOpState.Saving);
          vp.modsArr = getRoot<RootInstance>(self).modsStore
            .JsonConfig as Mod[];
          try {
            const modifiedUpdate = insertIntoGraphQlString(
              updateProjectVersion,
              {
                path: ["videoParts"],
                key: "modsArr",
                value: {
                  id: true,
                  dataStr: true, // TODO Add origin
                  origin: true,
                },
              }
            );
            await API.graphql(
              graphqlOperation(modifiedUpdate, {
                input: {
                  id: self.selectedProjectId,
                  videoParts: self.projectWorkspaceVersion.videoParts,
                },
              })
            );

            self.updateSaveModsStatus(AsyncOpState.Success);
            toast.info("Changes are saved but not published!", {
              transition: Slide,
            })
          } catch (e) {
            self.updateSaveModsStatus(AsyncOpState.Error);
            console.error("err", e);
            toast.info("Changes were not saved!",{
              transition: Slide,
            })
          }

          setTimeout(() => {
            self.updateSaveModsStatus(AsyncOpState.Changed);
          }, 2000);
        }
      },
      getFullUrlOfAsset(assetOriginalName, folder) {
        return (
          self.platformStorageProjectAssetsRoot +
          `/${folder}/${Date.now() + "_" + assetOriginalName}`
        );
      },
      getOriginalNameOfAsset(fullName: string) {
        if (fullName.includes(self.platformStorageProjectAssetsRoot)) {
          const fileName = fullName.split("/").pop(); // remove entire folder structure
          if (fileName) {
            const assetName = fileName.split("_").slice(1).join("_");
            if (assetName) return assetName;
          }
        }
        return fullName;
      },

      async uploadAssetToProject(
        file: File | UploadFile<any>,
        s3Folder: string,
        cb?: (key: string) => void
      ) {
        self.updateUploadAssetStatus(AsyncOpState.Saving);

        try {
          const res = await this.uploadFileToS3(
            this.getFullUrlOfAsset(file.name, s3Folder),
            file,
            file.type
          );
          if (cb) {
            cb(`${CDN[getENV()]}/${res.key}`);
          }

          self.updateUploadAssetStatus(AsyncOpState.Success);
        } catch (e) {
          self.updateUploadAssetStatus(AsyncOpState.Error);
          console.error("err", e);
        }

        setTimeout(() => {
          self.updateUploadAssetStatus(AsyncOpState.Changed);
        }, 2000);
      },
      uploadFileToS3(
        Key: string,
        file: string | File | UploadFile<any>,
        type?: string
      ) {
        return Storage.put(Key, file, {
          level: "public",
          contentType: type || "application/json",
        }) as Promise<{ key: string }>;
      },
    };
  });

export type IPlatformModel = Instance<typeof PlatformModel>;
