import React from "react";
import { ModsPanelCompProps } from "../ModsPanel";
import { IOnPlayerEvent } from "@blings/blings-player";
import { Select, Space, Typography } from "antd";
import { PlayerEvents } from "@blings/blings-player/lib/src/player.api";
import { publicPlayerEvents } from "../../../stores/ModsStore";
import { JsEditor } from "../../Editor/JsEditor";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
const { Option } = Select;

export const defaultFunctionForOnPlayerEventMod = `(data, liveData, eventData) => {
    console.log("event happened", eventData) 
}`;

export function PlayerEventForm(props: ModsPanelCompProps<IOnPlayerEvent>) {
  const { change, onChange, index } = props;

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Space>
        <Typography.Text strong>Player Event: </Typography.Text>
        <Select<PlayerEvents>
          style={{ width: 200 }}
          onChange={(value) => {
            onChange(index, "playerEvent", value);
          }}
          onClick={(event) => event.preventDefault()}
          size={"middle"}
          value={change.playerEvent}
        >
          {publicPlayerEvents.map((pe) => (
            <Option key={pe} value={pe}>
              {pe}
            </Option>
          ))}
        </Select>
      </Space>
      <span className={"JsEditorAndToolTipContainer"}>
        <JsEditor
          defaultValue={
            props.change.functionString || defaultFunctionForOnPlayerEventMod
          }
          onChange={(e: any) =>
            props.onChange(props.index, "functionString", e)
          }
        />
        <InfoTooltip
          info={
            "This function will run whenever an " +
            change.playerEvent +
            " event occurs."
          }
        />
      </span>
    </Space>
  );
}
