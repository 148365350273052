import { Switch } from "antd";
import { IThemeColorChangeMod } from "@blings/blings-player";
import { getRelevantLayersColors } from "./ColorModsHelpers";
import { observer } from "mobx-react-lite";

interface IByLayersSwitch {
  change: IThemeColorChangeMod;
  index: number;
  json: any;
  onLayerChange: (...params) => void;
  onChange: (...params) => void;
}

export const ByLayersSwitch = observer(
  ({ change, json, index, onLayerChange, onChange }: IByLayersSwitch) => {
    return (
      <Switch
        checkedChildren="Layers"
        unCheckedChildren="Global"
        defaultChecked
        size="default"
        checked={"isLayers" in change && change.isLayers}
        onChange={(v) => {
          onChange(index, "isLayers", v);
          onLayerChange(0, undefined, undefined, []);

          onChange(
            index,
            "froms",
            change.froms.filter((s) => {
              return getRelevantLayersColors(json, change).some((color) => {
                return JSON.stringify(color) === JSON.stringify(s);
              });
            })
          );
        }}
      />
    );
  }
);
