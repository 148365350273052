import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { AsyncStateBtn } from "../../../BaseComps/AsyncStateBtn";
import { MSTContext } from "../../../stores/main";
import { Upload } from "antd";
import { CloudUploadOutlined, CheckOutlined } from "@ant-design/icons";

export const AssetUploader = observer(function ({
  cb,
  accept,
  s3Folder,
}: {
  accept: string;
  s3Folder: string;
  cb?: (url: string) => void;
}) {
  const {
    platformStore: {
      selectedProjectId,
      uploadAssetStatus,
      uploadAssetToProject,
    },
    playerStore,
  } = useContext(MSTContext);

  const disableProjectBtns = !playerStore.jsonFile || !selectedProjectId;

  return (
    <Upload
      accept={accept}
      multiple={false}
      beforeUpload={() => false}
      showUploadList={false}
      onChange={async ({ file, event }) => {
        // @ts-ignore
        await uploadAssetToProject(file, s3Folder, cb);
      }}
    >
      <AsyncStateBtn
        title={'Upload file to Blings CDN. Will be used in "value"'}
        type="dashed"
        disabled={disableProjectBtns}
        asyncState={uploadAssetStatus}
        btnText={<CloudUploadOutlined />}
        btnTextSuccess={<CheckOutlined />}
      />
    </Upload>
  );
});
