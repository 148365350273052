import React from "react";
import { Space } from "antd";
import { InteractionMods } from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { observer } from "mobx-react-lite";
import { LayerSelector } from "../../../LayerSelector";

interface Props<T> extends ModsPanelCompProps<T> {
  renderSpecificInteractive: (props: ModsPanelCompProps<T>) => React.ReactNode;
}
function _AddInteractiveHOC<T extends InteractionMods>(props: Props<T>) {
  const { change } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <LayerSelector
        isMulty={true}
        placeholder="Select layers that will become interactive"
        assetLayerPairs={props.assetLayerPairs}
        selectedAssetId={change.assetId}
        selectedLayerName={change.layerName}
        selectedAdditionals={change.additionals}
        onChange={props.onLayerChange}
        index={props.index}
      />
      {/*
      // for now this option is hidden
      <Select
        placeholder="Select an event"
        showArrow
        style={{ width: "100%" }}
        onChange={(v) => {
          props.onChange(props.index, "event", v);
        }}
        options={[
          "onclick",
          "ondblclick",
          "onmouseover",
          "ondragend",
          "ondragstart",
          "ontouchend",
          "ontouchstart",
        ].map((e) => ({ label: e, value: e }))}
        value={change.event}
      />*/}
      {props.renderSpecificInteractive(props)}
    </Space>
  );
}
export const AddInteractiveHOC = observer(_AddInteractiveHOC);
