import React from "react";
import { IInteractiveModOpen, modTypes } from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../../DynamicValueMod";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";

function renderActionForm(props: ModsPanelCompProps<IInteractiveModOpen>) {
  return (
    <>
      <Row>
        <Col>
          <Text type="secondary">Give this CTA a name (important for analytics)</Text>
          {/* <InfoTooltip info="" /> */}
          <Input
            id="connector-cta-name"
            value={props.change.ctaName}
            addonBefore={"Name"}
            // eslint-disable-next-line no-template-curly-in-string
            placeholder="You can use the text on the button for example"
            onChange={(e) => props.onChange(props.index, ["ctaName"], [e.target.value])}
            allowClear={true}
          />
        </Col>
      </Row>
      <DynamicValueMod
        change={props.change}
        onChange={(index, keys, values) => {
          return props.onChange(
            index,
            (keys as Array<keyof IInteractiveModOpen>).concat("type"),
            values.concat(modTypes.interactiveOpen)
          );
        }}
        index={props.index}
        type={DynamicValueModTypes.url}
      /></>
  );
}

export function AddInteractiveOpenForm(
  props: ModsPanelCompProps<IInteractiveModOpen>
) {
  return (
    <AddInteractiveHOC<IInteractiveModOpen>
      {...props}
      renderSpecificInteractive={renderActionForm}
    />
  );
}
