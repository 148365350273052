import { IThemeColorChangeMod } from "@blings/blings-player";
import { Select, Tag } from "antd";
import {
  darkTextColorIfBGisBright,
  getRelevantLayerColors,
  getRelevantLayersColors,
} from "./ColorModsHelpers";
import { GRADIENT_LENGTH, GRADIENT_PART_JUMP, HEX_START } from "./consts";
import { hexToRgba, rgbaArrToHexStr } from "../../../../helpers/colors";
import { observer } from "mobx-react-lite";
const { Option } = Select;

function tagRender(props) {
  const { label, closable, onClose } = props;
  const hexWithoutOpacity = label.slice(0, 7);
  return (
    <Tag
      color={hexWithoutOpacity}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: darkTextColorIfBGisBright(hexWithoutOpacity),
      }}
    >
      {label}
    </Tag>
  );
}

interface IHEXColorSelector {
  change: IThemeColorChangeMod;
  json: any;
  onChange: (...params) => void;
  index: number;
}
export const HEXColorSelector = observer(
  ({ change, json, onChange, index }: IHEXColorSelector) => {
    return (
      <Select<string[]>
        mode="multiple"
        showArrow
        allowClear
        placeholder={"Select colors for change"}
        notFoundContent={
          <div className={"notFoundContent"}>
            {"isLayers" in change && change.isLayers
              ? "Selected layers has no colors"
              : "This project has no defined colors"}
          </div>
        }
        tagRender={tagRender}
        value={change.froms.map((c) => JSON.stringify(c))}
        style={{ width: "100%" }}
        onChange={(v) => {
          onChange(
            index,
            "froms",
            v.map((s) => JSON.parse(s))
          );
        }}
      >
        {("isLayers" in change && change.isLayers
          ? getRelevantLayersColors(json, change)
          : getRelevantLayerColors(json)
        )
          .map((v) => {
            return JSON.stringify(v);
          })
          .filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
          })
          .map((v) => {
            const newV = JSON.parse(v);
            let val, vString, hex;
            if (newV.length && newV.length >= GRADIENT_LENGTH) {
              vString = JSON.stringify(newV);
              const RGBSTART = newV[newV.length - 1];
              hex = rgbaArrToHexStr(
                newV.slice(RGBSTART, RGBSTART + GRADIENT_PART_JUMP)
              );
              val = vString;
            } else if (newV.toString().startsWith(HEX_START)) {
              vString = JSON.stringify(hexToRgba(newV.toString()));
              hex = newV.toString();
              val = JSON.stringify(hex);
            } else {
              vString = JSON.stringify(newV);
              hex = rgbaArrToHexStr(newV);
              val = vString;
            }
            const hexWithoutOpacity = hex.slice(0, 7);
            return (
              <Option
                key={vString}
                value={val}
                style={{
                  backgroundColor: hexWithoutOpacity,
                  color: darkTextColorIfBGisBright(hexWithoutOpacity),
                }}
              >
                {hex}
              </Option>
            );
          })}
        )
      </Select>
    );
  }
);
