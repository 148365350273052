import * as React from "react";
import { Tooltip } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import "./InfoTooltip.scss";
import { theme } from "../theme";
import { AbstractTooltipProps } from "antd/lib/tooltip";
import { CSSProperties } from "react";

interface Props extends AbstractTooltipProps {
  info: string;
  style?: CSSProperties;
  tooltipStyle?: CSSProperties;
}

export function InfoTooltip({ info, style, tooltipStyle, ...rest }: Props) {
  return (
    <div className={"InfoTooltip"} style={{ cursor: "help", ...style }}>
      <Tooltip
        title={info}
        style={tooltipStyle}
        placement={"right"}
        arrowPointAtCenter={false}
        color={theme.blings_purple + "f7"}
        {...rest}
      >
        <QuestionCircleTwoTone twoToneColor={theme.blings_teal} />
      </Tooltip>
    </div>
  );
}
