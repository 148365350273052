import config from "../aws-exports";

export const getENV = () => {
  return config.aws_cloud_logic_custom[0].endpoint.split("/").slice(-1)[0];
};

export const CDN = {
  dev: "https://dh2jw0564x0cd.cloudfront.net",
  staging: "https://d25az89v0z0rdg.cloudfront.net",
  master: "https://d1t5lnx6iwscgl.cloudfront.net",
};
export const EXTENSION_URL = {
  dev: "https://ykd8o3yvea.execute-api.eu-west-1.amazonaws.com/dev",
  staging: "https://o1d26b9xt3.execute-api.eu-west-1.amazonaws.com/staging",
  master: "https://58hoje6tse.execute-api.eu-west-1.amazonaws.com/master",
};
export const GET_PLAYER_SETTINGS_URL = {
  dev: "https://lja2d0vqji.execute-api.eu-west-1.amazonaws.com/dev/projects",
  master:
    "https://8nm0xcfgh9.execute-api.eu-west-1.amazonaws.com/master/projects",
  staging:
    "https://csjxoutuob.execute-api.eu-west-1.amazonaws.com/staging/projects",
}[getENV()];
export const DEFAULT_MAX_LAYER_CHARS = 20;

export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";