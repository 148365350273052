/* tslint:disable:variable-name */
// const blings_pink = "#f03564";
const blings_pink = "#ea2f57"; // new
const blings_pink__light = "#e23564";
const blings_pink__dark = "#cc3059";
const blings_pink__higlight = "#ff386a";
const blings_pink__accent = "#ff91c5";

const blings_gray1 = "#2e3235"; // That gray
const blings_dark_gray1 = "#000c17";

const blings_gray_bg1 = "#f0f2f5";
const blings_gray_bg2 = "#cccccc";

const error_red = "#f13636";
const link_blue = "#363ef1";

const blings_chetwode_blue = "#7f95d1";
const blings_mustard_yellow = "#ffd046";

const blings_gray = "#cccccc";
const blings_purple = "#280c2a";
const blings_blue = "#a9b6c3";
const blings_success = "#98d7c2";
const blings_error = "#ff000f";
const blings_that_gray = "#2e3235";
const blings_teal = "#374146";
const blings_teal_80 = "#5F676B";
const blings_teal_60 = "#878D90";

const theme = {
  primary: blings_pink,
  _primary: {
    light: blings_pink__light,
    dark: blings_pink__dark,
    highlight: blings_pink__higlight,
    accent: blings_pink__accent,
  },

  secondary: blings_gray1,
  secondary2: blings_mustard_yellow,
  secondary3: blings_chetwode_blue,
  secondary4: blings_dark_gray1,
  _secondary: {},

  // surface:,
  background: blings_gray_bg1,
  background2: blings_gray_bg2,
  error: error_red,
  link: link_blue,

  blings_gray: blings_gray,
  blings_purple: blings_purple,
  blings_blue: blings_blue,
  blings_success: blings_success,
  blings_error: blings_error,
  blings_that_gray: blings_that_gray,
  blings_teal: blings_teal,
  blings_teal_80: blings_teal_80,
  blings_teal_60: blings_teal_60,

  connectors: {
    mediaAsset: '#f76300',
    text: '#038eff',
    color: '#f7f701',
    advanced: '#04FFBD',
    interactive: '#c056f1',
    vidAndAudio: '#9DFF03',
    input: "#fa78b0"
  }
};

//https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
const antdTheme = {
  "@primary-color": theme.primary, // primary color for all components
  "@info-color": theme.primary,
  "@processing-color": theme.primary,

  "@link-color": theme.link, // link color
  "@success-color": "#52c41a", // success state color
  "@warning-color": "#faad14", // warning state color
  "@error-color": theme.error, // error state color

  "@layout-header-background": theme.secondary,
  "@font-size-base": "14px", // major text font size
  "@heading-color": "rgba(0, 0, 0, 0.85)", // heading text color
  "@text-color": "rgba(0, 0, 0, 0.65)", // major text color
  "@text-color-secondary ": "rgba(0, 0, 0, .45)", // secondary text color
  "@disabled-color ": "rgba(0, 0, 0, .25)", // disable state color

  "@border-radius-base": "4px", // major border radius
  "@border-color-base": "#d9d9d9", // major border color
  "@box-shadow-base": "0 2px 8px rgba(0, 0, 0, 0.15)", // major shadow for layers
};

//   --blings_accent: ${theme.accent};
const blingsVars = `
  --blings_primary: ${theme.primary};
  --blings_primary_light: ${theme._primary.light};
  --blings_primary_dark: ${theme._primary.dark};
  --blings_primary_highlight: ${theme._primary.highlight};

  --blings_secondary: ${theme.secondary};
  --blings_background: ${theme.background};
  --blings_background2: ${theme.background2};
  --blings_secondary3: ${theme.secondary3};
  --blings_secondary4: ${theme.secondary4};
  --blings_error: ${theme.error};
  --blings_link: ${theme.link};


  --blings_pink: ${theme.primary};
  --blings_gray: ${theme.blings_gray};
  --blings_purple: ${theme.blings_purple};
  --blings_blue: ${theme.blings_blue};
  --blings_success: ${theme.blings_success};
  --blings_error: ${theme.blings_error};
  --blings_that_gray: ${theme.blings_that_gray};
  --blings_teal: ${theme.blings_teal};
  --blings_teal_80: ${theme.blings_teal_80};
  --blings_teal_60: ${theme.blings_teal_60};
`;
const amplifyVars = `
  --amplify-primary-color: ${theme.primary};
  --amplify-primary-tint: ${theme._primary.light};
  --amplify-primary-shade: ${theme._primary.dark};
  
  --amazonOrange: ${theme.primary};
  --lightAmazonOrange: ${theme._primary.light};
  --darkAmazonOrange: ${theme._primary.dark};
  --squidInk: #232F3E;
  --lightSquidInk: #31465F;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --red: #DD3F5B;
  --white: #FFFFFF;
  --light-blue: #00a1c9;
  --button-color: var(--white);
  --button-background-color: var(--amazonOrange);
  --button-click: var(--darkAmazonOrange);
  --link-color: var(--amazonOrange);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);
  --font-family: "Amazon Ember","Helvetica Neue Light","Helvetica Neue","Helvetica" ,"Arial","sans-serif";
  --body-background: #F8F4F4;
  --component-width-desktop: 460px;
  --component-width-mobile: 100%;
  --color-primary: ${theme.primary};
  --color-primary-accent: ${theme._primary.accent};
  --color-primary-highlight: ${theme._primary.highlight};
  --color-background: #232F3E;
  --color-secondary: #152939;
  --color-secondary-accent: #31465F;
  --color-danger: #DD3F5B;
  --color-error: ${theme.error};
  --color-accent-brown: #828282;
  --color-accent-blue: #E1E4EA;
  --gradient-blaze: linear-gradient(270deg, ${theme._primary.light} 0%, ${theme._primary.dark} 100%);
  --color-blue: #007EB9;
  --color-purple: #527FFF;
  --color-gray: #828282;
  --color-white: #FFFFFF;
  --input-border: 1px solid #C4C4C4;
  --input-padding: 0.5em 0.5em 0.3em 1em;
  --box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  --button-height: 42px;
  --interactions-conversation-height: 250px;
  --ion-color-primary: ${theme.primary};
  --ion-color-primary-rgb: 255,153,0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #232F3E;
  --ion-color-primary-tint: ${theme._primary.highlight};
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244,169,66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;
`;
const cssRootVars = `
:root{
${blingsVars}
${amplifyVars}
}

`;
module.exports = {
  theme,
  antdTheme,
  cssRootVars,
};
