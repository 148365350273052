import React, { useEffect } from "react";
import { Provider, rootStore } from "./stores/main";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react"; // not fully customized yet. Maybe in the future (31/1/21), or change to AmplifyAuthenicator
import { Layout } from "antd";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import LogRocket from "logrocket";

import awsconfig from "./aws-exports";
import "./App.less";
import "./App.scss";
import "./layout.scss";
import "./toastify.css"
import { TopBar } from "./components/playground-top-bar/TopBar";
import { useHistory } from "react-router-dom";
import { MainLayout } from "./components/MainLayout";
import { ToastContainer } from "react-toastify"

const awsAmplifyConfig = {
  ...awsconfig,
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: false,
}

Amplify.configure(awsAmplifyConfig);

const App: React.FC = () => {
  const history = useHistory();
  const { projectId, scene } = useParams<{
    projectId: string | undefined;
    scene: string | undefined;
  }>();

  // todo: @demian plz combine both useEffect at once, with proper deps
  useEffect(() => {
    (async () => {
      await rootStore.platformStore.loadProjects();
      if (rootStore.platformStore.allProjects.length && projectId) {
        await rootStore.platformStore.selectProject(projectId);
        if (scene) rootStore.platformStore.changeVideoPart(scene);
        else if (
          rootStore.platformStore.projectWorkspaceVersion &&
          rootStore.platformStore.projectWorkspaceVersion.videoParts?.length
        ) {
          history.push(
            `${projectId}/${rootStore.platformStore.projectWorkspaceVersion.videoParts[0].name}`
          );
        }
      }
    })();
    rootStore.notificationStore.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!rootStore.platformStore.allProjects.length) {
      return;
    }
    (async () => {
      if (projectId !== rootStore.platformStore.selectedProjectId) {
        await rootStore.platformStore.selectProject(projectId);
      }
      if (scene) rootStore.platformStore.changeVideoPart(scene);
      else if (
        rootStore.platformStore.projectWorkspaceVersion &&
        rootStore.platformStore.projectWorkspaceVersion.videoParts?.length
      ) {
        history.push(
          `${projectId}/${rootStore.platformStore.projectWorkspaceVersion.videoParts[0].name}`
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, scene]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.init("xodu2t/blings_playground");
      (async () => {
        const data = await Auth.currentUserInfo();
        LogRocket.identify("USER_IN_APP_ID", {
          name: data.username,
          email: data.attributes.email,
          id: data.id,
        });
      })();
    }
  }, []);

  return (
    <>
      <Provider value={rootStore}>
        <Layout>
          <TopBar />
          <MainLayout />
        </Layout>
        <ToastContainer
          position="bottom-left"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"

        />
      </Provider>

    </>
  );
};
const AppWithRouter = () => (
  <Router>
    <Route path={"/:projectId?/:scene?"} component={withAuthenticator(App)} />
  </Router>
);

export default AppWithRouter;
