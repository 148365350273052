import React from "react";
import { Button, Drawer, Tabs } from "antd";
import { DataSetter } from "./DataSetter";
import { LiveControlSetter } from "./LiveControlSetter";
import "./SchemaEditors.scss";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { InfoTooltip } from "../../BaseComps/InfoTooltip";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/main";

export const SchemaEditors = observer(() => {
  const {
    layoutStore: { schemaDrawerOpen, toggleSchemaDrawer },
  } = useMst();

  return (
    <div className={"SchemaEditors"}>
      <div className={"SchemaEditors-open-button"}>
        <Button
          type="primary"
          onClick={() => {
            toggleSchemaDrawer(true);
          }}
          icon={<DownOutlined />}
        >
          Project data schema
        </Button>
      </div>
      <Drawer
        closeIcon={<UpOutlined style={{ width: "100%" }} />}
        // title="Basic Drawer"
        placement="top"
        closable={true}
        onClose={() => toggleSchemaDrawer(false)}
        visible={schemaDrawerOpen}
        getContainer={false}
        style={{ position: "absolute" }}
        contentWrapperStyle={{ height: "100%" }}
      >
        <ErrorBoundary>
          <Tabs
            defaultActiveKey="pervideo"
            type="card"
            size={"middle"}
            className="tabs"
          >
            <Tabs.TabPane
              key="pervideo"
              tab={
                <span>
                  <span className={"tab-text"}>Per Video</span>{" "}
                  <InfoTooltip
                    info={
                      'Define the structure of the data that is unique to each and every video. e.g - "user_name" and data-base data'
                    }
                  />
                </span>
              }
            >
              <DataSetter />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="livecontrol"
              forceRender={true}
              tab={
                <span>
                  <span className={"tab-text"}>Control </span>{" "}
                  <InfoTooltip
                    info={
                      "Define content that can be updated from Blings platform, e.g - call-to-action url, marketing copy, etc"
                    }
                  />
                </span>
              }
            >
              <LiveControlSetter />
            </Tabs.TabPane>
          </Tabs>
        </ErrorBoundary>
      </Drawer>
    </div>
  );
});
