/**
 * Altered version
 * - original in blings-player
 */
export function findPathsToKey(
  obj: any,
  predicate: (v: any, pathToKey: string) => boolean
) {
  const results: [string, any][] = [];

  const options: {
    obj: any;
    predicate: (v: any, pathToKey: string) => boolean;
    pathToKey?: string;
  } = { obj, predicate };

  // tslint:disable-next-line:no-shadowed-variable
  (function findKey({ predicate, obj, pathToKey }) {
    const oldPath = `${pathToKey ? pathToKey + "." : ""}`;

    if (predicate(obj, oldPath)) {
      return;
    }

    for (const k in obj) {
      if (predicate(obj[k], `${oldPath}${k}`)) {
        results.push([`${oldPath}${k}`, obj[k]]);
      }
    }

    if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (Array.isArray(obj[k])) {
            for (let j = 0; j < obj[k].length; j++) {
              findKey({
                obj: obj[k][j],
                predicate,
                pathToKey: `${oldPath}${k}.${j}`,
              });
            }
          }

          if (obj[k] !== null && typeof obj[k] === "object") {
            findKey({
              obj: obj[k],
              predicate,
              pathToKey: `${oldPath}${k}`,
            });
          }
        }
      }
    }
  })(options);

  return results;
}
