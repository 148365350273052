import * as React from "react";
import { modsDefinitions } from "./mod-definitions";
import { Collapse, Space } from "antd";
import { useContext, useEffect, useRef } from "react";
import { MSTContext } from "../../stores/main";
import { PanelHeader } from "./ModsListPanelHeader";
import { observer } from "mobx-react-lite";
import "./ModsList.scss";
import "./modForms/ModForm.scss";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

const { Panel } = Collapse;

export const ModsList = observer(() => {
  const {
    modsStore,
    playerStore: { allColors, assetNames, assetLayerPairs, jsonFile },
  } = useContext(MSTContext);

  const { addCbAfterAddingMod } = modsStore;

  const reset = () => {
    modsStore.snapshotMods();
  };

  const collapseScrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addCbAfterAddingMod((id) => {
      setTimeout(() => {
        try {
          collapseScrollContainerRef?.current?.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } catch (e) {}
      }, 80);
    });
  }, [addCbAfterAddingMod]);

  return (
    <ErrorBoundary resetFunc={reset}>
      <Space
        direction={"vertical"}
        style={{ overflow: "hidden" }}
        className={"ModsList-container"}
      >
        <div
          id="connectors-list"
          className="modsList-collapse-container"
          ref={collapseScrollContainerRef}
        >
          <Collapse
            
            className="ModsList"
            accordion={true}
            activeKey={modsStore.activeModId.toString()}
            onChange={(key) => modsStore.setActiveMod(key as string)}
          >
            {modsStore.mods.map((mod, index) => {
              const { id, moddata } = mod;
              if (!modsDefinitions[moddata.type]) {
                return null;
              }
              const {
                ChangeFormComp,
                formTitle,
                formTitle2,
                // Icon,
                color,
                text,
              } = modsDefinitions[moddata.type];

              return (
                <Panel
                  id={"connector-" + id.toString()}
                  className={"ModForm"}
                  showArrow={false}
                  key={id.toString()}
                  style={{ borderLeftColor: color + "aa" }} // aa = opacity for design
                  header={
                    <PanelHeader
                      // Icon={Icon}
                      color={color}
                      handleRemove={() => {
                        modsStore.removeMod(mod);
                      }}
                      handleCopy={() => {
                        modsStore.copyMod(mod);
                      }}
                      formTitleText={formTitle(moddata)}
                      formTitleText2={
                        formTitle2 ? formTitle2(moddata) : undefined
                      }
                      modTitle={text.toUpperCase()}
                    />
                  }
                >
                  <ChangeFormComp
                    json={jsonFile}
                    index={id}
                    change={moddata}
                    config={modsStore.mods}
                    assetNames={assetNames}
                    allColors={allColors}
                    assetLayerPairs={assetLayerPairs}
                    onChange={(i, k, v) => {
                      modsStore.updateValue(id, k, v);
                    }}
                    onLayerChange={(i, assetId, layerName, additionals) => {
                      modsStore.updateValue(
                        id,
                        ["assetId", "layerName", "additionals"],
                        [assetId, layerName, additionals]
                      );
                    }}
                    handleChange={() => console.log("this.props.handleChange")}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Space>
    </ErrorBoundary>
  );
});
