import React from "react";
import { useRef } from "react";
import { useMst } from "../../stores/main";
import "./DataSetter.scss";
import { observer } from "mobx-react-lite";
import { DynamicForm } from "@blings/dynamic-form";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../stores/async-op-state";
import { cleanEmptyStringInValue } from "../../helpers/objects";

type Props = {};
// const placeholder = `{
//   data1: "abc"
// }`

export const LiveControlSetter = observer((props: Props) => {
  const {
    platformStore,
    dynamicDataStore: {
      setCurrentLiveControlData,
      setCurrentLiveControlSchema,
      /* setInitialLiveControlData, */
      liveControlInitialSchema,
      initialLiveControlData,
    },
  } = useMst();

  /*   useEffect(() => {
      setInitialLiveControlData(platformStore.selectedProjectLiveControl);
    }, [platformStore.selectedProjectId]); */
  const submit = useRef<() => void>(() => {
    return;
  });

  if (!platformStore.projectWorkspaceVersion)
    return <div className={"editor-wrapper"}></div>;

  return (
    <div className={"editor-wrapper"}>
      <DynamicForm
        key={`${liveControlInitialSchema}-LiveControl`}
        schema={JSON.parse(
          liveControlInitialSchema || "{}"
        )}
        formData={
          initialLiveControlData
        }
        saveData={(data, schema) => {
          const cleanData = cleanEmptyStringInValue(data);
          platformStore.saveLiveControlSchema(schema, cleanData);
        }}
        submit={submit}
        editable={true}
        uploadAssetToProject={platformStore.uploadAssetToProject}
        getAssetsUploadStatus={platformStore.getDynamicFormAssetsUploadStatus}
        onChange={(formData, schema) => {
          setCurrentLiveControlData(formData);
          setCurrentLiveControlSchema(schema);
        }}
        readable={false}
        fieldDescription={{
          key: "A name for the data field",
          value:
            "Data value. Can be updated for all videos from Blings platform",
        }}
      />
      <div className="footer">
        <BlingsBtn
          className={"save-schema-btn"}
          opState={platformStore.saveProjectLiveControlSchemaStatus}
          htmlType={"submit"}
          btnTexts={{ [AsyncOpState.Changed]: "Save 'control' schema" }}
          onClick={() => {
            submit.current();
          }}
          shape="round"
          ghost={true}
        />
      </div>
    </div>
  );
});;
