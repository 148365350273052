import { ModsPanelCompProps } from "./ModsPanel";
import React, { useState } from "react";
import {
  Space,
  Radio,
  Input,
  InputNumber,
  Switch,
  Row,
  Col,
  Tooltip,
  Select,
} from "antd";
import { IDynamicChangeMod } from "@blings/blings-player";
import { modTypes } from "@blings/blings-player";
import { observer } from "mobx-react-lite";
import { JSONTreeSelect } from "../../BaseComps/JSONTreeSelect";
import { useMst } from "../../stores/main";
import { AssetUploader } from "./modForms/AssetUploader";
import { theme } from "../../theme";
import { getDynamicValueTypeIcon } from "./dynamicValueModsIcon";
import { ColorPicker } from "../../BaseComps/ColorPicker";
import { JsEditor } from "../../components/Editor/JsEditor";
import { InfoTooltip } from "../../BaseComps/InfoTooltip";
const { Option } = Select;
export enum DynamicValueModTypes {
  input = "input",
  textarea = "textarea",
  number = "number",
  color = "color",
  boolean = "boolean",
  url = "url",
  hex = "hex",
}

interface Props<T extends modTypes> {
  index: ModsPanelCompProps<any>["index"];
  onChange: ModsPanelCompProps<IDynamicChangeMod<T, any>>["onChange"];
  type?: DynamicValueModTypes;
  change: ModsPanelCompProps<IDynamicChangeMod<T, any>>["change"];
  uploaderProps?: { accept: string; s3Folder: string };
  booleanText?: [string, string];
  // onChange: (index: number, key: keyof T, value: any) => void;
}
export type dynamicValueType =
  | "value"
  | "dataKey"
  | "expression"
  | "liveControlKey"
  | "inputName";

// defaultValue
function DynamicValueModComp<T extends modTypes>({
  index,
  onChange,
  change,
  type = DynamicValueModTypes.input,
  uploaderProps,
  booleanText,
}: Props<T>) {
  const [valueSetterType, setValueType] = useState(() => {
    if (typeof change["value"] !== "undefined") {
      return "value";
    } else if (typeof change["dataKey"] !== "undefined") {
      return "dataKey";
    } else if (typeof change["liveControlKey"] !== "undefined") {
      return "liveControlKey";
    } else if (typeof change["expression"] !== "undefined") {
      return "expression";
    } else if (typeof change["inputName"] !== "undefined") {
      return "inputName";
    } else {
      return "value";
    }
  });

  function changeVal(value) {
    const types: Array<dynamicValueType> = [
      "value",
      "dataKey",
      "expression",
      "liveControlKey",
      "inputName",
    ];
    onChange(
      index,
      types,
      types.map((t) => (t === valueSetterType ? value : undefined))
    );
  }

  function changeDefaultVal(defaultValue) {
    onChange(index, ["defaultValue"], [defaultValue]);
  }

  const {
    platformStore: { inputNames },
    dynamicDataStore: { perVideoCurrentData: perVideoData, liveControlCurrentData: liveControlData },
  } = useMst();
  const projectJsonPerVideoData = perVideoData;
  const projectJsonLiveControlData = liveControlData;

  const inputNameOption = inputNames.map((inputName) => (
    <Option key={inputName}>{inputName}</Option>
  ));
  const DynamicValueBtn = ({ info, title, value }) => (
    <Tooltip
      overlayClassName={"DynamicValueBtn-tooltip"}
      arrowContent={<span>aa</span>}
      // align={{points: ['tr','tr']}}
      arrowPointAtCenter={true}
      title={info}
      mouseEnterDelay={1}
      color={theme.blings_purple + "ee"}
    >
      <Radio.Button value={value}>
        {getDynamicValueTypeIcon(value)} {title}
      </Radio.Button>
    </Tooltip>
  );

  const showInput =
    type === DynamicValueModTypes.input ||
    type === DynamicValueModTypes.url ||
    type === DynamicValueModTypes.hex;

  const [inputPlaceholder, expressionPlaceholder] = (() => {
    switch (type) {
      case DynamicValueModTypes.number:
        return ["10", `return data.number`];
      case DynamicValueModTypes.hex:
        return [
          "#ffaa01",
          `return data.userLikesPink ? "${theme.primary}" : "#fff"`,
        ];
      case DynamicValueModTypes.url:
        return ["URL", "return 'https://cats.com/assets/' + data.cat"];
      case DynamicValueModTypes.boolean:
        return ["true or false", "return !data.value"];
      case DynamicValueModTypes.input:
      default:
        // eslint-disable-next-line no-template-curly-in-string
        return ["Hi ${name}", "return 'Hi ' + data.name"];
    }
  })();

  return (
    <Space direction="vertical" className={"DynamicValueModComp"}>
      <Radio.Group
        className={"dynamic-value-tab-selector"}
        value={valueSetterType}
        onChange={(e) => setValueType(e.target.value)}
        buttonStyle="solid"
      >
        {DynamicValueBtn({
          info:
            'Connect to data that can be dynamic for each video (e.g. "user_name")  ',
          value: "dataKey",
          title: "Per Video",
        })}

        {projectJsonLiveControlData &&
          DynamicValueBtn({
            info:
              "Connect to data that can be controlled from Blings platform in real time (e.g. marketing copy, discount price)  ",
            value: "liveControlKey",
            title: "Control",
          })}
        {DynamicValueBtn({
          info: "Replace the video content with any custom value",
          value: "value",
          title: "Value",
        })}
        {inputNames &&
          inputNames.length &&
          DynamicValueBtn({
            info: "Connect to input that can be dynamic from another scene",
            value: "inputName",
            title: "input",
          })}
        {DynamicValueBtn({
          info:
            "Write down Javascript code that `return` a value. You can use the `data` object",
          value: "expression",
          title: "",
        })}
      </Radio.Group>

      {valueSetterType === "value" && (
        <Row style={{ display: "flex", alignItems: "center" }}>
          {uploaderProps && (
            <Col span={3}>
              <AssetUploader
                accept={uploaderProps.accept}
                s3Folder={uploaderProps.s3Folder}
                cb={(fileUrl) => {
                  changeVal(fileUrl);
                }}
              />
            </Col>
          )}

          <Col span={uploaderProps ? 21 : 24} style={{ display: "flex" }}>
            {type === DynamicValueModTypes.hex && (
              <div id="color-picker-button" style={{ marginRight: "0.5rem" }}>
                <ColorPicker
                  color={change.value}
                  onChange={(e) => {
                    changeVal(e.hex);
                  }}
                  size={2}
                />
              </div>
            )}
            {showInput && (
              <Input
                id="connector-input"
                value={change.value}
                // eslint-disable-next-line no-template-curly-in-string
                placeholder={inputPlaceholder}
                onChange={(e) => changeVal(e.target.value)}
                allowClear={true}
              />
            )}
            {type === DynamicValueModTypes.number && (
              <InputNumber
                value={change.value}
                onChange={(value) => changeVal(value)}
              />
            )}
            {type === DynamicValueModTypes.textarea && (
              <Input.TextArea
                id="connector-input"
                placeholder={inputPlaceholder}
                value={change.value}
                onChange={(e) => {
                  changeVal(e.target.value || undefined);
                }}
              />
            )}
            {type === DynamicValueModTypes.boolean && (
              <>
                <Switch checked={change.value} onChange={changeVal} />{" "}
                {booleanText &&
                  (change.value ? booleanText[0] : booleanText[1])}
              </>
            )}
          </Col>
        </Row>
      )}

      {valueSetterType === "dataKey" && (
        <label>
          <JSONTreeSelect
            className={"mod-form-json-tree-select"}
            json={projectJsonPerVideoData}
            value={change.dataKey}
            onChange={changeVal}
            allowClear
          />
        </label>
      )}

      {valueSetterType === "inputName" && (
        <label>
          <Select
            showSearch
            className={"mod-form-json-tree-select"}
            style={{ width: "100%" }}
            value={change.inputName}
            placeholder="Please select"
            onChange={changeVal}
          >
            {inputNameOption}
          </Select>
        </label>
      )}

      {valueSetterType === "liveControlKey" && (
        <label>
          <JSONTreeSelect
            className={"mod-form-json-tree-select"}
            json={projectJsonLiveControlData}
            value={change.liveControlKey}
            onChange={changeVal}
            allowClear
          />
        </label>
      )}
      {valueSetterType === "expression" && (
        <span className={"JsEditorAndToolTipContainer"}>
          <JsEditor
            defaultValue={change.expression || expressionPlaceholder}
            onChange={(e: any) => changeVal(e)}
          />
          <InfoTooltip
            info={
              "This function will run every time the user interacts with the layer\\s. You can use `data` and `control` objects "
            }
          />
        </span>
      )}
      <Input
        id="connector-default-input"
        addonBefore={"Default Value"}
        value={change.defaultValue}
        onChange={(e) => changeDefaultVal(e.target.value)}
        allowClear
      />
    </Space>
  );
}

export const DynamicValueMod = observer(DynamicValueModComp);
