import { types } from "mobx-state-tree";

export const LayoutModel = types
  .model({
    schemaDrawerOpen: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleSchemaDrawer(open: boolean) {
      self.schemaDrawerOpen = open;
    },
  }));
