import { types, Instance } from "mobx-state-tree";
import React, { useContext, createContext } from "react";
import { ModsModel } from "./ModsStore";
import { PlayerModel } from "./PlayerStore";
import { PlatformModel } from "./PlatformStore";
import { DynamicDataModel } from "./DynamicDataStore";
import { LayoutModel } from "./layoutStore";
import { NotificationStore } from "./notification/notificationStore"
import { StoreStatus } from "./notification/StoreState";

export const RootModel = types
  .model({
    layoutStore: LayoutModel,
    platformStore: PlatformModel,
    modsStore: ModsModel,
    playerStore: PlayerModel,
    dynamicDataStore: DynamicDataModel,
    notificationStore: NotificationStore
  })
  .views((self) => ({}))
  .actions((self) => ({
    runPlayer() {
      self.playerStore.runPlayer();
    },
  }));

export const rootStore = RootModel.create({
  layoutStore: {},
  modsStore: {},
  playerStore: {},
  platformStore: {},
  dynamicDataStore: {},
  notificationStore: {
    notifications: [],
    unreadNotificationsNumber: 0,
    status: "NOT_INITIALIZED" as StoreStatus,
    isSubscribedToOnCreateNotification: false,
    isSubscribedToOnInstantNotification: false,
  }
});

export const MSTContext = React.createContext(rootStore);

export type RootInstance = Instance<typeof RootModel>;

const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useMst(): RootInstance {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
