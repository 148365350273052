/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gid
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        subType
        status
        title
        message
        read
        owner
        projectId
        createdAt
        extra
        updatedAt
        projectNotificationsId
      }
      nextToken
    }
  }
`;
export const notificationsByTypeAndCreatedAt = /* GraphQL */ `
  query NotificationsByTypeAndCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subType
        status
        title
        message
        read
        owner
        projectId
        createdAt
        extra
        updatedAt
        projectNotificationsId
      }
      nextToken
    }
  }
`;
export const notificationsByOwnerAndCreatedAt = /* GraphQL */ `
  query NotificationsByOwnerAndCreatedAt(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subType
        status
        title
        message
        read
        owner
        projectId
        createdAt
        extra
        updatedAt
        projectNotificationsId
      }
      nextToken
    }
  }
`;
export const notificationsByProjectIdAndCreatedAt = /* GraphQL */ `
  query NotificationsByProjectIdAndCreatedAt(
    $projectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByProjectIdAndCreatedAt(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subType
        status
        title
        message
        read
        owner
        projectId
        createdAt
        extra
        updatedAt
        projectNotificationsId
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        playerVersionToUse
        customHtml
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectsByProjectAccountIdAndCreatedAt = /* GraphQL */ `
  query ProjectsByProjectAccountIdAndCreatedAt(
    $projectAccountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByProjectAccountIdAndCreatedAt(
      projectAccountId: $projectAccountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        playerVersionToUse
        customHtml
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectChangeActivity = /* GraphQL */ `
  query GetProjectChangeActivity($id: ID!) {
    getProjectChangeActivity(id: $id) {
      projectId
      before {
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        type
        owner
      }
      after {
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        type
        owner
      }
      owner
      origin
      accountOwner
      createdAt
      id
      updatedAt
    }
  }
`;
export const listProjectChangeActivities = /* GraphQL */ `
  query ListProjectChangeActivities(
    $filter: ModelProjectChangeActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectChangeActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        projectId
        owner
        origin
        accountOwner
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectChangeActivitiesByProjectIdAndCreatedAt = /* GraphQL */ `
  query ProjectChangeActivitiesByProjectIdAndCreatedAt(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectChangeActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectChangeActivitiesByProjectIdAndCreatedAt(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        projectId
        owner
        origin
        accountOwner
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectVersion = /* GraphQL */ `
  query GetProjectVersion($id: ID!) {
    getProjectVersion(id: $id) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const listProjectVersions = /* GraphQL */ `
  query ListProjectVersions(
    $filter: ModelProjectVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountOwner
        owner
        stateJsonSchemaStr
        settingsJsonSchemaStr
        settings
        updatedAt
        playerVersionToUse
        createdAt
        projectProjectVersionsId
      }
      nextToken
    }
  }
`;
export const getServerRender = /* GraphQL */ `
  query GetServerRender($id: ID!) {
    getServerRender(id: $id) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      playerVersion
      updatedAt
    }
  }
`;
export const listServerRenders = /* GraphQL */ `
  query ListServerRenders(
    $filter: ModelServerRenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServerRenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        scenes
        data
        env
        outputFileName
        quality
        frameToStart
        frameToEnd
        format
        cognitoUserIdentifier
        cognitoGroupOwner
        createdAt
        playerVersion
        updatedAt
      }
      nextToken
    }
  }
`;
export const serverRendersByProjectIdAndCreatedAt = /* GraphQL */ `
  query ServerRendersByProjectIdAndCreatedAt(
    $projectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServerRenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serverRendersByProjectIdAndCreatedAt(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        scenes
        data
        env
        outputFileName
        quality
        frameToStart
        frameToEnd
        format
        cognitoUserIdentifier
        cognitoGroupOwner
        createdAt
        playerVersion
        updatedAt
      }
      nextToken
    }
  }
`;
