import { Project, ProjectVersion, UpdateProjectVersionInput } from "../API";
import { API, graphqlOperation } from "aws-amplify";
import {
  createProjectVersion,
  updateProject,
  updateProjectVersion,
} from "../graphql/mutations";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { getProject, getProjectVersion } from "../graphql/queries";
import Observable from "zen-observable";
import { ProjectBasicInfo } from "../stores/PlatformStore";
export async function createVersion(project: Project | ProjectVersion) {
  const input = {
    id: project.id,
    accountOwner: isProjectVersion(project)
      ? project.accountOwner
      : project.account.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts,
  };

  const modifiedCreateProjectVersion = insertIntoGraphQlString(
    insertIntoGraphQlString(createProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  const {
    data: { createProjectVersion: projectVersion },
  } = (await API.graphql(
    graphqlOperation(modifiedCreateProjectVersion, {
      input,
    })
  )) as { data: { createProjectVersion: ProjectVersion } };
  return projectVersion;
}
export async function updateVersion(project: UpdateProjectVersionInput) {
  const input = {
    id: project.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts,
  };
  return (await API.graphql(
    graphqlOperation(updateProjectVersion, {
      input,
    })
  )) as { data: ProjectVersion };
}
export async function publishVersion(projectVersion: ProjectVersion) {
  const {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
  } = projectVersion;
  const input = {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
  };
  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  return (await API.graphql(graphqlOperation(modifiedUpdateProject, { input }))) as {
    data: ProjectVersion;
  };
}
export async function getPublishedProject(id: string) {
  const modifiedGetProject = insertIntoGraphQlString(
    insertIntoGraphQlString(getProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );

  const {
    data: { getProject: project },
  } = (await API.graphql(
    graphqlOperation(modifiedGetProject, {
      id,
    })
  )) as { data: { getProject: Project } };
  return project;
}

export async function getPublishedProjectBasicInfo(id: string) {
  const getProject = `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      updatedAt
      createdAt
    }
  }
`;

  const {
    data: { getProject: project },
  } = (await API.graphql(
    graphqlOperation(getProject, {
      id,
    })
  )) as {
    data: { getProject: ProjectBasicInfo };
  };
  return project;
}

export async function getLatestProjectVersion(id: string) {
  // TODO add logic for fetching latest version when we have multiple versions per project id
  const modifiedGetProjectVersion = insertIntoGraphQlString(
    insertIntoGraphQlString(getProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  const {
    data: { getProjectVersion: projectVersion },
  } = (await API.graphql(
    graphqlOperation(modifiedGetProjectVersion, {
      id,
    })
  )) as { data: { getProjectVersion: ProjectVersion } };
  return projectVersion;
}

export async function getProjectChangesObservable(id: string) {
  const subscription = `
  subscription OnUpdateProjectSub($id: ID) {
    onUpdateProjectSub(id: $id) {
      id
      title
      updatedAt
      createdAt
    }
  }
`;
  const response = await API.graphql(
    graphqlOperation(subscription, {
      id,
    })
  );
  if (response instanceof Observable) {
    return response as Observable<object>;
  }
  return null;
}
export async function subscribeToProjectVersionChanges(id: string) {}

function isProjectVersion(
  project: Project | ProjectVersion
): project is ProjectVersion {
  return (project as ProjectVersion).accountOwner !== undefined;
}
