import { Button, Checkbox, Modal, Popover, Select, Typography } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useState } from "react";
import { Project } from "../../API";
import { getProject } from "../../graphql/queries";
import { AsyncOpState } from "../../stores/async-op-state";
import { EXAMPLE_PLAYER_LINK, getENV } from "../../stores/consts";
import { useMst } from "../../stores/main";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";

export const PublishButtons = observer(() => {
  const {
    modsStore: { hasUnsavedChanges: hasUnsavedModsChanges },
    platformStore: {
      publishCurrentVersion,
      selectedProjectId,
      selectedProjectBasicInfo,
    },
    dynamicDataStore: { hasUnsavedChanges: hasUnsavedDynamicDataChanges },
  } = useMst();
  const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const [modalUsavedText, setModalUsavedText] = useState("");
  const [modalSaveOpen, setModalSaveOpen] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const [dontShowPopoverCheckbox, setDontShowPopoverCheckbox] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [scenes, setScenes] = useState<string[]>([]);
  const publishButtonTexts = {
    [AsyncOpState.Changed]: "Publish",
    [AsyncOpState.Saving]: "Publishing",
    [AsyncOpState.Success]: "Published",
    [AsyncOpState.Error]: "Error"
  };
  useEffect(() => {
    if (selectedProjectId) {
      setScenes([]);
      getPublishedVideoPartsNamesAndData();
    }
  }, [selectedProjectId, selectedProjectBasicInfo]);

  useEffect(() => {
    // If the page has a cookie dont-show-publish-popover don't show the popover
    if (document.cookie.indexOf("dont-show-publish-popover") > -1) {
      setPopOverVisible(false);
      return;
    }
    else {
      setPopOverVisible(true);
    }
  }, []);

  function publish() {
    if (hasUnsavedDynamicDataChanges) {
      setModalUsavedText("You have unsaved data changes");
    } else if (hasUnsavedModsChanges) {
      setModalUsavedText("You have unsaved connectors changes");
    } else {
      setModalSaveOpen(true);
    }
  }
  async function getPublishedVideoPartsNamesAndData() {
    const {
      data: { getProject: project },
    } = (await API.graphql(
      graphqlOperation(getProject, {
        id: selectedProjectId,
      })
    )) as { data: { getProject: Project; }; };
    setProject(project);
  }
  async function save() {
    setPublishButtonState(AsyncOpState.Saving);
    try {
      await publishCurrentVersion();
    } catch (e) {
      console.log(e);
    }
    setPublishButtonState(AsyncOpState.Changed);
    setModalSaveOpen(false);
  }
  function openLiveVersion() {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${selectedProjectId}`;
    if (scenes) {
      url += `&scenes=${encodeURIComponent(scenes.join())}`;
    }
    const publishedExampleData = project?.stateJsonSchemaStr
      ? JSON.parse(project.stateJsonSchemaStr).examples[0]
      : null;
    if (publishedExampleData) {
      url += `&data=${encodeURIComponent(
        JSON.stringify(publishedExampleData)
      )}`;
    }
    window.open(url, "_blank");
  }
  return (
    <>
      <div className="PublishButtons">
        <Popover
          overlayClassName="view-live-version-popover"
          placement="bottomRight"
          title="Add scenes"
          content={
            <div>
              <Select
                id="view-live-select"
                mode="multiple"
                onChange={setScenes}
                value={scenes}
                className="view-live-version-select"
              >
                {project?.videoParts?.map((vp) => (
                  <Select.Option key={vp.name} value={vp.name}>
                    {vp.name}
                  </Select.Option>
                ))}
              </Select>
              <Button type="primary" onClick={openLiveVersion}>
                Go
              </Button>
            </div>
          }
          trigger="click"
        >
          <a>View published version</a>
        </Popover>

        <Popover
          content={
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "4px"}}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography.Title level={4} style={{margin: "0", padding: "0", marginBottom: "16px"}}>Notice</Typography.Title>
              </div>
              <p style={{ maxWidth: "300px", fontSize: "12px", lineHeight: "1.4", letterSpacing: "0" }}>Rendered and saved compositions are visible only to you.</p>
              <p style={{ maxWidth: "300px", marginBottom: "16px", fontSize: "12px", lineHeight: "1.4", letterSpacing: "0" }}>Publish the composition to make it public.</p>

              <Checkbox
                onChange={(e) => {
                  setDontShowPopoverCheckbox(e.target.checked);
                }}
                style={{ marginBottom: "16px", }}
              >
                <p style={{ maxWidth: "300px", fontSize: "12px", lineHeight: "1.4", letterSpacing: "0" }}>Don't show this message soon</p>
              </Checkbox>

              <Button
                key="ok"
                type="primary"
                style={{ marginBottom: "8px", width: "75px", alignSelf: "center" }}
                onClick={() => {
                  setPopOverVisible(false);

                  if (dontShowPopoverCheckbox) {
                    // Add cookie to not show this popover for some time.
                    const date = new Date();
                    date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // 24 hours
                    const expires = "expires=" + date.toUTCString();
                    document.cookie = "dont-show-publish-popover=true;" + expires + ";path=/";
                  }
                }}
              >
                OK
              </Button>
            </div>
          }

          trigger="click"
          visible={popOverVisible}
          placement="bottomRight"
        >
          <BlingsBtn
            className={"publish"}
            opState={publishButtonState}
            htmlType={"submit"}
            btnTexts={publishButtonTexts}
            onClick={() => { publish(); setPopOverVisible(false); }}
          />
        </Popover>

      </div>
      <Modal
        visible={modalUsavedText !== ""}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setModalUsavedText("");
            }}
          >
            OK
          </Button>,
        ]}
      >
        {modalUsavedText}
      </Modal>
      <Modal
        visible={modalSaveOpen}
        onCancel={() => {
          setModalSaveOpen(false);
        }}
        footer={[
          <Button
            key="cancel"
            type="link"
            onClick={() => {
              setModalSaveOpen(false);
            }}
          >
            Cancel
          </Button>,
          <BlingsBtn
            key="publish"
            className={"publish"}
            opState={publishButtonState}
            htmlType={"submit"}
            btnTexts={publishButtonTexts}
            onClick={save}
          />,
        ]}
      >
        Are you sure you want to publish the current version?
      </Modal>
    </>
  );
});
