import * as React from "react";
import { Layout, Space } from "antd";
import { VideoPartsSelection } from "./VideoPartsSelection";
import "./SideBar.scss";
import { HorizontalDivider } from "../../BaseComps/HorizontalDivider";
import { NewModButtonsList } from "../mods/NewModButtons/NewModButtonsList";
import { ModsList } from "../mods/ModsList";
import { SideFormActionButtons } from "../../SideFormActionButtons";

const { Sider } = Layout;
type Props = {};

export function SideBar(props: Props) {
  return (
    <Sider
      style={
        {
          // overflow: 'auto',
          // height: '100vh',
          // position: 'fixed',
          // left: 0,
        }
      }
      breakpoint="lg"
      collapsedWidth="0"
      width={415}
      className={"playground-left-sidebar"}
    >
      <Space
        className={"playground-left-sidebar-inner-container"}
        direction={"vertical"}
        split={<HorizontalDivider />}
      >
        <VideoPartsSelection />
        <NewModButtonsList />
        <ModsList />
        <SideFormActionButtons />
      </Space>
    </Sider>
  );
}
