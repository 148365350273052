import { Tag } from "antd";
import "./LayerTagRender.scss";

export function tagRender(props) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      className={"LayerTagRender"}
      // style={{ margin: "0.2em", borderRadius: "2em" }}
      title={label}
    >
      {label}
    </Tag>
  );
}
