export function getAssetLayerPairs(jsonFile) {
  const assetPairs = jsonFile.assets
    .map((a) => (a.layers ? [a.nm || a.id, a.layers.map((l) => l.nm)] : []))
    .filter((x) => x.length);
  const mainPairs = jsonFile.layers.map((l) => l.nm);
  const assetLayerPairs = {};
  for (let p of assetPairs) {
    assetLayerPairs[p[0]] = p[1];
  }

  return [assetLayerPairs, mainPairs];
}
