import { useState } from "react";
import Lottie from "react-lottie-player";
import loadingLoop from "../icons-and-animations/EazyRobo/Loading.json";
import startLoad from "../icons-and-animations/EazyRobo/StartAndLoading.json";

export const EazyLoader = ({size, isLoop}) => {

    const [animation, setAnimation]: [any, any] = useState(startLoad);
    const [loop, setLoop] = useState(false);
    return (
      <Lottie
        onComplete={() => {
          if (loadingLoop) setAnimation(loadingLoop);
          setLoop(isLoop);
        }}
        loop={loop}
        play={true}
        animationData={animation}
        style={{
          height: size-100,
          width: size,
        }}
      />
    );
  };