import { ModsPanelCompProps } from "../../ModsPanel";
import { IInteractiveModConcat } from "@blings/blings-player";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import React from "react";
import { useMst } from "../../../../stores/main";
import { InputNumber, Select, Space } from "antd";

const { Option } = Select;

const InteractiveConcatRender = (
  props: ModsPanelCompProps<IInteractiveModConcat>
) => {
  const { platformStore } = useMst();

  const defaultJumpsValue = {
    sceneStart: props.change.value && props.change.value[0],
    sceneEnd: props.change.value && props.change.value[0],
    frame: 1,
    currentFrame: undefined,
  };

  const jumpValueRender = () => {
    console.log(props.change.jumpType, 8768);

    switch (props.change.jumpType) {
      case "sceneStart":
      case "sceneEnd":
        return (
          <Select
            style={{ minWidth: "2rem" }}
            value={props.change.jumpValue as string}
            onChange={(v) => {
              props.onChange(props.index, ["jumpValue"], [v]);
            }}
          >
            {props.change.value?.map((vp) => (
              <Option value={vp} key={vp}>
                {vp}
              </Option>
            ))}
          </Select>
        );
      case "frame":
        return (
          <InputNumber
            value={props.change.jumpValue as number}
            onChange={(value) =>
              props.onChange(props.index, ["jumpValue"], [value])
            }
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Select Video Part"
        value={props.change.value}
        onChange={(v) => {
          props.onChange(props.index, ["value"], [v]);
          props.onChange(props.index, "jumpValue", v[0]);
        }}
      >
        {platformStore.projectWorkspaceVersion?.videoParts?.map((vp) => (
          <Option key={vp.name} value={vp.name}>
            {vp.name}
          </Option>
        ))}
      </Select>
      {props.change.value?.length ? (
        <Space style={{ width: "100%" }}>
          <label>
            Goto:
            <Select
              value={props.change.jumpType}
              onChange={(v) => {
                props.onChange(
                  props.index,
                  ["jumpType", "jumpValue"],
                  [v, defaultJumpsValue[v]]
                );
              }}
            >
              <Option value="sceneStart">Scene Start</Option>
              <Option value="sceneEnd">Scene End</Option>
              <Option value="frame">Specific Frame</Option>
              <Option value="currentFrame">Current Frame</Option>
            </Select>
          </label>
          {jumpValueRender()}
        </Space>
      ) : null}
    </Space>
  );
};

export function AddInteractiveConcatForm(
  props: ModsPanelCompProps<IInteractiveModConcat>
) {
  return (
    <AddInteractiveHOC<IInteractiveModConcat>
      {...props}
      renderSpecificInteractive={InteractiveConcatRender}
    />
  );
}
