import * as React from "react";
import burgerAnim from "../../icons-and-animations/burger.json";
import { Button, Dropdown, Menu } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useMst } from "../../stores/main";
import { observer } from "mobx-react-lite";
import { HoverableLottie } from "../../BaseComps/HoverableLottie";
import { useRef } from "react";
import "./BurgerMenu.scss";
import { EXTENSION_URL, getENV } from "../../stores/consts";
const demoDownloadUrl =
  "https://s3.amazonaws.com/assets.blings.io/demo/OnBoardingVideoCollect.zip";
const extensionDownloadUrl = `${
  EXTENSION_URL[getENV()]
}/extension/latest/download`;
type Props = {};
export const BurgerMenu = observer((props: Props) => {
  const {
    platformStore: { logout, selectedProjectId },
  } = useMst();
  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.download = "demo";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  };
  function BurgerOverlayMenu() {
    const PLATFORM = "https://platform.blings.io/";
    return (
      <Menu
        id="top-burger-menu-list"
        className={"BurgerOverlayMenu"}
        // style={{ width: 300 }}
      >
        {/*<Space direction={'vertical'} align={'center'}>*/}

        <Menu.Item key="platorm">
          <Button
            id="top-burger-menu-item-platform"
            type="link"
            href={
              selectedProjectId
                ? PLATFORM + "project/" + selectedProjectId
                : PLATFORM
            }
            target={"_blank"}
            // todo: link by env
            // todo: open project route in platform if a project is currently opened
          >
            Platform
          </Button>
        </Menu.Item>
        <Menu.Item key="download">
          <Button
            id="top-burger-menu-item-demo-files"
            className="download-btn"
            onClick={() => handleDownload(demoDownloadUrl)}
            type="link"
            icon={<DownloadOutlined />}
            size={"small"}
          >
            Demo files
          </Button>
        </Menu.Item>
        <Menu.Item key="download extension">
          <Button
            id="top-burger-menu-item-extension"
            className="download-btn"
            onClick={() => handleDownload(extensionDownloadUrl)}
            type="link"
            icon={<DownloadOutlined />}
            size={"small"}
          >
            Extension
          </Button>
        </Menu.Item>
        <Menu.Item key={"logout"}>
          <Button
            id="top-burger-menu-item-logout"
            type="primary"
            // icon={<PoweroffOutlined/>}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
  const ddRef = useRef<HTMLDivElement>(null);

  return (
    <div id="top-burger-menu">
      <Dropdown
        className={"burger-dropdown"}
        // trigger={['click']}
        overlay={BurgerOverlayMenu}
        placement="bottomLeft"
      >
        <span ref={ddRef}>
          <HoverableLottie
            hoverableElement={ddRef}
            animationData={burgerAnim}
            size={40}
          />
        </span>
      </Dropdown>
    </div>
  );
});
