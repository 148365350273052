import { editorOptions, editorOptionsSmall } from "./JsEditorConsts";
import Editor from "@monaco-editor/react";
import { FormOutlined } from "@ant-design/icons";
import "./JsEditor.scss";
import { useRef, useState } from "react";
import { Button, Modal } from "antd";
import { editor } from "monaco-editor";

export function JsEditor({ onChange, defaultValue }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editorValue, setEditorValue] = useState(defaultValue);
  const editorRef = useRef<undefined | editor.IStandaloneCodeEditor>(undefined);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleOnChange = (e) => {
    setEditorValue(e);
    onChange(e);
  };

  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }

  return (
    <div style={{ width: "100%" }} className="JsEditor">
      <FormOutlined className="OpenWindow" onClick={handleOpen} />
      <Editor
        language="javascript"
        defaultValue={defaultValue}
        height={"90px"}
        onChange={handleOnChange}
        options={editorOptionsSmall}
        theme="vs-dark"
        className="Editor"
        value={editorValue}
      />
      <Modal
        width={700}
        style={{ left: "-10rem" }}
        bodyStyle={{ height: "25rem", maxHeight: "30rem" }}
        className="JsEditorModal"
        visible={isModalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button
            key={"indent"}
            onClick={() => {
              if (editorRef.current) {
                editorRef.current
                  .getAction("editor.action.formatDocument")
                  .run();
              }
            }}
          >
            Auto Indent
          </Button>,
          <Button key="ok" onClick={handleClose}>
            ok
          </Button>,
        ]}
      >
        <Editor
          language="javascript"
          defaultValue={defaultValue}
          onChange={handleOnChange}
          options={editorOptions}
          theme="vs-dark"
          className="ModalEditor"
          value={editorValue}
          onMount={handleEditorDidMount}
        />
      </Modal>
    </div>
  );
}
