import {
  IInteractiveModInput,
  COMMON_PATHS,
  findLayerOrLayerAsset,
  getPath,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { Input, Space } from "antd";
import { LayerSelector } from "../../../LayerSelector";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";

export function AddInteractiveInputForm(
  props: ModsPanelCompProps<IInteractiveModInput>
) {
  const { change, json } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <span>
        Select a name for the input field:
        <InfoTooltip
          style={{ display: "inline-block" }}
          info={"In order to access to the input value, use: `inputs[name]`"}
        />
      </span>
      <Input
        value={change.value}
        addonBefore="Name"
        // The index of the mod, the property name, the value to update
        onChange={(e) => props.onChange(props.index, "value", e.target.value)}
        allowClear={true}
      />
      <span>
        Set the placeholder message:
        <InfoTooltip
          style={{ display: "inline-block" }}
          info={"This text will be shown in the input field and will disappear when the user starts typing"}
        />
      </span>
      <Input
        value={change.placeholder}
        addonBefore="Placeholder"
        onChange={(e) => props.onChange(props.index, "placeholder", e.target.value)}
        allowClear={true}
      />
      <span>
        Select a layer that will become an input field:
        <InfoTooltip
          style={{ display: "inline-block" }}
          info={"Only PARAGRAPH TEXT layers"}
        />
      </span>
      <LayerSelector
        isMulty={false}
        placeholder="Select layer that will become input field"
        assetLayerPairs={props.assetLayerPairs}
        selectedAssetId={change.assetId}
        selectedLayerName={change.layerName}
        onChange={props.onLayerChange}
        index={props.index}
        mapFilterLayerFunc={(p, layerName, assetId) => {
          const layer = findLayerOrLayerAsset({
            assetId: assetId === "main" ? undefined : assetId,
            layerName,
            jsonVid: json,
          });
          const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
          const isParagraph = getPath(layer, COMMON_PATHS.PARAGRAPH_IN_LAYER);
          if (text && isParagraph) {
            p.push(
              text === layerName
                ? layerName
                : {
                    renderText: `${text} [${layerName} - ${assetId}]`,
                    layerName,
                  }
            );
          }
          return p;
        }}
      />
    </Space>
  );
}
