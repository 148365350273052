import * as React from "react";
import Lottie from "react-lottie-player";
import { RefObject, useState } from "react";
import { AnimationDirection } from "lottie-web";

type Props = {
  animationData: any;
  size: number;
  hoverableElement?: RefObject<HTMLElement>;
};

export function HoverableLottie({
  hoverableElement,
  animationData,
  size,
}: Props) {
  const [speed, setSpeed] = useState(1);
  const [playback, setPlayback] = useState(false);
  const [goTo, setGoTo] = useState<number | undefined>(0);
  const [direction, setDirection] = useState<AnimationDirection>(1);
  const [complete, setComplete] = useState(false);

  const handleEnter = () => {
    setPlayback(false);
    setGoTo(0);

    setSpeed(1);
    setDirection(1);
    setPlayback(true);
    setComplete(false);
  };

  const handleLeave = () => {
    if (complete) {
      setGoTo(animationData.op);
    }
    setComplete(false);
    setDirection(-1);
    setSpeed(3);
  };

  const doNothing = () => {};
  // useLayoutEffect(()=>{
  // setPlayback(false)
  if (hoverableElement?.current) {
    hoverableElement.current.onmouseenter = handleEnter;
    hoverableElement.current.onmouseleave = handleLeave;
  }
  // },[])
  return (
    <div
      onMouseEnter={!hoverableElement ? handleEnter : doNothing}
      onMouseLeave={!hoverableElement ? handleLeave : doNothing}
    >
      <Lottie
        onComplete={() => {
          setComplete(true);
        }}
        goTo={goTo}
        play={playback}
        speed={speed}
        loop={false}
        animationData={animationData}
        direction={direction}
        style={{
          height: size,
          width: size,
        }}
      />
    </div>
  );
}
