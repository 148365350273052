import { getRoot, Instance, types } from "mobx-state-tree";
import { RootInstance } from "./main";
import { modTypes, Player, expand } from "@blings/blings-player";
import { autorun, toJS } from "mobx";
import { runPlayerDebounce } from "./runPlayer";
import { getAllColors } from "./getAllColorsFronJson";
import { getAssetLayerPairs } from "./getAssetLayerPairs";
import { getFonts } from "./getFonts";
export const mainAssetKey = "main";
export const sequenceLayerId = "sequence_";
export const seqImgId = "imgSeq_";

export const PlayerModel = types
  .model({
    videoBgColor: types.optional(types.string, ""),
    jsonFile: types.maybe(types.frozen()),
    jsonFileName: types.maybe(types.string),
    uploadedImages: types.array(
      types.model({
        name: types.string,
        url: types.string,
        blob: types.frozen(),
      })
    ),
    autoplay: types.optional(types.boolean, true),
    posterFrame: types.optional(types.number, 1),
    fontConfig: types.optional(
      types.model({
        google: types.maybe(
          types.model({
            families: types.array(types.string),
          })
        ),
        typekit: types.maybe(
          types.model({
            id: types.maybe(types.string),
            api: types.maybe(types.string),
          })
        ),
        custom: types.maybe(
          types.model({
            families: types.array(types.string),
            urls: types.array(types.string),
          })
        ),
      }),
      {}
    ),

    rendererType: types.optional(
      types.enumeration(["svg", "canvas", "html"]),
      "svg"
    ),
    assetLayerPairs: types.map(types.array(types.string)),
    assetNames: types.array(types.string),
    allColors: types.array(types.array(types.number)),
    googleFonts: types.array(types.string),
    mainAssetKey: types.optional(types.string, "main"),
    currentFrame: types.optional(types.number, 0),
    totalFrames: types.optional(types.number, 0),
  })
  .volatile((self) => ({
    player: undefined as Player | undefined,
    lastVPAndProject: "",
  }))

  .actions((self) => ({
    setLastScene(vp: string | undefined, pid: string | null) {
      self.lastVPAndProject = JSON.stringify([vp, pid]);
    },
  }))
  .actions((self) => ({
    setVideoBgColor(newColor) {
      self.videoBgColor = newColor;
    },
    updateFont(type, key, value) {
      if (!self.fontConfig[type]) {
        self.fontConfig[type] = {};
      }
      self.fontConfig[type][key] = value;
    },
    addFontConfigToMods() {
      const fontConfig = toJS(self.fontConfig);
      for (const key in fontConfig) {
        if (fontConfig.hasOwnProperty(key) && !fontConfig[key]) {
          delete fontConfig[key];
        }
      }
      const rootStore = getRoot<RootInstance>(self);
      rootStore.modsStore.addMod({
        type: modTypes.font,
        fontConfig: fontConfig,
      });
    },

    setUploadedImages(name, url, blob) {
      self.uploadedImages.push({ name, url, blob });
      this.runPlayer();
    },

    setJsonFile(jsonFile, jsonFileName) {
      self.jsonFile = jsonFile;
      self.jsonFileName = jsonFileName;

      const [assetLayerPairs, mainPairs] = getAssetLayerPairs(jsonFile);
      self.mainAssetKey = assetLayerPairs["main"]
        ? "main_" + Object.keys(assetLayerPairs).length
        : "main";
      assetLayerPairs[mainAssetKey] = mainPairs;
      Object.keys(assetLayerPairs).forEach((key) => {
        if (key.indexOf(sequenceLayerId) === 0) {
          delete assetLayerPairs[key];
        }
      });
      self.assetLayerPairs.replace(assetLayerPairs);
      const assetNames = jsonFile.assets
        .filter((a) => a.p)
        .filter((a) => a.id.indexOf(seqImgId) !== 0)
        .map((a) => a.p);
      self.assetNames.replace(assetNames);

      const allColors = getAllColors(jsonFile);
      // @ts-ignore
      self.allColors.replace(allColors);

      const googleFonts = getFonts(jsonFile);
      self.googleFonts.replace(googleFonts);
    },

    async downloadJsonFile(url: string) {
      const raw = await fetch(url);
      const jvid = await raw.json();
      const json = expand(jvid);

      this.setJsonFile(json, url.split("/").slice(-1)[0]);
    },

    setCurrentFrame(frame: number, totalFrames: number) {
      self.currentFrame = frame;
      self.totalFrames = totalFrames;
    },
    setPlayer(player?: Player) {
      self.player = player;
    },

    runPlayer() {
      const {
        jsonFile,
        autoplay,
        // posterFrame,
        // fontConfig,
        rendererType,
        uploadedImages,
      } = self;
      const rootStore = getRoot<RootInstance>(self);
      const videoData = rootStore.dynamicDataStore.perVideoCurrentData;

      if (!jsonFile) {
        return;
      }

      const JsonConfig = rootStore.modsStore.JsonConfig.map((mod) =>
        JSON.parse(mod.dataStr)
      );

      const videoPart = {
        json: self.jsonFile,
        modsArr: rootStore.modsStore.mods.map((mod) => mod.moddata),
        name: rootStore.platformStore.selectedVideoPart?.name,
      };

      /** videoParts should include all scenes, for dynamic-concat update */
      const videoParts = JSON.parse(
        JSON.stringify(
          rootStore.platformStore.projectWorkspaceVersion?.videoParts || [videoPart]
        )
      );

      const vpidx = videoParts.findIndex((vp) => vp.name === videoPart.name);
      videoParts[vpidx] = videoPart;
      const liveControlData = rootStore.dynamicDataStore.liveControlCurrentData;
      runPlayerDebounce({
        jsonFile,
        autoplay,
        posterFrame: 0,
        JsonConfig,
        rendererType,
        videoData,
        liveControlData,
        videoParts,
        scenes: [videoPart.name],
        assetChanges: uploadedImages
          ? uploadedImages.reduce((o, ui) => {
              o[ui.name] = ui.url;
              return o;
            }, {})
          : [],

        cb: (p) => {
          if (p) {
            p.EE.on("onFrame", (f) => {
              try {
                this.setCurrentFrame(f, p.getTotalFrames());
              } catch (e) {
                console.error('err', e)
              }
            });
          }
          this.setPlayer(p);
        },
      });
    },
    removePlayer() {
      self.jsonFile = "";
      self.jsonFileName = "";
      if ((window as any).p) {
        try {
          (window as any).p.destroy();
          (window as any).p = null;
        } catch (e) {
          console.error("err destroying player", e);
        }
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      autorun(() => {
        if (self.jsonFile || self.uploadedImages) {
          self.runPlayer();
        }
      });
    },
  }));

export type IPlayerModel = Instance<typeof PlayerModel>;

// export const playerStore = PlayerModel.create({});
