import { Space, Typography } from "antd";
import React from "react";
import { Def, modDefsOrGroups, IGroupsDict } from "../mod-definitions";
import { useMst } from "../../../stores/main";
import { observer } from "mobx-react-lite";
import { ButtonRender } from "./NewModButtonRender";
import { DropDownMenuRender } from "./DropDownMenuOfNewModButtons";
import "./ModButtons.scss";

export const isDisabledModButton = (
  playerStore,
  disabled: Def<any>["disabled"],
  selectedProject
) => {
  return (
    !playerStore.jsonFile ||
    (typeof disabled === "boolean" && disabled) ||
    (typeof disabled === "function" && disabled(selectedProject))
  );
};

export const NewModButtonsList = observer(() => {
  const {
    modsStore,
    playerStore,
    platformStore: { projectWorkspaceVersion },
  } = useMst();

  return (
    <>
      <Typography.Title level={5}>Add Connector</Typography.Title>

      <Space
        id="add-connectors-list"
        style={{ rowGap: "1rem", display: "flex", flexWrap: "wrap" }}
        className={"ChangesAddButtons"}
      >
        {modDefsOrGroups.map((changeDefOrGroupOfDefs, i) => {
          if ((changeDefOrGroupOfDefs as Def<any>).ChangeFormComp) {
            const {
              color,
              animation,
              disabled,
              text,
            } = changeDefOrGroupOfDefs as Def<any>;
            return (
              <ButtonRender
                key={i}
                color={color}
                animation={animation}
                text={text}
                disabledDef={disabled}
                changeDef={changeDefOrGroupOfDefs as Def<any>}
                playerStore={playerStore}
                modsStore={modsStore}
                selectedProject={projectWorkspaceVersion}
              />
            );
          } else if (
            (changeDefOrGroupOfDefs as IGroupsDict).type === "dropDownGroup"
          ) {
            return (
              <DropDownMenuRender
                key={i}
                changeDefGroup={changeDefOrGroupOfDefs as IGroupsDict}
                playerStore={playerStore}
                modsStore={modsStore}
                selectedProject={projectWorkspaceVersion}
              />
            );
          }
          return null; // this should not happen!
        })}
      </Space>
    </>
  );
});
