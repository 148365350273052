import { TextChangeForm } from "../modForms/TextChangeForm";
import { CustomChangeForm } from "../modForms/CustomChangeForm";
import { byLayerThemeColorChangeForm } from "../modForms/ColorModForms/ByLayerThemeColorChangeForm";
import { AssetChangeForm } from "../modForms/AssetChangeForm";
import { HideLayerForm } from "../modForms/HideLayerForm";
import { AddInteractiveOpenForm } from "../modForms/interactiveForms/AddInteractiveOpenForm";
import { BGVideoForm } from "../modForms/MediaModForms/bgVideoForm";
import { BGAudioForm } from "../modForms/MediaModForms/bgAudioForm";
import colorAnimationData from "../../../icons-and-animations/ConnectorBtns/Color_mode.json";
import mediaAssetAnimationData from "../../../icons-and-animations/ConnectorBtns/Image_mode.json";
import textAnimationData from "../../../icons-and-animations/ConnectorBtns/text_mode.json";

import {
  ITextChangeMod,
  // InteractionMods,
  IBGVideoMod,
  IBGAudioMod,
  ICustomChangeMod,
  IHideLayerChangeMod,
  IThemeColorChangeMod,
  IAssetChangeMod,
  ImageAlign,
  IMods,
  IInteractiveModGoto,
  IInteractiveModOpen,
  modTypes,
  interactiveGotoTypes,
  IDynamicChangeMod,
  IInteractiveModConcat,
  IAdvancedConfigMod,
  IInteractiveModJSExpression,
  IOnPlayerEvent,
  ITransitionTimingMod,
  IInteractiveModInput,
} from "@blings/blings-player";
// import {   modTypes} from "@blings/blings-player";
import React from "react";
import { observer } from "mobx-react-lite";
import { AddInteractiveGotoForm } from "../modForms/interactiveForms/AddInteractiveGoToForm";
import { AddInteractiveConcatForm } from "../modForms/interactiveForms/AddInteractiveConcatForm";
import { ConfigModForm } from "../modForms/ConfigModForm";
import { dropDownGroups, dropDownGroupsKeys } from "./mod-groups-definitions";
import {
  AddInteractionJsExpressionForm,
  defaultFunctionForInteractionExpression,
} from "../modForms/interactiveForms/AddInteractionJSExpressionForm";
import {
  defaultFunctionForOnPlayerEventMod,
  PlayerEventForm,
} from "../modForms/PlayerEventForm";
import { TransitionModForm } from "../modForms/TransitionModForm";
import { theme } from "../../../theme";
import { getDynamicValueTypeIcon } from "../dynamicValueModsIcon";
import { rootStore } from "../../../stores/main";
import { AddInteractiveInputForm } from "../modForms/interactiveForms/AddInteractiveInputForm";
import { getColorTitles } from "./colorTitles";
export interface Def<T extends IMods> {
  text: string;
  ChangeFormComp: React.ElementType;
  formTitle: (c) => string;
  formTitle2?: (c) => [string, ((a: any) => JSX.Element)?]; // text + icon
  change: T;
  color: string;
  Icon?: any;
  disabled?: boolean | ((selectedProject) => boolean);
  dropDownGroup?: dropDownGroupsKeys;
  hide?: boolean;
  animation?: any;
}

function getValueTitle(
  change: IDynamicChangeMod<any, any>,
  textFunc: (changeText: string) => JSX.Element | string = (t) => t
): [string | JSX.Element, (a: any) => JSX.Element] {
  // const connectType = (()=>{
  //   switch (change)
  // })();
  let typeIcon;
  if (change.value) typeIcon = "value";
  else if (change.dataKey) typeIcon = "dataKey";
  else if (change.expression) typeIcon = "expression";
  else if (change.liveControlKey) typeIcon = "liveControlKey";
  else if (change.inputName) typeIcon = "inputName";

  // if(typeof change.value !== 'undefined') { return 'EditIcon' }
  const changeText =
    change.value || change.dataKey || change.liveControlKey || change.inputName;

  return [
    changeText ? textFunc(changeText.toString()) : "",
    typeIcon && getDynamicValueTypeIcon(typeIcon),
  ];
}
function getLayersTitle(change) {
  if (change.additionals && change.additionals.length) {
    return `${change.layerName} (+${change.additionals.length})`;
    // return change.layerName + " + " + change.additionals.length + "Layers";
  }
  return change.layerName;
}

// function

export const modsDefinitions = {
  [modTypes.text]: {
    text: "Text",
    ChangeFormComp: observer(TextChangeForm),
    formTitle: (change) => getLayersTitle(change) || "TEXT CONNECTOR",
    formTitle2: getValueTitle,
    // formTitle: (change: ITextChangeMod) =>
    //   `${change.layerName} => ${getValueTitle(change)}`,
    color: theme.connectors.text,
    animation: textAnimationData,
    Icon: textAnimationData,
    change: {
      type: modTypes.text,
      assetId: undefined,
      layerName: "",
      maxChars: undefined,
      center: false,
      dontTrim: false,
      // value: "",
      // newText: "N/A",
    },
  } as Def<ITextChangeMod>,

  [modTypes.asset]: {
    text: "Media",
    color: theme.connectors.mediaAsset,
    // Icon: FileImageFilled,
    animation: mediaAssetAnimationData,
    Icon: mediaAssetAnimationData,
    ChangeFormComp: observer(AssetChangeForm),
    formTitle: (change: IAssetChangeMod) =>
      rootStore.platformStore.getOriginalNameOfAsset(change.imageName) ||
      "MEDIA CONNECTOR",
    formTitle2: getValueTitle,
    change: {
      type: modTypes.asset,
      imageName: "",
      align: ImageAlign.XMidYMid,
      slice: true,
    },
  } as Def<IAssetChangeMod>,

  [modTypes.themeColor]: {
    text: "Color",
    ChangeFormComp: observer(byLayerThemeColorChangeForm),
    animation: colorAnimationData,
    Icon: colorAnimationData,
    color: theme.connectors.color,
    formTitle: (change) => getLayersTitle(change) || "COLOR CONNECTOR",
    formTitle2: (c) => getValueTitle(c, (t) => getColorTitles(c, t)),
    // formTitle2: change => `${change.froms[0] + change.froms.length} -> ${getValueTitle(change)}`,
    // formTitle: (change: IThemeColorChangeMod) =>
    //     `From: ${change.froms.length} colors => ${getValueTitle(change)}`,
    change: {
      isLayers: false,
      // assetId: undefined,
      // layerName: '',
      type: modTypes.themeColor,
      froms: [],
      to: "",
    },
  } as Def<IThemeColorChangeMod>,

  [modTypes.video]: {
    text: "Video",
    // Icon: VideoCameraFilled,
    color: theme.connectors.vidAndAudio,
    ChangeFormComp: observer(BGVideoForm),
    formTitle: (change: IBGVideoMod) => `Video`,
    formTitle2: getValueTitle,
    change: {
      type: modTypes.video,
      startOnMarker: undefined,
      startAnimationFrame: undefined,
      stopAnimationFrame: undefined,
      offsetInSeconds: 0,
      shouldLoop: false,
      url: "",
      style: undefined,
      fullScreenStyle: undefined,
    },
    // dropDownGroup: "media",
    hide: true,
  } as Def<IBGVideoMod>,

  [modTypes.audio]: {
    text: "Audio",
    // Icon: CustomerServiceFilled,
    color: theme.connectors.vidAndAudio,
    ChangeFormComp: observer(BGAudioForm),
    formTitle: (change: IBGVideoMod) => `Audio`,
    formTitle2: getValueTitle,
    change: {
      type: modTypes.audio,
      startOnMarker: undefined,
      startAnimationFrame: undefined,
      stopAnimationFrame: undefined,
      offsetInSeconds: 0,
      shouldLoop: false,
      url: "",
      volume: 1,
      style: undefined,
      fullScreenStyle: undefined,
    },
    hide: true,
    // dropDownGroup: "media",
  } as Def<IBGAudioMod>,

  [modTypes.interactiveGoto]: {
    text: "Jump to frame",
    // Icon: InteractionOutlined,
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveGotoForm),
    formTitle: (change) => getLayersTitle(change) || "INTERACTIVE - GOTO",
    formTitle2: (change) => [
      `${getValueTitle(change)[0]} (${change.gotoType})`,
      getValueTitle(change)[1],
    ],

    change: {
      event: "onclick",
      type: modTypes.interactiveGoto,
      gotoType: interactiveGotoTypes.frame,
      value: 100,
      assetId: "",
      layerName: "",
    },
    dropDownGroup: "interaction",
  } as Def<IInteractiveModGoto>,

  [modTypes.interactiveOpen]: {
    text: "Open Link",
    // Icon: LinkOutlined,
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveOpenForm),
    formTitle: (change) => getLayersTitle(change) || "INTERACTIVE - OPEN",
    formTitle2: getValueTitle,

    change: {
      event: "onclick",
      type: modTypes.interactiveOpen,
      value: "",
      // type: modTypes.interactiveGoto,
      assetId: "",
      layerName: "",
      ctaName: "",
    },
    dropDownGroup: "interaction",
  } as Def<IInteractiveModOpen>,

  [modTypes.interactiveConcat]: {
    text: "Dynamic Scenes",
    // Icon: ApiFilled,
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractiveConcatForm),

    formTitle: (change) => getLayersTitle(change) || "DYNAMIC SCENES",
    formTitle2: getValueTitle,
    // formTitle: (change: IInteractiveModConcat) =>
    //   `Concat: ${change.layerName || "N/A"} => ${getValueTitle(change)}`,
    change: {
      event: "onclick",
      type: modTypes.interactiveConcat,
      value: [],
      // type: modTypes.interactiveGoto,
      assetId: "",
      layerName: "",
      jumpType: "currentFrame",
    },
    disabled: (selectedProject) => !selectedProject,
    dropDownGroup: "interaction",
  } as Def<IInteractiveModConcat>,

  [modTypes.interactiveJSExpression]: {
    text: "Expression",
    // Icon: CodeOutlined,
    color: dropDownGroups.interaction.color, //"#1f6714",
    ChangeFormComp: observer(AddInteractionJsExpressionForm),
    formTitle: (change: IInteractiveModJSExpression) =>
      getLayersTitle(change) || "EXPRESSION",
    formTitle2: () => ["JS code"],
    change: {
      event: "onclick",
      type: modTypes.interactiveJSExpression,
      assetId: "",
      layerName: "",
      functionString: defaultFunctionForInteractionExpression,
    },
    disabled: (selectedProject) => !selectedProject,
    dropDownGroup: "interaction",
  } as Def<IInteractiveModJSExpression>,

  [modTypes.hideLayer]: {
    text: "Layer Hide",
    ChangeFormComp: observer(HideLayerForm),
    // Icon: DeleteRowOutlined,
    color: "#949494",
    // formTitle: (change: IHideLayerChangeMod) =>
    // (change.layerName || "N/A"),
    // `${change.layerName} => ${getValueTitle(change)}`,
    formTitle: (change) => getLayersTitle(change) || "HIDE LAYER",
    formTitle2: getValueTitle,

    change: {
      type: modTypes.hideLayer,
      assetId: "",
      layerName: "",
      value: true,
    },
    dropDownGroup: "advanced",
  } as Def<IHideLayerChangeMod>,

  [modTypes.interactiveInput]: {
    text: "Input Field",
    color: theme.connectors.input,
    ChangeFormComp: observer(AddInteractiveInputForm),
    formTitle: (change) => getLayersTitle(change) || "Input",
    formTitle2: (change) => ["Input name: " + change.value],

    change: {
      event: "onclick",
      type: modTypes.interactiveInput,
      value: "",
      placeholder: "",
      assetId: "",
      layerName: "",
    },
    dropDownGroup: "advanced",
  } as Def<IInteractiveModInput>,

  [modTypes.custom]: {
    text: "Custom Change",
    ChangeFormComp: observer(CustomChangeForm),
    // Icon: StarFilled,
    color: theme.connectors.advanced,
    // formTitle: (change: ICustomChangeMod) => "on layer: " + change.layerName,
    formTitle: (change) => getLayersTitle(change) || "CUSTOM CHANGE",
    formTitle2: getValueTitle,
    change: {
      type: modTypes.custom,
      assetId: undefined,
      layerName: "",
      path: "",
      // newValue: "N/A",
      nvType: "string",
    } as ICustomChangeMod,
    dropDownGroup: "advanced",
  } as Def<ICustomChangeMod>,

  [modTypes.advancedConfig]: {
    color: theme.connectors.advanced,
    // Icon: FlagOutlined,
    text: "Advanced Config",
    ChangeFormComp: observer(ConfigModForm),
    formTitle: (change: IAdvancedConfigMod) =>
      `Auto RTL: ${change.autoRTL}, Arabic: ${change.fixArabic}`,
    change: {
      type: modTypes.advancedConfig,
      autoRTL: true,
      fixArabic: false,
    },
    dropDownGroup: "advanced",
  } as Def<IAdvancedConfigMod>,

  [modTypes.onPlayerEvent]: {
    color: theme.connectors.advanced,
    // Icon: FieldTimeOutlined,
    text: "Player-Event",
    ChangeFormComp: observer(PlayerEventForm),
    formTitle: (change: IOnPlayerEvent) => `Run JS code`,
    formTitle2: (change: IOnPlayerEvent) => [change.playerEvent],
    change: {
      type: modTypes.onPlayerEvent,
      playerEvent: "onAllReady",
      functionString: defaultFunctionForOnPlayerEventMod,
    },
    dropDownGroup: "advanced",
  } as Def<IOnPlayerEvent>,

  [modTypes.transitionTiming]: {
    color: "#fff",
    // Icon: ColumnWidthOutlined,
    text: "Scene Transition",
    ChangeFormComp: observer(TransitionModForm),
    formTitle: (change: ITransitionTimingMod) =>
      `In: ${change.ip || 0} frames Out: ${change.op || 0} frames`,
    change: {
      type: modTypes.transitionTiming,
      ip: 0,
      op: 0,
    },
    dropDownGroup: "advanced",
  } as Def<ITransitionTimingMod>,
};

export const modDefinitionsArr: Def<any>[] = Object.keys(modsDefinitions).map(
  (key) => modsDefinitions[key]
);
