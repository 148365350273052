/**
 * This file will be used to store the notifications that are received from the server.
 * This store can receive notifications from the server through the listNotifications query done when the store is initialized.
 * It can also receive notifications from the server through the onCreateNotification subscription.
 * This store will utilize a notification class to store a notification information
 * This store will be a manager for all the notifications that are received from the server.
 * This will be created using the mobx-state-tree library.
 */

import { API, Auth, graphqlOperation } from "aws-amplify";
import { Instance, types } from "mobx-state-tree";
import {
  onCreateNotification,
  onSendInstantNotification,
} from "../../graphql/subscriptions";
import { listNotifications } from "../../graphql/queries";
import { Notification } from "./notificationModel";
import { ListNotificationsQuery, OnCreateNotificationSubscription, OnSendInstantNotificationSubscription } from "../../API";
import { StoreStatus } from "./StoreState";
import { INotificationSubscription, NotificationSubscriptionModel } from "./notificationSubscriptionModel";
import { InstantNotification, NotificationSendType, NotificationType } from "./Notification";

/**
 * This is a notification store that will be used to store all the notifications that are received from the server
 * This store will hold all the notifications that are received from the server and will be used to display them in the notification page
 */
export const NotificationStore =
  // Define the store structure to be used in the mobx-state-tree store
  types.model("NotificationStore", {
    /**
     * The list of notifications that are stored in the store
     */
    notifications: types.array(Notification),

    /**
     * The list of subscriptions to notifications
     * Create this with an empty array to avoid errors when the store is initialized
     */
    subscriptionsToNotifications: types.array(NotificationSubscriptionModel),

    /**
     * The number of unread notifications
     */
    unreadNotificationsNumber: types.number,

    /**
     * The status of the store. This will be used to determine if the store is loading, if it has been loaded or an unhandled error has occurred
     */
    status: types.optional(types.enumeration("NotificationStoreStatus", Object.values(StoreStatus)), StoreStatus.notInitialized),

    /**
     * Whether the user has subscribed to the onCreateNotification subscription
     */
    isSubscribedToOnCreateNotification: types.boolean,

    /**
     * Whether the user has subscribed to the onSendInstantNotification subscription
     */
    isSubscribedToOnInstantNotification: types.boolean,


  }).views(self => ({
    /**
     * Get the number of unread notifications
     * @returns The number of unread notifications
     */
    getUnreadNotificationsNumber: () => {
      return self.unreadNotificationsNumber;
    },

    /**
     * Get all the unread notifications
     * @returns All the unread notifications
     */
    getUnreadNotifications: () => {
      return self.notifications.filter((n) => !n.read);
    },

    /**
     * Get all the read notifications
     * @returns All the read notifications
     */
    getReadNotifications: () => {
      return self.notifications.filter((n) => n.read);
    },

    /**
     * Get all the notifications
     * @returns All the notifications
     */
    getAllNotifications: () => {
      return self.notifications;
    },

    /**
     * Get how many notifications are in the store
     * @returns The number of notifications
     */
    getNotificationsNumber: () => {
      return self.notifications.length;
    },

    /**
     * Get the notification with the given id
     * @param id The id of the notification to be retrieved
     * @returns The notification with the given id
     * @throws An error if the notification with the given id does not exist
     */
    getNotificationById: (id: string) => {
      // Find the notification with the given id
      const notification = self.notifications.find((n) => n.id === id);

      // Check if the notification was found
      if (!notification)
        throw new Error("Notification with the given id does not exist");

      // Return the notification with the given id
      return notification;
    },

    /**
     * Returns the status of the store
     */
    getStoreStatus: () => {
      return self.status;
    },
  }))
    // Define the actions that can be performed on the store
    .actions(self => ({

      /////////////////////////////////////////////
      //#region Setters
      // These functions are used to set the values of the store

      /**
       * INTERNAL USE ONLY
       * This function will be used to set the status of the subscription to the onCreateNotification subscription
       * Used because changing the status on the action results in an error
       * @param status The status to be set
       */
      __setIsSubscribedToOnCreateNotificationStatus(status: boolean) {
        self.isSubscribedToOnCreateNotification = status;
      },

      /**
       * INTERNAL USE ONLY
       * This function will be used to set the status of the subscription to the onSendInstantNotification subscription
       * Used because changing the status on the action results in an error
       * @param status The status to be set
       */
      __setIsSubscribedToOnInstantNotification(status: boolean) {
        self.isSubscribedToOnInstantNotification = status;
      },

      __setStoreStatus(status: StoreStatus) {
        self.status = status;
      },

      /**
       * This function will be used to mark all notifications as read
       * @returns A boolean indicating if all notifications were marked as read
       */
      setAllNotificationsAsRead() {
        if (self.status !== StoreStatus.ready)
          throw new Error("Store is not ready");

        for (let i = 0; i < self.notifications.length; i++) {
          self.notifications[i].markAsRead();
        }
      },

      /**
       * This function will be used to mark a notification as read
       * @param notificationId The notificationID to be marked as read
       * @returns A boolean indicating if the notification was marked as read
       */
      setNotificationAsRead(notificationId: string) {
        if (self.status !== StoreStatus.ready)
          throw new Error("Store is not ready");

        // Find the notification in the store by its ID
        const notification = self.notifications.find(
          (n) => n.id === notificationId
        );

        // Check if the notification was found in the store
        if (!notification) return false;

        // Mark the notification as read
        notification.markAsRead();

        // Return a boolean indicating if the notification was marked as read
        return true;
      },

      /**
       * Set the status of the store
       * @param status The status to be set
       */
      setStoreStatus(status: StoreStatus) {
        self.status = status;
      },
      //#endregion

      /////////////////////////////////////////////
      //#region Basic Actions (Add, Remove, Update)
      // These actions are used to add, remove and update notifications in the store

      /**
       * This function will be used to add a notification to the store
       * @param notification The notification to be added to the store
       * @returns The notification that was added to the store
       * @throws An error if the notification could not be added to the store; If it already exists in the store; If it is not valid
       */
      addNotification(notification: Instance<typeof Notification>) {
        if (
          self.status !== StoreStatus.ready &&
          self.status !== StoreStatus.loading
        )
          throw new Error("Store is not ready");
        // Check if the notification already exists in the store
        if (self.notifications.find((n) => n.id === notification.id)) {
          throw new Error("Notification already exists in the store");
        }

        // Add the notification to the store
        self.notifications.unshift(notification);

        // Update the unread notifications count
        this.updateUnreadNotifications();

        // Return the notification that was added to the store
        return notification;
      },

      /**
       * Add a notification subscription to the store that will be called when a notification that matches the subscription is created
       * @param subscription The subscription to be added to the store
       * @throws An error if the subscription already exists in the store
       * @throws An error if an subscription with the same id already exists in the store
       */
      addNotificationSubscription(subscription: INotificationSubscription) {
        // Check if there's not already a subscription with the same id
        if (self.subscriptionsToNotifications.find(s => s.id === subscription.id)) {
          throw new Error("Subscription already exists in the store");
        }

        // Show all the subscriptions in the store
        //console.log("Subscriptions in the store: ", self.subscriptionsToNotifications);

        //console.log("Adding subscription to the store");

        // Create a new subscription from the given subscription interface and the model
        const newSubscription = NotificationSubscriptionModel.create({
          id: subscription.id,
          sendType: subscription.sendType,
          type: subscription.type as any,
          subType: subscription.subType as any,
          executeOnlyOnce: subscription.executeOnlyOnce,
        });
        newSubscription.setCallback(subscription.callback);


        // Add the subscription to the store
        self.subscriptionsToNotifications.push(newSubscription);
      },

      /**
       * This function will be used to remove a notification from the store and the database
       * @param notificationId The notificationID to be removed from the store and the database
       * @returns A boolean indicating if the notification was removed from the store and the database
       * @throws An error if the store is not ready
       */
      deleteNotification(notificationId: string) {
        if (self.status !== StoreStatus.ready) throw new Error("Store is not ready");

        // Find the notification in the store by its ID
        const notification = self.notifications.find(
          (n) => n.id === notificationId
        );

        // Check if the notification was found in the store
        if (!notification) return false;

        // Delete the notification from the database
        notification.deleteNotification();

        // Delete the notification from the store
        self.notifications.remove(notification);

        // Return a boolean indicating if the notification was removed from the store and the database
        return true;
      },

      /**
       * Remove a notification subscription from the store by the subscriptionID
       * @param subscriptionId The subscriptionID to be removed from the store
       * @returns A boolean indicating if the subscription was removed from the store
       */
      deleteNotificationSubscription(subscriptionId: string): boolean {
        // Find the subscription in the store by its ID
        const subscription = self.subscriptionsToNotifications.find(s => s.id === subscriptionId);

        // Check if the subscription was found in the store
        if (!subscription)
          return false;

        // Remove the subscription from the store
        self.subscriptionsToNotifications.remove(subscription);

        // Return a boolean indicating if the subscription was removed from the store
        return true;
      },

      /**
       * After every read notification or after every new notification is added to the store
       * this function will be called to update the number of unread notifications
       */
      updateUnreadNotifications(forceNumber = -1) {
        if (self.status !== StoreStatus.loading && self.status !== StoreStatus.ready) throw new Error("Store is not ready");

        // Get the number of unread notifications
        if (forceNumber === -1)
          self.unreadNotificationsNumber = self.notifications.filter(n => !n.read).length;
        else
          self.unreadNotificationsNumber = forceNumber;
      },

      //#endregion

      /////////////////////////////////////////////
      //#region Composite Actions. 
      // These actions are made up of multiple basic actions and/or extra logic.
      // They are used to perform more complex operations on the store 

      /**
       * This function will be used to subscribe to the onCreateNotification subscription
       * @returns A boolean indicating if the subscription was successful
       * @throws An error if the subscription was not successful
       */
      async subscribeToOnCreateNotification() {
        //console.log("Subscribing to onCreateNotification");
        //console.log("Store Status", self.status);
        if (
          self.status !== StoreStatus.ready &&
          self.status !== StoreStatus.loading
        )
          throw new Error("Store is not ready");

        // Check if the store is already subscribed to the onCreateNotification subscription
        //console.log("Is subscribed to onCreateNotification", self.isSubscribedToOnCreateNotification);
        if (self.isSubscribedToOnCreateNotification)
          throw new Error(
            "Notification Store is already subscribed to the onCreateNotification subscription"
          );

        // Get the user's id
        const userId = await Auth.currentAuthenticatedUser().then(user => user.attributes.sub);
        //console.log("User ID", userId);

        // Subscribe to the onCreateNotification subscription and listen for new notifications that contain the user's id as the owner
        const subscription = API.graphql(graphqlOperation(onCreateNotification, {
          owner: userId
          //@ts-ignore
        })).subscribe({
          next: (notificationData: { value: { data: OnCreateNotificationSubscription } }) => {
            //console.log("Notification Data", notificationData);
            // Get the notification from the subscription
            const receivedNotification = notificationData.value.data.onCreateNotification;
            //console.log("Received Normal Notification");
            // Check if the notification is valid
            if (!receivedNotification)
              throw new Error("Notification is not valid");

            // Create and add the notification to the store
            const notification = Notification.create({
              id: receivedNotification.id,
              title: receivedNotification.title,
              read: receivedNotification.read,
              owner: receivedNotification.owner,
              message: receivedNotification.message,
              type: receivedNotification.type as NotificationType,
              subType: receivedNotification.subType,
              updatedAt: receivedNotification.updatedAt,
              createdAt: receivedNotification.createdAt,
              status: receivedNotification.status,
              extra: receivedNotification.extra,
              projectId: receivedNotification.projectId,
            })

            // Process the onClickCallback for the notification based on the notification type
            notification.processNotificationOnClickCallback();

            this.addNotification(notification);

            // Send the notification to the notification subscriptions
            this.callNotificationSubscriptions(notification, "Normal");
          },
          error: (error: any) => {
            // Unsuscribe from the onCreateNotification subscription
            subscription.unsubscribe();
            // Set the isSubscribedToOnCreateNotification to false
            this.__setIsSubscribedToOnCreateNotificationStatus(false);
            // Restart the subscription to the onCreateNotification subscription
            this.subscribeToOnCreateNotification();
          }
        });
        this.__setIsSubscribedToOnCreateNotificationStatus(true);
      },

      /**
       * This function will be used to subscribe to the onSendInstantNotification subscription
       * @returns A boolean indicating if the subscription was successful
       * @throws An error if the subscription was not successful
       */
      async subscribeToInstantNotifications() {
        if (
          self.status !== StoreStatus.ready &&
          self.status !== StoreStatus.loading
        )
          throw new Error("Store is not ready");

        // Check if the store is already subscribed to the onCreateNotification subscription
        if (self.isSubscribedToOnInstantNotification)
          throw new Error(
            "Notification Store is already subscribed to the onSendInstantNotification subscription"
          );

        // Get the user's id
        const userId = await Auth.currentAuthenticatedUser().then(user => user.attributes.sub);

        // Subscribe to the onCreateNotification subscription and listen for new notifications that contain the user's id as the owner
        const subscription = API.graphql(graphqlOperation(onSendInstantNotification, {
          owner: userId
          //@ts-ignore
        })).subscribe({
          next: (notificationData: { value: { data: OnSendInstantNotificationSubscription } }) => {
            //console.log("Instant Notification Data", notificationData);
            // Get the notification from the subscription
            const receivedNotification = notificationData.value.data.onSendInstantNotification;

            if (!receivedNotification)
              throw new Error("Notification is not valid");

            //console.log("Received Instant Notification");

            // Create a new InstantNotification object
            const instantNotification: InstantNotification = {
              message: receivedNotification.message,
              title: receivedNotification.title,
              type: receivedNotification.type as NotificationType,
              subType: receivedNotification.subType as string,
              extra: receivedNotification.extra,
              projectId: receivedNotification.projectId as string,
              owner: receivedNotification.owner,
              status: receivedNotification.status,
            }

            // Send the notification to the notification subscriptions
            this.callNotificationSubscriptions(instantNotification, "Instant");
          },
          error: (error: any) => {
            //console.log("Error on instant notification", error);
            // Unsuscribe from the onCreateNotification subscription
            subscription.unsubscribe();
            // Set the isSubscribedToOnCreateNotification to false
            this.__setIsSubscribedToOnInstantNotification(false);
            // Restart the subscription to the onCreateNotification subscription
            this.subscribeToInstantNotifications();
          }
        });
        this.__setIsSubscribedToOnInstantNotification(true);
      },

      /**
       * Call all the notification subscriptions with the notification that was received from the subscription
       * and filter out the ones that are not subscribed with the types and formats received
       * @param notification The notification that was received from the subscription
       */
      callNotificationSubscriptions(notification: Instance<typeof Notification> | InstantNotification, sendType: NotificationSendType) {
        //console.log("Notification", notification);
        //console.log("There's ", self.subscriptionsToNotifications.length, " subscriptions to notifications");

        let notificationsToCall: any[] = [];
        // Loop through all the subscriptions to notifications and get the ones that are subscribed to the send type
        notificationsToCall = self.subscriptionsToNotifications.filter(subscription => {
          if (subscription.sendType === "All") return true;
          return subscription.sendType === sendType
        });

        //console.log("There's ", notificationsToCall.length, " subscriptions to notifications that are subscribed to the send type");

        // Loop through all the remaining subscriptions and check which notification types they are subscribed to
        notificationsToCall = notificationsToCall.filter(subscription => {
          if (subscription.type === "All") return true;
          // Since the MobX state tree stores the notification as a observable object, we need to convert the type to a string
          return subscription.type.toString() === notification.type;
        });

        //console.log("There's ", notificationsToCall.length, " subscriptions to notifications that are subscribed to the send type and the notification type");

        // If there is a subType, loop through all the remaining subscriptions and check which notification subTypes they are subscribed to
        if (notification.subType)
          notificationsToCall = notificationsToCall.filter(subscription => {
            if (subscription.subType === "All") return true;
            if(subscription.subType)  return subscription.subType.toString() === notification.subType;
          });

        //console.log("There's ", notificationsToCall.length, " subscriptions to notifications that are subscribed to the send type, the notification type and the notification subType");

        // If there remains any subscriptions, call them
        if (notificationsToCall.length > 0)
          notificationsToCall.forEach((subscription: INotificationSubscription) => {
            subscription.callback(notification);
            // If the subscription should only be called once, remove it from the subscriptions array
            if (subscription.executeOnlyOnce) {
              this.deleteNotificationSubscription(subscription.id);
            }
          });
      },

      /**
       * Set all the notifications in the store as read
       */
      readAllNotifications() {
        if (self.status !== StoreStatus.ready && self.status !== StoreStatus.loading) throw new Error("Store is not ready");
        self.notifications.forEach(notification => {
          notification.markAsRead();
        });

        this.updateUnreadNotifications(0);
      },

      /**
       * Query the server for the newest X notifications
       * @param numNotifications The number of notifications to be queried from the server
       * @returns The notifications that were queried from the server
       */
      async queryForNotifications(numNotifications: number) {
        if (
          self.status !== StoreStatus.ready &&
          self.status !== StoreStatus.loading
        )
          throw new Error("Store is not ready");

        // Get the newest X notifications from the server and parse them into the store
        const notificationsResponse = await API.graphql(graphqlOperation(listNotifications, {
          limit: numNotifications//, orderBy: { createdAt: ""} Ordering doesn't work
        })) as { data: ListNotificationsQuery };
        const notificationList = notificationsResponse.data.listNotifications

        // If there is notifications, order them by date
        if (notificationList && notificationList.items.length > 0) {
          // Order the notifications by date
          notificationList.items.sort((a, b) => {
            // @ts-ignore - Will only reach this point if both a and b exist
            if (a.createdAt < b.createdAt) return -1;
            // @ts-ignore - Will only reach this point if both a and b exist
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
        }

        //

        return notificationsResponse.data.listNotifications;
      },
      //#endregion

      /**
       * This function will be used to initialize the store.
       * When the store initializes it will get the newest 100 notifications from the server
       * and subscribe to the onCreateNotification subscription to receive new notifications from the server
       * @returns A boolean indicating if the store was initialized
       */
      async init() {
        if (self.status !== StoreStatus.notInitialized) {
          console.warn("Notification Store is already initialized");
          return;
        }
        //console.log("Initializing Notification Store");
        this.setStoreStatus(StoreStatus.loading);

        // Get the newest 100 notifications from the server and parse them into the store
        const notifications = await this.queryForNotifications(100);

        // For each notification received from the server, create a new notification and add it to the store
        if (notifications && notifications.items.length > 0) {
          for (let i = 0; i < notifications.items.length; i++) {
            const notification = notifications.items[i];
            if (notification) {
              const newNotification = Notification.create({
                id: notification.id,
                title: notification.title,
                read: notification.read,
                owner: notification.owner,
                message: notification.message,
                type: notification.type as NotificationType,
                subType: notification.subType,
                updatedAt: notification.updatedAt,
                createdAt: notification.createdAt,
                status: notification.status,
                extra: JSON.parse(notification.extra as string),
                projectId: notification.projectId,
              })
              newNotification.processNotificationOnClickCallback();
              this.addNotification(newNotification);
            }
          }
        }

        // Subscribe to the onCreateNotification subscription
        await this.subscribeToOnCreateNotification();
        await this.subscribeToInstantNotifications();

        this.setStoreStatus(StoreStatus.ready);
      }
    }));
