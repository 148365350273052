import React from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as pervideo } from "../../icons-and-animations/dynamic-value-connection-icons/pervideo.svg";
import { ReactComponent as control } from "../../icons-and-animations/dynamic-value-connection-icons/control.svg";
import { ReactComponent as expression } from "../../icons-and-animations/dynamic-value-connection-icons/expression.svg";
import { ReactComponent as value1 } from "../../icons-and-animations/dynamic-value-connection-icons/value1.svg";
import { ReactComponent as input } from "../../icons-and-animations/dynamic-value-connection-icons/input.svg";
import { dynamicValueType } from "./DynamicValueMod";

export function getDynamicValueTypeIcon(vt: dynamicValueType) {
  switch (vt) {
    case "dataKey":
      return <Icon component={pervideo} />;
    case "liveControlKey":
      return <Icon component={control} />;
    case "value":
      return <Icon component={expression} />;
    case "expression":
      return <Icon component={value1} />;
    case "inputName":
      return <Icon component={input} />;
  }
}
