import React from "react";
import { InputNumber, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../DynamicValueMod";
import {
  ITextChangeMod,
  findLayerOrLayerAsset,
  getPath,
  COMMON_PATHS,
} from "@blings/blings-player";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
import { LayerSelector } from "../../LayerSelector";

export function TextChangeForm(props: ModsPanelCompProps<ITextChangeMod>) {
  const { change, json } = props;
  return (
    <Space direction={"vertical"} style={{}}>
      <span>
        <LayerSelector
          isMulty={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedAdditionals={change.additionals}
          onChange={props.onLayerChange}
          index={props.index}
          mapFilterLayerFunc={(p, layerName, assetId, additionals) => {
            const layer = findLayerOrLayerAsset({
              assetId: assetId === "main" ? undefined : assetId,
              layerName,
              jsonVid: json,
              additionals,
            });
            const text = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER);
            if (!!text) {
              p.push(
                text === layerName
                  ? layerName
                  : {
                      renderText: `${text} [${layerName} - ${assetId}]`,
                      layerName,
                    }
              );
            }
            return p;
          }}
        />
      </span>
      <Space>
        <span>
          Font-size Breakpoint{"  "}
          <InfoTooltip
            info={
              "Dynamic text with more characters than this number, will start shrinking down to smartly fit the design"
            }
          />{" "}
          {"  "}
          <InputNumber
            className={"breakpoint-input"}
            min={1}
            max={500}
            value={change.maxChars}
            onChange={(value) =>
              props.onChange(props.index, "maxChars", value ? value : undefined)
            }
          />
        </span>
        {/*<label>*/}
        {/*  Center:{" "}*/}
        {/*  <Switch*/}
        {/*    onChange={(checked) => {*/}
        {/*      props.onChange(props.index, "center", checked);*/}
        {/*    }}*/}
        {/*    checked={change.center}*/}
        {/*  />*/}
        {/*</label>*/}
      </Space>

      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.textarea}
      />
      {/*<label>*/}
      {/*  <Input*/}
      {/*    value={change.newText}*/}
      {/*    addonBefore="New Text"*/}
      {/*    onChange={(e) => this.props.onChange(this.props.index, "newText", e.target.value)}*/}
      {/*    allowClear={true}*/}
      {/*  />*/}
      {/*</label>*/}
    </Space>
  );
}
