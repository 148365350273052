import React from "react";
import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { DynamicForm } from "@blings/dynamic-form";
import "./DataSetter.scss";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { useMst } from "../../stores/main";
import { AsyncOpState } from "../../stores/async-op-state";

type Props = {};
// const placeholder = `{
//   data1: "abc"
// }`

export const DataSetter = observer((props: Props) => {
  const {
    platformStore,
    dynamicDataStore: {
      setCurrentPerVideoData,
      setCurrentPerVideoSchema,
      /* setInitialPerVideoData, */
      perVideoInitialSchema,
      initialPerVideoData,
    },
  } = useMst();

  /*   useEffect(() => {
      setInitialPerVideoData(
        jsonSchemaGetExamples(platformStore.selectedProjectDataSchema)
      );
    }, [platformStore.selectedProjectId]); */
  const submit = useRef<() => void>(() => {
    return;
  });

  if (!platformStore.projectWorkspaceVersion)
    return <div className={"editor-wrapper"}></div>;
  return (
    <div className={"editor-wrapper"}>
      <DynamicForm
        key={`${perVideoInitialSchema}-Data`}
        schema={JSON.parse(
          perVideoInitialSchema || "{}"
        )}
        formData={
          initialPerVideoData
        }
        saveData={(data, schema) => {
          schema.examples = [data];
          platformStore.savePerVideoSchema(schema);
        }}
        submit={submit}
        editable={true}
        uploadAssetToProject={platformStore.uploadAssetToProject}
        getAssetsUploadStatus={platformStore.getDynamicFormAssetsUploadStatus}
        onChange={(formData, schema) => {
          setCurrentPerVideoData(formData);
          schema.examples = [formData];
          setCurrentPerVideoSchema(schema);
        }}
        readable={false}
        fieldDescription={{
          key:
            "A name for this data field. Each video might get different value at run-time",
          value:
            'Values defined in "per-video" serve just as an example. The real value can be different for every video',
        }}
      />
      <div className="footer">
        <BlingsBtn
          className={"save-schema-btn"}
          opState={platformStore.saveProjectSchemaStatus}
          htmlType={"submit"}
          btnTexts={{ [AsyncOpState.Changed]: "Save 'per video' schema" }}
          onClick={() => {
            submit.current();
          }}
          shape="round"
          ghost={true}
        />
      </div>
    </div>
  );
});
