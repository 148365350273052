import * as React from "react";
import { useContext } from "react";
import "./TopBar.scss";
import { Layout, Space } from "antd";
import logo from "../../icons-and-animations/playground-logo.svg";
import { ConnectToProject } from "./ConnectToProject";
import { BurgerMenu } from "./BurgerMenu";
import { Link } from "react-router-dom";
import { PublishStatus } from "./PublishStatus";
import { PublishButtons } from "./PublishButtons";
import { MSTContext } from "../../stores/main";
import { observer } from "mobx-react-lite";
const { Header } = Layout;

type Props = {};

export const TopBar = observer((props: Props) => {
  const {
    platformStore: {
      selectedProjectId,
      allProjects,
    },
  } = useContext(MSTContext);
  const selectedProjectExists = allProjects.find(
    (project) => project.id === selectedProjectId
  );
  return (
    <Header className="playground-top-bar">
      <Space className="playground-top-bar-space-container">
        <div className="left">
          <Link to={"/"}>
            <img src={logo} alt="blings-playground-logo" />
          </Link>
          <ConnectToProject />
        </div>
        <div className="right">
          {selectedProjectExists ? <PublishStatus /> : null}
          {selectedProjectExists ? <PublishButtons /> : null}
          <BurgerMenu />
        </div>
      </Space>
    </Header>
  );
});