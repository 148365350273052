import React from "react";
import { Select, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { IAssetChangeMod } from "@blings/blings-player";
import { DynamicValueMod, DynamicValueModTypes } from "../DynamicValueMod";
import { useMst } from "../../../stores/main";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";

const { Option } = Select;

function PAR(props) {
  return (
    <Space>
      <div>
        <Select
          dropdownClassName={"mod-form-select-dropdown"}
          onChange={(value) => {
            props.onChange(props.index, "slice", value !== "meet");
          }}
          size={"middle"}
          value={props.slice ? "slice" : "meet"}
        >
          <Option key="slice" value="slice">
            Slice
          </Option>
          <Option key="meet" value="meet">
            Fit
          </Option>
        </Select>
        <InfoTooltip
          info={
            "Dynamic images with ratio different than the current image, will either fit into the container (leaving empty places), or sliced down "
          }
        />
      </div>
      <Space>
        Align:
        <Select
          style={{ width: 120 }}
          onChange={(value) => props.onChange(props.index, "align", value)}
          onClick={(event) => event.preventDefault()}
          // value={change.align}
          size={"middle"}
          value={props.align}
        >
          <Option key="xMinYMin" value="xMinYMin" title="xMinYMin">
            top left
          </Option>
          <Option key="xMinYMid" value="xMinYMid" title="xMinYMid">
            center left
          </Option>
          <Option key="xMinYMax" value="xMinYMax" title="xMinYMax">
            bottom left
          </Option>
          <Option key="xMidYMin" value="xMidYMin" title="xMidYMin">
            top center
          </Option>
          <Option key="xMidYMid" value="xMidYMid" title="xMidYMid">
            centered
          </Option>
          <Option key="xMidYMax" value="xMidYMax" title="xMidYMax">
            bottom center
          </Option>
          <Option key="xMaxYMin" value="xMaxYMin" title="xMaxYMin">
            top right
          </Option>
          <Option key="xMaxYMid" value="xMaxYMid" title="xMaxYMid">
            center right{" "}
          </Option>
          <Option key="xMaxYMax" value="xMaxYMax" title="xMaxYMax">
            bottom right
          </Option>
        </Select>
      </Space>
    </Space>
  );
}

export function AssetChangeForm(props: ModsPanelCompProps<IAssetChangeMod>) {
  const {
    platformStore: { getOriginalNameOfAsset },
  } = useMst();

  const { change } = props;
  const isVidOrAudio =
    change.imageName.endsWith(".mp4") || change.imageName.endsWith(".mp3");

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        Media Asset <span />
        <Select
          style={{ width: 200 }}
          placeholder={"Select asset"}
          onChange={(value) => {
            props.onChange(props.index, "imageName", value);
          }}
          onClick={(event) => event.preventDefault()}
          value={change.imageName || undefined}
          size={"middle"}
        >
          {props.assetNames.map((k, i) => (
            <Option title={k} key={k + i} value={k}>
              {getOriginalNameOfAsset(k)}
            </Option>
          ))}
        </Select>
      </div>
      <DynamicValueMod
        change={change}
        onChange={props.onChange}
        index={props.index}
        type={DynamicValueModTypes.url}
      />
      <br />
      {!isVidOrAudio && (
        <PAR
          onChange={props.onChange}
          index={props.index}
          change={change}
          slice={props.change.slice}
          align={props.change.align}
        />
      )}
    </div>
  );
}
