import { observer } from "mobx-react-lite";
import { dropDownGroups, IGroupsDict } from "../mod-definitions";
import { Dropdown, Menu } from "antd";
import React from "react";
import { ButtonRender } from "./NewModButtonRender";

export const DropDownMenuRender = observer(
  ({
    changeDefGroup,
    playerStore,
    selectedProject,
    modsStore,
  }: {
    changeDefGroup: IGroupsDict;
    playerStore;
    selectedProject;
    modsStore;
  }) => {
    const { color, dropDownText, animation } = dropDownGroups[
      changeDefGroup.name
    ];
    // const isDisabledGroup = !changeDefGroup.defs.find(
    //   (def) =>
    //     !isDisabledModButton(playerStore, false, selectedProject) &&
    //     def.hide !== true
    // );

    const menu = (
      <Menu theme={"dark"}>
        {changeDefGroup.defs.map((def, key, a) => {
          return (
            <React.Fragment key={key}>
              <Menu.Item
                key={key}
                onClick={() => {
                  modsStore.addMod(def.change);
                }}
              >
                <div
                  className="circle"
                  style={{ backgroundColor: def.color }}
                />
                {def.text}
                {/*    //           <ButtonRender
    //               isInDropdown={true}
    //             changeDef={def}
    //             playerStore={playerStore}
    //             selectedProject={selectedProject}
    //             modsStore={modsStore}
    //           />*/}
              </Menu.Item>
              {key < a.length - 1 && <Menu.Divider />}
            </React.Fragment>
          );
        })}
      </Menu>
    );

    // return (
    //   <Dropdown overlay={menu}>
    //     <NewModButtonsListButtonParent disable={isDisabledGroup} color={color}>
    //       {dropDownText} <DownOutlined />
    //     </NewModButtonsListButtonParent>
    //   </Dropdown>
    // );

    return (
      <Dropdown
        overlay={menu}
        placement="bottomCenter"
        arrow
        overlayClassName={"NewModGroupDropdown-overlay"}
      >
        <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <ButtonRender
            color={color}
            isInDropdown={true}
            disabledDef={false}
            text={dropDownText}
            // changeDef={changeDefGroup.defs}
            playerStore={playerStore}
            selectedProject={selectedProject}
            modsStore={modsStore}
            animation={animation}
          />
        </span>
      </Dropdown>
    );
  }
);
