import React from "react";
import { Input, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { ICustomChangeMod } from "@blings/blings-player";
import { DynamicValueMod } from "../DynamicValueMod";
import { LayerSelector } from "../../LayerSelector";

export function CustomChangeForm(props: ModsPanelCompProps<ICustomChangeMod>) {
  const { change } = props;
  return (
    <Space direction={"vertical"}>
      <div>
        <LayerSelector
          isMulty={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedAdditionals={change.additionals}
          // onChange={onLayerChange}
          onChange={props.onLayerChange}
          index={props.index}
        />
      </div>
      <Input
        value={change.path}
        addonBefore="Path"
        onChange={(e) => props.onChange(props.index, "path", e.target.value)}
        allowClear={true}
      />
      {/*<div>*/}
      {/*    <label>*/}
      {/*        New Value:{" "}*/}
      {/*    </label> */}
      {/*    <Select defaultValue="string" style={{ width: 120 }} onChange={(v) => props.onChange(props.index,"nvType", v)} bordered={false}>*/}
      {/*        <Option value="string">String</Option>*/}
      {/*        <Option value="number">Number</Option>*/}
      {/*        <Option value="object">Object</Option>*/}
      {/*        <Option value="array">Array</Option>*/}
      {/*        <Option value="function">Function</Option>*/}
      {/*    </Select>                */}
      {/*</div>               */}
      <DynamicValueMod
        change={props.change}
        onChange={props.onChange}
        index={props.index}
      />
    </Space>
  );
}
