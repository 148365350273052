import {
  destroy,
  getRoot,
  Instance,
  types,
  getSnapshot,
  applySnapshot,
} from "mobx-state-tree";
import {
  IChangeInPathMod,
  interactiveGotoTypes,
  modTypes,
  interactiveConcatJumpTypes,
} from "@blings/blings-player";
import { autorun, toJS } from "mobx";
import _ from "lodash";
import { RootInstance } from "./main";
import { PlayerEvents } from "@blings/blings-player/lib/src/player.api";
import { snapshotsConsts } from "./snapshotConsts";
import { Mod } from "../API";

export const publicPlayerEvents: PlayerEvents[] = [
  "onAllReady",
  "onFirstPlay",
  "onPlay",
  "onFrame",
  "onReplay",
  "onPause",
  "onMute",
  "onUnmute",
  "onComplete",
  "onReplaceAnimation",
];

const ModModel = types.model({
  id: types.identifierNumber,
  origin: types.optional(types.maybe(types.maybeNull(types.string)), null),
  // moddata: types.frozen<IMods>(),
  moddata: types.model({
    type: types.enumeration<modTypes>("modType", Object.values(modTypes)),

    // IChangeInPathMod
    assetId: types.maybe(types.string),
    layerName: types.maybe(types.string),
    additionals: types.maybe(
      types.array(
        types.model({
          assetId: types.maybe(types.string),
          layerName: types.string,
        })
      )
    ),

    // IDynamicChangeMod
    dataKey: types.maybe(types.string),
    liveControlKey: types.maybe(types.string),
    value: types.maybe(types.frozen()),
    placeholder: types.maybe(types.frozen()), // Add the placeholder property to the mods storage
    inputName: types.maybe(types.string),
    expression: types.maybe(types.string),
    defaultValue: types.maybe(types.frozen()),

    // IInteractiveMod
    event: types.maybe(types.string),
    gotoType: types.maybe(
      types.enumeration<interactiveGotoTypes>([
        ...Object.values(interactiveGotoTypes),
      ])
    ),
    jumpType: types.maybe(
      types.enumeration<interactiveConcatJumpTypes>([
        ...Object.values(interactiveConcatJumpTypes),
      ])
    ),
    jumpValue: types.maybe(
      types.union(types.string, types.number, types.undefined)
    ),

    // ITextChangeMod
    maxChars: types.maybe(types.number),
    center: types.maybe(types.boolean),
    dontTrim: types.maybe(types.boolean),
    autoRTL: types.maybe(types.boolean),
    fixArabic: types.maybe(types.boolean),

    // IColorChangeMod + ICustomChangeMod
    path: types.maybe(types.string),
    shapeName: types.maybe(types.string),

    //IThemeColorChangeMod
    froms: types.maybe(
      types.array(types.union(types.array(types.number), types.string))
    ),
    isLayers: types.maybe(types.boolean),
    //IAssetChangeMod
    imageName: types.maybe(types.string),
    align: types.maybe(types.string),
    slice: types.maybe(types.boolean),

    // MediaMods
    startOnMarker: types.maybe(types.string),
    startAnimationFrame: types.maybe(types.number),
    stopAnimationFrame: types.maybe(types.number),
    offsetInSeconds: types.maybe(types.number),
    shouldLoop: types.maybe(types.boolean),

    //IBGVideoMod
    style: types.maybe(types.string),
    fullScreenStyle: types.maybe(types.string),
    unmute: types.maybe(types.boolean),

    //IBGAudioMod
    volume: types.maybe(types.number),

    // IFontMod
    fontConfig: types.maybe(types.frozen()),

    // IFunctionParam (IInteractiveModJSExpression + IOnPlayerEvent)
    functionString: types.maybe(types.string),
    /*
     *  following two are currently not in playground (mods stored online) just in manual player invocation
     *  functionKey: types.maybe(types.string)
     *  jsFunction:
     */

    playerEvent: types.maybe(
      types.enumeration<PlayerEvents>("PlayerEvents", publicPlayerEvents)
    ),

    //transition ITransitionTimingMod
    ip: types.maybe(types.number),
    op: types.maybe(types.number),

    //name for goto connectors
    ctaName: types.maybe(types.string),
  }),
});

export type IModModel = Instance<typeof ModModel>;

export const ModsModel = types
  .model({
    activeModId: types.optional(types.string, ""),
    // mods: types.map(types.frozen<IMods>()), [])
    mods: types.array(ModModel),
    unchangedMods: types.maybeNull(types.string),
  })
  .views((self) => ({
    get JsonConfig() {
      return self.mods
        .map((mod) => ({
          id: mod.id,
          dataStr: JSON.stringify(mod.moddata),
          origin: mod.origin,
        }));
    },
    get hasUnsavedChanges() {
      const stringifiedMods = JSON.stringify(
        self.mods.map((mod) => ({
          id: mod.id,
          dataStr: JSON.stringify(mod.moddata), //TODO Add origin
          origin: mod.origin,
        }))
      );
      return stringifiedMods !== self.unchangedMods;
    },
    getThrottleJsonConfig: _.throttle(
      () => {
        return JSON.parse(JSON.stringify(self.mods.map((mod) => mod.moddata)));
      },
      1000,
      { trailing: true, leading: false }
    ),
  }))
  .actions((self) => ({
    afterAttach() {
      autorun((a) => {
        if (self.JsonConfig) {
          getRoot<RootInstance>(self).playerStore.runPlayer();
        }
      });
    },
    setActiveMod(id?: string | number) {
      if (typeof id !== "undefined") {
        self.activeModId = id.toString();
      } else {
        self.activeModId = "";
      }
    },
    // addCbAfterAddingMod(cb){
    //
    // }
  }))
  .actions((self) => {
    const afterAddCB: ((id?: number) => void)[] = [];
    function addCbAfterAddingMod(cb) {
      afterAddCB.push(cb);
    }
    function addMod(moddata: Partial<IModModel["moddata"]>, afterIdx?: number) {
      localStorage.setItem(
        snapshotsConsts.mods,
        JSON.stringify(getSnapshot(self.mods))
      );
      const id = generateRandomId();

      if (typeof afterIdx !== "undefined") {
        const afterIndex = self.mods.findIndex((mod) => mod.id === afterIdx);
        // @ts-ignore
        self.mods.splice(afterIndex, 0, { id, moddata });
      } else {
        // @ts-ignore
        self.mods.unshift({ id, moddata });
      }
      setTimeout(() => {
        self.setActiveMod(id);
      }, 1);

      if (typeof id !== "undefined" && typeof afterIdx === "undefined") {
        afterAddCB.forEach((cb) => cb(id));
      }
    }
    function generateRandomId() {
      const usedIds = new Set(self.mods.map((mod) => mod.id));
      let rnd: number;
      do {
        rnd = Math.floor(Math.random() * 100000);
      } while (usedIds.has(rnd));
      return rnd;
    }
    return { addMod, addCbAfterAddingMod };
  })
  .actions((self) => ({
    copyMod(mod: IModModel) {
      self.addMod(
        toJS(mod.moddata),
        self.mods.findIndex((m) => m.id === mod.id)
      );
    },
    removeMod(mod) {
      destroy(mod);
    },
    snapshotMods() {
      const modsSnapshot = localStorage.getItem(snapshotsConsts.mods);
      if (modsSnapshot) {
        applySnapshot(self.mods, JSON.parse(modsSnapshot));
      }
    },
    replaceMods(mods?: Array<Mod> | null) {
      if (mods) {
        self.mods.replace(
          mods.map((md) => ({
            id: md.id,
            moddata: JSON.parse(md.dataStr),
            origin: md.origin,
          }))
        );
      } else {
        self.mods.replace([]);
      }
      self.unchangedMods = JSON.stringify(mods || []);
    },
    mergeWithModsFromSubscription(mods?: Array<Mod> | null) {
      if (!mods) {
        mods = [];
      }
      self.unchangedMods = JSON.stringify(mods);
      const parsedMods = mods.map((mod) => ({
        id: mod.id,
        moddata: JSON.parse(mod.dataStr),
        origin: mod.origin,
      }));
      const activeMod = self.mods.find(
        (mod) => mod.id.toString() === self.activeModId
      );
      if (!activeMod) {
        self.mods.replace(parsedMods);
        return;
      }
      let replacedActiveInIncoming = false;
      for (let i = 0; i < parsedMods.length; i++) {
        const mod = parsedMods[i];
        if (mod.id === activeMod.id) {
          parsedMods[i] = activeMod;
          replacedActiveInIncoming = true;
          break;
        }
      }
      if (!replacedActiveInIncoming) {
        parsedMods.push(activeMod);
      }
      self.mods.replace(parsedMods);
    },
    updateValue(modId: number, key: string | string[], value: any) {
      const m = self.mods.find((mod) => mod.id === modId);
      if (!m) return;
      const keys = Array.isArray(key) ? key : [key];
      const values = Array.isArray(key) ? value : [value];

      keys.forEach((k, i) => {
        m.moddata[k] = values[i];
      });
    },
  }));

export type IModsModel = Instance<typeof ModsModel>;

export const modsStore = ModsModel.create({
  // mods: [{type: modTypes.text}]
});
